import styles from './styles.module.css';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStrings } from 'providers/stringsprovider';
import { arrowLeftIcon } from 'resources/images';
import Button from 'components/common/button';
import Input from 'components/common/input';
import routePaths from 'routes/paths';
import Image from 'components/common/image';
const EmailRequired = () => {
  const { strings } = useStrings();
  const navigation = useNavigate();
  const location = useLocation();
  const assetData = location.state.asset;

  const [schemaObj, setSchemaObj] = useState({
    email: Yup.string().email(strings.validEmail).required(strings.emailRequired),
  });

  const emaiSchema = Yup.object().shape(schemaObj);

  const handleNext = async (values, { resetForm }) => {
    try {
      localStorage.setItem('guest_user', values.email);
      navigation(routePaths.raiseTicketThroughQr, {
        state: { asset: assetData, guest_email: values.email },
      });
      resetForm();
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emaiSchema,
    onSubmit: handleNext,
  });

  const rendedrHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <Image
          src={arrowLeftIcon}
          alt="close"
          containerStyle={styles.backImgViewStyle}
          onClick={() => navigation(-1)}
        />
        <p>{strings.back}</p>
      </div>
    );
  };

  const renderEmailDetails = () => {
    return (
      <form onSubmit={formik.handleSubmit} className={styles.containerStyle}>
        <div className={styles.emailDetailsViewStyle}>
          <div className={styles.emailDetailsSubViewStyle}>
            <p className={styles.titleTextStyle}>{strings.giveEmailId}</p>
            <div className={styles.emailDescViewStyle}>
              <p className={styles.descTextStyle}>{strings.giveEmailIdDesc}</p>
            </div>
          </div>
          <Input
            type="email"
            name="email"
            lableText={strings.emailLabelRequired}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.enterEmailId}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={() => formik.setFieldError('email', '')}
            hasError={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
          />
        </div>
        {renderNextBtn()}
      </form>
    );
  };

  const renderNextBtn = () => {
    return (
      <div className={styles.nextBtnViewStyle}>
        <Button title={strings.next} type="submit" btnStyle={styles.btnViewStyle} />
      </div>
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {rendedrHeaderSection()}
      {renderEmailDetails()}
    </div>
  );
};

export default EmailRequired;
