import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useStrings } from 'providers/stringsprovider';
import { raiseTicketApi } from 'networking/api/tickets';
import { useUserData } from 'providers/userdataprovider';
import SuggestionCard from 'components/suggestion-card';
import Input from 'components/common/input';
import Image from 'components/common/image';
import PopOver from 'components/common/pop-over';
import RadioButton from 'components/common/radio-button';
import Button from 'components/common/button';
import ImageUpload from 'components/image-upload';
import AvatarInfoCard from 'components/common/avatar-info-card';
import Modal from 'components/common/modal';
import {
  assetIcon,
  chevronDownFillGrayIcon,
  closeCircleFillBlackIcon,
  closeIcon,
  searchIcon,
} from 'resources/images';
import routePaths from 'routes/paths';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { updateAllTicketFunction } from 'functions';
import { useAppData } from 'providers/appdataprovider';
import styles from './styles.module.css';

const TicketRaise = () => {
  const navigate = useNavigate();
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const { assets, setTickets } = useUserData();
  const { setBreadcrumbs } = useAppData();
  const [raisedTicket, setRaisedTicket] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [showTicketInfo, setShowTicketInfo] = useState(false);
  const [apiError, setApiError] = useState('');
  const [assetPopRef, setAssetPopRef] = useState();
  const [assetPopOpen, setAssetPopOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [filteredAssets, setFilteredAssets] = useState(assets || []);
  const asset_options = ['All assets', 'Unassigned assets'];
  const [assetOptionType, setAssetOptionType] = useState(asset_options[0]);
  const [tagAssets, setTagAssets] = useState(false);

  useEffect(() => {
    let data = [...assets];
    if (assetOptionType === 'Unassigned assets') {
      data = data.filter((item) => !item.assigned_to);
    }
    if (query.length > 0) {
      data = data.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
      );
    }
    setFilteredAssets(data);
  }, [assets, assetOptionType, query]);

  useEffect(() => {
    setBreadcrumbs([{ title: strings.raiseTicket, route: '' }]);
  }, []);

  const raiseTicketSchema = Yup.object().shape({
    asset: Yup.string().required('Asset is required'),
    subject: Yup.string().required('Subject is required'),
    note: Yup.string().notRequired(),
    image: Yup.string().notRequired(),
  });

  const handleRaiseTicket = async (values, { resetForm }) => {
    try {
      setActionLoading(true);
      let raiseTicketResponse = await raiseTicketApi(values);
      if (raiseTicketResponse.data.type === 'success') {
        setRaisedTicket(raiseTicketResponse.data.data);
        setTickets((prev) => {
          let previous_data = [...prev];
          return updateAllTicketFunction(previous_data, raiseTicketResponse.data.data);
        });
        setActionLoading(false);
        handleResetData();
        setShowTicketInfo(true);
      } else {
        setActionLoading(false);
        setApiError(raiseTicketResponse.data.message);
      }
    } catch (error) {
      setApiError(error.message);
      setActionLoading(false);
      console.log('raise Ticket catch error', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      asset: '',
      subject: '',
      note: '',
      image: '',
    },
    validationSchema: raiseTicketSchema,
    onSubmit: handleRaiseTicket,
  });

  const handleResetData = () => {
    formik.resetForm();
    setSelectedAsset(null);
    setApiError('');
  };

  const renderTicketRaiseForm = () => {
    return (
      <form className={styles.formWrapperStyle} onSubmit={formik.handleSubmit}>
        <h2 className={styles.formTitleStyle}>{strings.createNewTicket}</h2>
        <div className={styles.inputsWrapperStyle}>
          <p className={styles.inputLabelStyle}>Tag an asset*</p>
          {formik.values.asset ? (
            <div className={classNames(styles.inputWrapperStyle)}>
              <AvatarInfoCard
                icon={selectedAsset.image || assetIcon}
                title={selectedAsset.asset_name}
                description={'#' + selectedAsset.asset_uid}
                avatarStyle={styles.avatarStyle}
              />
              <Image
                src={closeCircleFillBlackIcon}
                containerStyle={styles.closeIconStyle}
                onClick={() => {
                  setSelectedAsset(null);
                  formik.setFieldValue('asset', null);
                }}
              />
            </div>
          ) : (
            <React.Fragment>
              <div
                className={classNames(
                  styles.inputWrapperStyle,
                  !formik.values.asset && styles.pointerStyle
                )}
                ref={setAssetPopRef}
                onClick={() => setAssetPopOpen(true)}
              >
                <p className={styles.assetPlaceHolderStyle}>Click here to tag an asset</p>
                <Image
                  src={chevronDownFillGrayIcon}
                  containerStyle={styles.assetSelectDropDownIconStyle}
                />
                <PopOver
                  reference={assetPopRef}
                  show={assetPopOpen}
                  containerStyle={styles.assetPopUpStyle}
                  onClose={() => {
                    setAssetPopOpen(false);
                    setAssetOptionType(asset_options[0]);
                    setQuery('');
                    setTagAssets(false);
                  }}
                  relativeWidth
                  placement="bottom-end"
                >
                  <div className={styles.assetPopUpHeaderStyle}>
                    <h5 className={styles.assetPopUpTitleStyle}>Tag an asset</h5>
                    <Image
                      src={closeIcon}
                      containerStyle={styles.assetPopUpHeaderIconStyle}
                      onClick={() => {
                        setAssetPopOpen(false);
                        setQuery('');
                        setTagAssets(false);
                        setAssetOptionType(asset_options[0]);
                      }}
                    />
                  </div>
                  <div className={styles.assetPopUpBodyStyle}>
                    <div className={styles.assetOptionWrapperStyle}>
                      {asset_options.map((option) => (
                        <RadioButton
                          key={option}
                          label={option}
                          selected={option === assetOptionType}
                          onSelect={() => setAssetOptionType(option)}
                          labelStyle={
                            option === assetOptionType ? styles.selectedOptionLabelStyle : ''
                          }
                        />
                      ))}
                    </div>
                    <div>
                      {selectedAsset ? (
                        <div className={classNames(styles.inputWrapperStyle)}>
                          <AvatarInfoCard
                            icon={selectedAsset.image || assetIcon}
                            title={selectedAsset.asset_name}
                            description={'#' + selectedAsset.asset_uid}
                            avatarStyle={styles.avatarStyle}
                          />
                          <Image
                            src={closeCircleFillBlackIcon}
                            containerStyle={styles.closeIconStyle}
                            onClick={() => {
                              setSelectedAsset(null);
                            }}
                          />
                        </div>
                      ) : (
                        <Input
                          leftIcon={searchIcon}
                          value={query}
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                          placeholder={'Search from the added assets'}
                          onFocus={(e) => setTagAssets(true)}
                        />
                      )}
                      {tagAssets && (
                        <div className={styles.assetListWithInputStyle}>
                          <div className={styles.assetListWrapperStyle}>
                            {filteredAssets.length > 0 ? (
                              filteredAssets.map((asset, index) => (
                                <div
                                  key={'asset' + index}
                                  className={styles.assetListStyle}
                                  onClick={() => {
                                    setSelectedAsset(asset);
                                    setQuery('');
                                    setTagAssets(false);
                                  }}
                                >
                                  <Image
                                    src={asset.image || assetIcon}
                                    alt={asset.asset_name}
                                    containerStyle={styles.assetListImgStyle}
                                  />
                                  <div>
                                    <p className={styles.assetListTitleStyle}>{asset.asset_name}</p>
                                    <p className={styles.assetListDescStyle}>
                                      {'#' + asset.asset_uid}
                                    </p>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p className={styles.emptyDataStyle}>No data available</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <Button
                      title={'Done'}
                      btnStyle={styles.doneBtnStyle}
                      onClick={() => {
                        formik.setFieldValue('asset', selectedAsset?._id);
                        setAssetPopOpen(false);
                      }}
                      size={width > 480 ? 'lg' : 'md'}
                      disabled={!selectedAsset}
                    />
                  </div>
                </PopOver>
              </div>
              {formik.errors.asset && (
                <p className={styles.errorTextStyle}>{formik.errors.asset}</p>
              )}
            </React.Fragment>
          )}

          <Input
            name="subject"
            labelText={'Ticket subject*'}
            inputLabelStyle={styles.inputLabelStyle}
            placeholder={'Enter subject here'}
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.subject && formik.errors.subject ? formik.errors.subject : ''}
            containerStyle={styles.inputStyle}
          />
          <Input
            type="textarea"
            name="note"
            labelText={'Ticket notes (Optional)'}
            inputLabelStyle={styles.inputLabelStyle}
            placeholder={'Description or short note here'}
            value={formik.values.note}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.note && formik.errors.note ? formik.errors.note : ''}
            inputStyle={styles.textAreaStyle}
          />
          <ImageUpload
            label="Upload image (Optional)"
            placeholder="JPG, JPEG, or PNG (Less than 5MB)"
            image={formik.values.image}
            onSuccess={(img) => formik.setFieldValue('image', img)}
          />
          <div className={styles.bottomBlockStyle}>
            <p className={styles.errorTextStyle}>{apiError}</p>
            <div className={styles.btnWrapperStyle}>
              <Button
                title={strings.cancel}
                onClick={() => formik.resetForm()}
                variant="light"
                btnStyle={styles.customBtnStyle}
                disabled={actionLoading}
                size={width > 480 ? 'lg' : 'md'}
              />
              <Button
                type={'submit'}
                title={actionLoading ? strings.loading : 'Create'}
                isLoading={actionLoading}
                disabled={!formik.values.asset || !formik.values.subject || actionLoading}
                btnStyle={styles.customBtnStyle}
                size={width > 480 ? 'lg' : 'md'}
              />
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderSuccessModalContainer = () => {
    return (
      <Modal
        show={showTicketInfo}
        showOverlay
        onClose={() => setShowTicketInfo(false)}
        containerStyle={styles.successModalStyle}
      >
        <div className={styles.modalTopInfoWrapperStyle}>
          <p className={styles.modalTitleStyle}>
            {strings.ticket}{' '}
            <span className={styles.modalTitleLinkStyle}>#{raisedTicket?.ticket_uid}</span>{' '}
            {strings.ticketHasRaised}
          </p>
          <p
            className={styles.modalDescStyle}
          >{`Your complaint has reached the respective authorities and they are working on resolving it at the earliest. Check your all tickets page for further updates on #${raisedTicket?.ticket_uid}.`}</p>
        </div>
        <div className={styles.timeLineContainerStyle}>
          <div className={styles.timeLineItemWrapperStyle}>
            <div className={styles.dotStyle} />
            <div className={styles.contentStyle}>
              <p className={styles.timeLineItemTitleStyle}>Ticket Details</p>
              <div className={styles.contentBlockStyle}>
                {raisedTicket?.image && (
                  <Image src={raisedTicket.image} containerStyle={styles.contentImgStyle} />
                )}
                <div>
                  <p className={styles.contentTitleStyle}>{raisedTicket?.subject}</p>
                  <p className={styles.contentDescStyle}>{raisedTicket?.note}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.timeLineItemWrapperStyle}>
            <div className={styles.dotStyle} />
            <div className={styles.contentStyle}>
              <div className={styles.timeLineItemTitleStyle}>Asset & User details</div>
              <div className={styles.contentBlockStyle}>
                <AvatarInfoCard
                  icon={raisedTicket?.asset?.image || assetIcon}
                  title={raisedTicket?.asset?.asset_name}
                  description={raisedTicket?.asset?.asset_uid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.modalBtnBlockStyle}>
          <Button
            title={'Close'}
            variant="light"
            size={width > 480 ? 'lg' : 'md'}
            onClick={() => setShowTicketInfo(false)}
          />
          <Button
            title={'Go to all tickets'}
            size={width > 480 ? 'lg' : 'md'}
            onClick={() => navigate(routePaths.ticketList)}
          />
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      <SuggestionCard
        title={strings.suggestionBoardTitleForRaiseTicket}
        description={strings.suggestionBoardDescForRaiseTicket}
      />
      {renderTicketRaiseForm()}
      {renderSuccessModalContainer()}
    </div>
  );
};

export default TicketRaise;
