import React, { useEffect, useState } from 'react';
import { useUserData } from 'providers/userdataprovider';
import moment from 'moment';
import Input from 'components/common/input';
import Button from 'components/common/button';
import { filterIcon, multiSelectIcon, searchIcon } from 'resources/images';
import { useStrings } from 'providers/stringsprovider';
import Avatar from 'components/common/avatar';
import styles from './styles.module.css';
import { useLocation, useNavigate } from 'react-router';
import routePaths from 'routes/paths';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AssetAndUserCard from 'components/asset&user-card';
import { useAppData } from 'providers/appdataprovider';
import { useAuth } from 'providers/authprovider';
import { useSearchParams } from 'react-router-dom';

const EmployeeList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useWindowDimensions();
  const { users } = useUserData();
  const { user } = useAuth();
  const { setBreadcrumbs } = useAppData();
  const { strings } = useStrings();
  const { pathname } = useLocation();
  const { setShowHeader } = useAppData();
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    if (query.length > 0) {
      setFilteredUsers(
        users.filter((user) => JSON.stringify(user).toLowerCase().includes(query.toLowerCase()))
      );
    } else {
      setFilteredUsers(users);
    }
    setBreadcrumbs([{ title: strings.allEmployees, route: '' }]);
  }, [users, query]);

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(true);
    }
  }, [width, pathname]);

  const renderFilterSection = () => {
    return (
      <div className={styles.headerWrapperStyle}>
        <div className={styles.filterWrapperStyle}>
          <Input
            placeholder={strings.searchInput}
            inputStyle={styles.filterInputStyle}
            leftIcon={searchIcon}
            leftIconStyle={styles.filterIconStyle}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.target.value.length > 0) {
                setSearchParams({ query: e.target.value });
              } else {
                setSearchParams(searchParams.delete('query'));
              }
            }}
          />
          {/* <Button
          variant="gray"
          title={strings.filter}
          leftIcon={filterIcon}
          btnStyle={styles.filterBtnStyle}
          leftIconStyle={styles.filterIconStyle}
        />
        <Button
          variant="gray"
          title={strings.select}
          leftIcon={multiSelectIcon}
          btnStyle={styles.filterBtnStyle}
          leftIconStyle={styles.filterIconStyle}
        /> */}
        </div>
      </div>
    );
  };

  const renderListSection = () => {
    return (
      <div className={styles.tableSectionStyle}>
        <table className={styles.tableWrapperStyle}>
          <thead>
            <tr className={styles.tableHeaderRowStyle}>
              <th>{strings.userAndEmp}</th>
              <th>{strings.lastAssign}</th>
              <th>{strings.dateAndTime}</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((item, index) => (
                <tr
                  key={index}
                  className={styles.tableRowStyle}
                  onClick={() => navigate(routePaths.employeeDetails(item._id))}
                >
                  <td>
                    <div className={styles.avatarWrapperStyle}>
                      <Avatar
                        src={item.image}
                        label={item.full_name}
                        containerStyle={styles.avatarStyle}
                      />
                      <div className={styles.customColStyle}>
                        <span>
                          {item.full_name} {item._id === user._id && '(You)'}
                        </span>
                        <span className={styles.tableSmallTextStyle}>
                          {strings.account} {item.account_type}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.customColStyle}>
                      {item?.asset_id ? (
                        <>
                          <span>{item.asset.asset_name}</span>
                          <span className={styles.tableSmallTextStyle}>
                            #{item.asset.asset_uid}
                          </span>
                        </>
                      ) : (
                        <span className={styles.emptyAssetLineStyle}>-- -- -- -- -- --</span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className={styles.customColStyle}>
                      {item?.asset_id ? (
                        <>
                          <span>{moment(item.assigned_at).format('ll')}</span>
                          <span className={styles.tableSmallTextStyle}>
                            {moment(item.assigned_at).format('LT')}
                          </span>
                        </>
                      ) : (
                        <span className={styles.emptyAssetLineStyle}>-- -- -- -- -- --</span>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles.tableRowStyle}>
                <td className={styles.emptyDataStyle}>{strings.noData}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderListMobileSection = () => {
    return (
      <div className={styles.mobileViewStyle}>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((item, index) => (
            <React.Fragment key={index}>
              <AssetAndUserCard
                type="user"
                title={item.full_name}
                description={`${item.account_type} acc..`}
                image={item?.image}
                leftLable="Last assigned"
                leftTitle={item.asset ? item.asset?.asset_name : '- - - -'}
                leftDescription={item.asset ? item.asset?.asset_uid : ''}
                rightLable="Date & Time :"
                rightTitle={item.assigned_at ? moment(item.assigned_at).format('ll') : '- - -'}
                rightDescription={item.assigned_at ? moment(item.assigned_at).format('LT') : ''}
                onClick={() => navigate(routePaths.employeeDetails(item._id))}
                containerStyle={styles.cardStyle}
              />
            </React.Fragment>
          ))
        ) : (
          <div className={styles.emptyViewStyle}>
            <p className={styles.emptyDataStyle}>No data available</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.listContainerStyle}>
      {renderFilterSection()}
      {width > 480 ? renderListSection() : renderListMobileSection()}
    </div>
  );
};

export default EmployeeList;
