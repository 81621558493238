import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import QrReader from 'react-qr-reader';
import { useAuth } from 'providers/authprovider';
import Image from 'components/common/image';
import { closeIcon, closeWhiteIcon, flashWhiteIcon } from 'resources/images';
import Button from 'components/common/button';
import Modal from 'components/common/modal';
import Input from 'components/common/input';
import styles from './styles.module.css';

const Scanner = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showScanner, setShowScanner] = useState(false);
  const [qrNumber, setQrNumber] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFlash, setIsFlash] = useState(false);

  const handleError = (err) => {
    try {
      console.log(err.message);
    } catch (error) {}
  };

  const handleScan = (url, error) => {
    if (url) {
      let data = url?.split('/');
      let id = data[data.length - 1];
      if (id) {
        setQrNumber(id);
        navigate(`/asset/${id}`);
      }
    }
    if (error) {
      console.log('error', error);
    }
  };

  const setTorch = () => {
    const SUPPORTS_MEDIA_DEVICES = 'mediaDevices' in navigator;
    if (SUPPORTS_MEDIA_DEVICES) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const cameras = devices.filter((device) => device.kind === 'videoinput');
          if (cameras.length === 0) {
            console.log('No camera found on this device.');
          }
          navigator.mediaDevices
            .getUserMedia({
              video: {
                facingMode: 'environment',
              },
            })
            .then((stream) => {
              const track = stream.getVideoTracks()[0];
              const imageCapture = new ImageCapture(track);
              imageCapture
                .getPhotoCapabilities()
                .then((capabilities) => {
                  const torchSupported =
                    !!capabilities.torch ||
                    ('fillLightMode' in capabilities &&
                      capabilities.fillLightMode.length !== 0 &&
                      capabilities.fillLightMode !== 'none');
                  if (torchSupported) {
                    try {
                      track.applyConstraints({
                        advanced: [
                          {
                            torch: !isFlash,
                          },
                        ],
                      });
                      setIsFlash(!isFlash);
                    } catch (err) {
                      console.log(err);
                    }
                  } else {
                    console.log('No torch found');
                  }
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
      //The light will be on as long the track exists
    }
  };

  const renderScannerSection = () => {
    return (
      <div className={styles.scannerSectionStyle}>
        <QrReader
          delay={2000}
          onError={handleError}
          onScan={handleScan}
          className={styles.qrReaderStyle}
        />
        <div className={styles.scanBarStyle}>
          <em></em>
          <span></span>
        </div>
        <div className={styles.scannerOptionsViewStyle}>
          <div className={styles.scannerOptionsSubViewStyle}>
            <Image
              src={closeWhiteIcon}
              alt="X"
              containerStyle={styles.scannerIconsViewStyle}
              onClick={() => {
                setShowScanner(false);
                if (user) {
                  navigate('/dashboard');
                } else {
                  navigate('/login');
                }
              }}
            />
            <Image
              src={flashWhiteIcon}
              alt="img"
              containerStyle={styles.scannerIconsViewStyle}
              onClick={() => setTorch()}
            />
          </div>
        </div>
        <div className={styles.scannerBottomOptionsViewStyle}>
          <div className={styles.scannerBottomOptionsSubViewStyle}>
            <Button
              title="Enter asset’s unique ID"
              btnStyle={styles.scannerBtnViewStyle}
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal show={isModalOpen} showOverlay={true} containerStyle={styles.modalCustomStyle}>
        <div className={styles.verifyBlockViewStyle}>
          <div className={styles.topSectionStyle}>
            <div className={styles.headerViewStyle}>
              <p className={styles.headerTextStyle}>Verify with unique ID</p>
              <Image
                src={closeIcon}
                alt="icon"
                onClick={() => {
                  setQrNumber('');
                  setIsModalOpen(false);
                }}
                containerStyle={styles.closeIconStyle}
              />
            </div>
            <div className={styles.inputSectionStyle}>
              <div className={styles.messageTextViewStyle}>
                <p className={styles.messageTextStyle}>
                  Enter the unique asset ID (the one printed on QR sticker) to verify and update the
                  condition of the asset.
                </p>
              </div>
              <Input
                lableText={'Unique asset ID*'}
                inputLabelStyle={styles.lableTextStyle}
                placeholder={'Ex: V67UWZ9081'}
                value={qrNumber}
                onChange={(e) => setQrNumber(e.target.value)}
                inputStyle={styles.inputStyle}
              />
            </div>
          </div>
          <div className={styles.bottomSectionStyle}>
            <Button
              title="Cancel"
              variant="light"
              onClick={() => {
                setIsModalOpen(false);
                setQrNumber('');
              }}
              btnStyle={styles.btnViewStyle}
            />
            <Button
              title="Proceed"
              disabled={!qrNumber}
              onClick={() => {
                setIsModalOpen(false);
                navigate(`/asset/${qrNumber}`);
              }}
              btnStyle={styles.btnViewStyle}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {renderScannerSection()}
      {renderModal()}
    </div>
  );
};

export default Scanner;
