import React, { useEffect, useState } from 'react';
import { useUserData } from 'providers/userdataprovider';
import { useStrings } from 'providers/stringsprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { assetIcon, usersIcon } from 'resources/images';
import SuggestionCard from 'components/suggestion-card';
import Button from 'components/common/button';
import StatisticsCard from 'components/statistics-card';
import { useNavigate } from 'react-router';
import routePaths from 'routes/paths';
import { useAuth } from 'providers/authprovider';
import styles from './styles.module.css';
import { account_types } from 'resources/data';
import { useAppData } from 'providers/appdataprovider';

const Dashboard = () => {
  const { assets, users, tickets } = useUserData();
  const { setBreadcrumbs } = useAppData();
  const { userType } = useAuth();
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [isSuggestionBoardOpen, setIsSuggestionBoardOpen] = useState(() => {
    const storedValue = localStorage.getItem('DS');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const [statisticOption, setStatisticOption] = useState('Assets');
  const [statisticRef, setStatisticRef] = useState(null);
  const [statisticPopUp, setStatisticPopUp] = useState(false);
  const options = [
    { icon: assetIcon, text: 'Assets' },
    { icon: usersIcon, text: 'Users' },
  ];

  useEffect(() => {
    setBreadcrumbs([{ title: strings.dashboard, route: '' }]);
  }, []);

  const handleSuggestionBoardClose = () => {
    localStorage.setItem('DS', JSON.stringify(false));
    setIsSuggestionBoardOpen(false);
  };

  const renderStaticsSection = () => {
    return (
      <div className={styles.statisticsBlockStyle}>
        <div className={styles.dashboardSectionStyle}>
          <div className={styles.labelWrapperStyle}>
            <p className={styles.labelTitleStyle}>Assets</p>
          </div>
          <div className={styles.staticCardStyles}>
            <StatisticsCard
              count={assets?.length}
              title={strings.totalAssets}
              onClick={() => navigate(routePaths.assetList)}
            />
            <StatisticsCard
              count={assets?.filter((a) => a.assigned_to)?.length}
              title={strings.assignedAssets}
              onClick={() => navigate(routePaths.assetList)}
            />
            <StatisticsCard
              count={assets?.filter((a) => !a.assigned_to)?.length}
              title={strings.unAssignedAssets}
              onClick={() => navigate(routePaths.assetList)}
            />
            {/* 
             // *Todo this is hided feture 
            <StatisticsCard
              count={assets?.filter((a) => a.asset_status === 'Marked lost')?.length}
              title={strings.lostAssets}
              onClick={() => navigate(routePaths.assetList)}
            /> */}
          </div>
        </div>
        {userType !== account_types.employee && (
          <div className={styles.dashboardSectionStyle}>
            <div className={styles.labelWrapperStyle}>
              <p className={styles.labelTitleStyle}>Users</p>
            </div>
            <div className={styles.staticCardStyles}>
              <StatisticsCard
                count={users?.length}
                title={'Total users'}
                onClick={() => navigate(routePaths.employeeList)}
              />
              <StatisticsCard
                count={users?.filter((u) => u.account_type !== 'Employee').length}
                title={'Admin users'}
                onClick={() => navigate(routePaths.employeeList)}
              />
              <StatisticsCard
                count={users?.filter((u) => u.account_type === 'Employee').length}
                title={'Employee users'}
                onClick={() => navigate(routePaths.employeeList)}
              />
            </div>
          </div>
        )}
        <div className={styles.dashboardSectionStyle}>
          <div className={styles.labelWrapperStyle}>
            <p className={styles.labelTitleStyle}>Tickets</p>
          </div>
          <div className={styles.staticCardStyles}>
            <StatisticsCard
              count={tickets?.length}
              title={'All tickets'}
              onClick={() => navigate(routePaths.ticketList)}
            />
            <StatisticsCard
              count={tickets?.filter((t) => t.ticket_status !== 'Closed').length}
              title={'Open tickets'}
              onClick={() => navigate(routePaths.ticketList)}
            />
            <StatisticsCard
              count={tickets?.filter((t) => t.ticket_status === 'Closed').length}
              title={'Closed tickets'}
              onClick={() => navigate(routePaths.ticketList)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {isSuggestionBoardOpen && (
        <SuggestionCard title={strings.title} description={strings.descp}>
          <div className={styles.suggestionBtnWrapperStyle}>
            <Button title={strings.learnMore} />
            <Button
              title={width <= 480 ? strings.hide : strings.dontShowAgain}
              variant="light"
              onClick={handleSuggestionBoardClose}
            />
          </div>
        </SuggestionCard>
      )}
      {renderStaticsSection()}
    </div>
  );
};

export default Dashboard;
