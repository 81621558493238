import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import { useStrings } from 'providers/stringsprovider';
import { useUserData } from 'providers/userdataprovider';
import Input from 'components/common/input';
import Avatar from 'components/common/avatar';
import { searchIcon } from 'resources/images';
import routePaths from 'routes/paths';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AssetAndUserCard from 'components/asset&user-card';
import { useAppData } from 'providers/appdataprovider';
import classNames from 'classnames';
import styles from './styles.module.css';

const AssetList = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { strings } = useStrings();
  const { pathname } = useLocation();
  const { assets } = useUserData();
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const [query, setQuery] = useState('');
  const [filteredAssets, setFilteredAssets] = useState(assets);

  useEffect(() => {
    if (query.length > 0) {
      setFilteredAssets(
        assets.filter((asset) => JSON.stringify(asset).toLowerCase().includes(query.toLowerCase()))
      );
    } else {
      setFilteredAssets(assets);
    }
    setBreadcrumbs([{ title: strings.allAssets, route: '' }]);
  }, [assets, query]);

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(true);
    }
  }, [width, pathname]);

  const renderFilterSection = () => {
    return (
      <div className={styles.headerWrapperStyle}>
        <div className={styles.filterWrapperStyle}>
          <Input
            placeholder={strings.searchAssets}
            inputStyle={styles.filterInputStyle}
            leftIcon={searchIcon}
            leftIconStyle={styles.filterIconStyle}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {/* <Button
          variant="gray"
          title={strings.filter}
          leftIcon={filterIcon}
          btnStyle={styles.filterBtnStyle}
          leftIconStyle={styles.filterIconStyle}
        />
        <Button
          variant="gray"
          title={strings.select}
          leftIcon={multiSelectIcon}
          btnStyle={styles.filterBtnStyle}
          leftIconStyle={styles.filterIconStyle}
        /> */}
        </div>
      </div>
    );
  };

  const getStatusStyle = (state) => {
    if (state === 'Assigned') {
      return styles.assignedStyle;
    } else if (state === 'Marked lost') {
      return styles.lostStyle;
    } else if (state === 'Unassigned') {
      return styles.unAssignedStyle;
    } else {
    }
  };

  const getBgClolor = (status) => {
    if (status === 'Assigned') {
      return styles.assignedBgStyle;
    } else if (status === 'Unassigned') {
      return styles.unAssignedBgStyle;
    } else if (status === 'Marked lost') {
      return styles.markLostBgStyle;
    }
  };

  const renderListSection = () => {
    return (
      <div className={styles.tableSectionStyle}>
        <table className={styles.tableWrapperStyle}>
          <thead>
            <tr className={styles.tableHeaderRowStyle}>
              <th>{strings.SerialNo}</th>
              <th>{strings.assetInfo}</th>
              <th>{strings.assignedTo}</th>
              <th>{strings.status}</th>
              <th>{strings.lastActivity}</th>
              {/* <th>{strings.upcomingActivity}</th> */}
            </tr>
          </thead>
          <tbody>
            {filteredAssets.length > 0 ? (
              filteredAssets.map((item, index) => (
                <tr
                  key={index}
                  className={styles.tableRowStyle}
                  onClick={() => navigate(routePaths.assetDetails(item._id))}
                >
                  <td>{'#' + item.asset_uid}</td>
                  <td>
                    <div className={styles.customColStyle}>
                      <span>{item.asset_name}</span>
                      <span className={styles.tableSmallTextStyle}>{item.asset_type}</span>
                    </div>
                  </td>
                  <td>
                    {item.assigned_to ? (
                      <div className={styles.avatarWrapperStyle}>
                        <Avatar
                          src={item.assigned_to.image}
                          label={item.assigned_to.full_name}
                          containerStyle={styles.avatarStyle}
                        />
                        <p>{item.assigned_to.full_name}</p>
                      </div>
                    ) : (
                      <p className={styles.unAssignedStyle}>Unassigned</p>
                    )}
                  </td>
                  <td>
                    <div className={styles.statusViewStyle}>
                      {item.asset_status !== 'Unassigned' && (
                        <div
                          className={classNames(
                            styles.dotViewStyle,
                            getBgClolor(item.asset_status)
                          )}
                        ></div>
                      )}
                      <p className={classNames(getStatusStyle(item.asset_status))}>
                        {item.asset_status}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={styles.customColStyle}>
                      <span>{item.last_activity}</span>
                      <span className={styles.tableSmallTextStyle}>
                        {moment(item.updated_at).fromNow()}
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles.tableRowStyle}>
                <td className={styles.emptyDataStyle}>{strings.noData}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderListMobileView = () => {
    return (
      <div className={styles.mobileViewStyle}>
        {filteredAssets.length > 0 ? (
          filteredAssets.map((item, index) => (
            <React.Fragment key={index}>
              <AssetAndUserCard
                title={item.asset_name}
                description={`#${item.asset_uid}`}
                image={item?.image}
                leftLable="Last assigned"
                leftTitle={item.assigned_to ? item.assigned_to?.full_name : '- - - -'}
                leftDescription={item.assigned_to?.email}
                rightLable="Status"
                rightTitle={item.asset_status}
                onClick={() => navigate(routePaths.assetDetails(item._id))}
                containerStyle={styles.cardStyle}
              />
            </React.Fragment>
          ))
        ) : (
          <div className={styles.emptyViewStyle}>
            <p className={styles.emptyDataStyle}>No data available</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.listContainerStyle}>
      {renderFilterSection()}
      {width > 480 ? renderListSection() : renderListMobileView()}
    </div>
  );
};

export default AssetList;
