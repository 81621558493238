import React, { useEffect, useRef, useState } from 'react';
import Image from 'components/common/image';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {
  checkPurpleIcon,
  chevronLeftBlackIcon,
  optionsVerticalGrayIcon,
  plusCircleBlackIcon,
  sendBlueFillIcon,
  timerOrangeIcon,
} from 'resources/images';
import routePaths from 'routes/paths';
import { useNavigate } from 'react-router';
import PopOver from 'components/common/pop-over';
import { useStrings } from 'providers/stringsprovider';
import { socket } from 'providers/socketprovider';
import {
  archiveChatApi,
  getChatMessagesApi,
  markAsRecoveredApi,
  sendMessageApi,
} from 'networking/api/chat';
import Loader from 'components/common/loader';
import moment from 'moment';
import Input from 'components/common/input';
import { useAuth } from 'providers/authprovider';
import styles from './styles.module.css';
import { useUserData } from 'providers/userdataprovider';
import Modal from 'components/common/modal';
import SuccessCard from 'components/success-card';
const ChatDetails = (props) => {
  const { chat_id, asset_id } = props;
  const navigate = useNavigate();
  const { chatList, setChatList } = useUserData();
  const { width } = useWindowDimensions();
  const { strings } = useStrings();
  const { user } = useAuth();
  const [menuOptions, setMenuOptions] = useState(false);
  const [menuRef, setMenuRef] = useState(null);
  const [comment, setComment] = useState('');
  const [messages, setMessages] = useState([]);
  const [asset, setAsset] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [isAlertLoader, setIsAlertLoader] = useState(false);
  const dates = new Set();
  const options = [strings.assetDetails, strings.markAsRecovered, strings.reportChat];

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (asset_id) {
      socketListner();
      setAsset(chatList.find((item) => item._id === asset_id));
    }
    socket.emit('join_room', asset_id);
    return () => {
      socket.emit('leave_room', asset_id);
    };
  }, [asset_id]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages, messagesEndRef]);

  useEffect(() => {
    if (chat_id) {
      getMessages();
    }
  }, [chat_id]);

  const socketListner = () => {
    socket.on('new_message_received', (newMessage) => {
      console.log('newMessage', newMessage);
      // if (selectedChat?.lf_asset._id === newMessage.lfa_id) {
      let findMessage = messages.find((msg) => msg._id === newMessage._id);
      if (!findMessage) {
        setMessages((prev) => [...prev, newMessage]);
      }
      // }
    });
  };

  const getMessages = async () => {
    setMessages([]);
    try {
      setIsLoading(true);
      let res = await getChatMessagesApi(chat_id);
      if ((res.data.type = 'success')) {
        setMessages(res.data.data);
        console.log('getChatMessages api res', res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log('getChatMessages api failed', res.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('getChatMessages api catch', error.response);
    }
  };

  const renderDateFormate = (timestampDate) => {
    var today = moment();
    var yesterday = moment().subtract(1, 'day');
    if (moment(timestampDate).isSame(today, 'd')) {
      return 'Today';
    } else if (moment(timestampDate).isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      return moment(timestampDate).format('Do MMMM YYYY');
    }
  };

  const sendMessage = async () => {
    if (comment !== '') {
      try {
        let response = await sendMessageApi({
          asset: asset_id,
          text: comment,
          files: [],
          guest_email: '',
        });
        if (response.data.type === 'success') {
          setComment('');
          console.log('send Message api response', response.data.data);
          // setMessages((prev) => [...prev, response.data.data]);
          socket.emit('new_message', response.data.data);
        } else {
          console.log('send Message api fail', response.data.message);
        }
      } catch (error) {
        console.log('send Message api catch', error.response.data);
      }
    }
  };

  const handleMenuAction = (opt) => {
    if (opt === strings.assetDetails) {
      setMenuOptions(false);
    } else if (opt === strings.markAsRecovered) {
      setMenuOptions(false);
      setIsAlertModalOpen(true);
    } else {
      setMenuOptions(false);
    }
  };

  const tapOnAcknowledge = () => {
    // setIsRecoveredModalOpen(!isRecoveredModalOpen);
  };

  const tapOnArchiveChat = async () => {
    try {
      let data = {
        asset: asset_id,
      };
      let chatArchiveResponse = await archiveChatApi(data);
      if (chatArchiveResponse.data.type === 'success') {
      } else {
        console.log('archive Chat api failed', chatArchiveResponse.data.message);
      }
    } catch (error) {
      console.log('archive Chat api catch', error.response.data);
    }
  };

  const tapOnProceed = async () => {
    let data = {
      asset: asset_id,
    };
    setIsAlertLoader(true);
    try {
      let markAsRecoveredResponse = await markAsRecoveredApi(data);
      if (markAsRecoveredResponse.data.type === 'success') {
        let updatedList = chatList.map((item) => {
          if (item._id === asset_id) {
            return {
              ...item,
              lf_asset: markAsRecoveredResponse.data.data,
            };
          } else {
            return item;
          }
        });
        setChatList(updatedList);
        setAsset(updatedList.find((item) => item._id === asset_id));
        setIsAlertLoader(false);
        setIsAlertModalOpen(false);
      } else {
        console.log('mark As Recovered Api failed', markAsRecoveredResponse.data.data);
        setIsAlertLoader(false);
      }
    } catch (error) {
      setIsAlertLoader(false);
      console.log('mark As Recovered Api catch', error.response.data);
    }
  };

  // ui section ----

  const renderHeader = () => {
    return (
      <div className={styles.cdHeaderBlockStyle}>
        <div className={styles.cdHeaderLeftBlockStyle}>
          {width < 480 && (
            <Image
              src={chevronLeftBlackIcon}
              alt="<"
              containerStyle={styles.cdHeaderLeftBlockIconStyle}
              onClick={() => navigate(routePaths.lostAndFound)}
            />
          )}
          <div className={styles.cdHeaderLeftBlockContentStyle}>
            <p className={styles.cdHeaderLeftBlockTitleStyle}>{asset?.asset_name}</p>
            <p className={styles.cdHeaderLeftBlockDescStyle}>
              Serial no: <span>#{asset?.asset_uid}</span>
            </p>
          </div>
        </div>
        <div className={styles.cdHeaderBlockRightStyle}>
          <Image
            src={optionsVerticalGrayIcon}
            reference={setMenuRef}
            onClick={() => setMenuOptions(true)}
          />
        </div>
      </div>
    );
  };

  const renderMenuOptionModal = () => {
    return (
      <PopOver
        showOverlay={true}
        show={menuOptions}
        reference={menuRef}
        onClose={() => setMenuOptions(false)}
        containerStyle={styles.cdMenuOptionsModalStyle}
      >
        <div className={styles.optionsViewStyle}>
          {options.map((item, index) => (
            <div
              key={index}
              onClick={() => handleMenuAction(item)}
              className={styles.eachOptionViewStyle}
            >
              <p className={styles.optionTextStyle}>{item}</p>
            </div>
          ))}
        </div>
      </PopOver>
    );
  };

  const renderChatBox = () => {
    return (
      <div className={styles.chatBoxContainerStyle}>
        <div ref={messagesEndRef} className={styles.chatBoxSubContainerStyle}>
          {messages.map((message, index) => {
            const date = moment(message.created_at).format('L');
            return (
              <div key={index}>
                {dates.has(date) ? null : renderDate(message.created_at, date)}
                {user?._id !== message?.sender?._id ? (
                  <div className={styles.leftMsgWrapperStyle}>
                    <div className={styles.leftmsgTextWrapperStyle}>
                      <p className={styles.leftmsgTextStyle}>{message.text}</p>
                    </div>
                    <p className={styles.msgTimeStampStyle}>
                      {message.created_at && moment(message.created_at).format('HH:mm a')}
                    </p>
                  </div>
                ) : (
                  <div className={styles.rightMsgWrapperStyle}>
                    <div className={styles.rightmsgTextWrapperStyle}>
                      <p className={styles.rightmsgTextStyle}>{message.text}</p>
                    </div>

                    <p className={styles.msgTimeStampStyle}>
                      {message.created_at && moment(message.created_at).format('HH:mm a')}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
          {(asset?.lf_asset?.recovered || asset?.lf_asset?.returned) &&
            renderReturnedRecoveredSection()}
        </div>
      </div>
    );
  };

  const renderReturnedRecoveredSection = () => {
    return (
      <div
        className={[
          asset?.lf_asset?.recovered
            ? styles.assetRecoveredViewStyle
            : styles.assetReturnedViewStyle,
        ]}
      >
        <Image
          src={asset?.lf_asset?.recovered ? checkPurpleIcon : timerOrangeIcon}
          alt="img"
          containerStyle={styles.imgViewStyle}
        />
        {user ? (
          <p className={styles.assetStatusTextStyle2}>
            {asset?.lf_asset?.recovered
              ? strings.assetRecoveredTextForUser
              : strings.assetReturnedTextForUser}{' '}
            <span
              className={[
                asset?.lf_asset?.recovered ? styles.onClickTextStyle : styles.onClickTextStyle2,
              ]}
              onClick={() => {
                asset?.lf_asset?.recovered ? tapOnArchiveChat() : tapOnAcknowledge();
              }}
            >
              {strings.clickHere}
            </span>
          </p>
        ) : (
          <p className={styles.assetStatusTextStyle}>
            {strings.assetReturneddTextForFinder}{' '}
            <span>{strings.assetReturneddTextForFinder2}</span>
          </p>
        )}
      </div>
    );
  };

  const renderDate = (timeToken, date) => {
    const timestampDate = moment(timeToken).format('L');
    dates.add(date);
    return (
      <div className={styles.dateWrapperStyle}>
        <div className={styles.dateViewStyle}>
          <p className={styles.dateTextStyle}>{renderDateFormate(timestampDate)}</p>
        </div>
      </div>
    );
  };

  const renderInputSection = () => {
    return (
      <div className={styles.chatInputContainerStyle}>
        <Input
          placeholder="Type message here ..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          inputStyle={styles.inputViewStyle}
          leftIcon={plusCircleBlackIcon}
          leftIconStyle={styles.inputLeftIconStyle}
          rightIcon={sendBlueFillIcon}
          rightIconStyle={styles.inputRightIconStyle}
          onClickRightIcon={() => sendMessage()}
        />
      </div>
    );
  };

  const rendedrNotSelectedSection = () => {
    return (
      <div className={styles.notSelectedSectionViewStyle}>
        <p className={styles.notSelectedTextStyle}>
          Select an chat from the list to view detailed-updates, messages, and much more. To learn
          more about Lost & Found and how it will help your business <span> click here </span>
        </p>
      </div>
    );
  };

  const renderEmptyChatSection = () => {
    return (
      <div className={styles.emptySectionViewStyle}>
        <p className={styles.emptyDataTextStyle}>0 conversations</p>
        <p className={styles.emptyDataTextStyle}>
          No finder has reported about this lost item/asset yet
        </p>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal showOverlay={true} show={isAlertModalOpen} containerStyle={styles.modalViewStyle}>
        <SuccessCard
          title={strings.itemRecovered}
          description={strings.userAcknowledgeAlertText}
          leftBtnTitle={strings.cancel}
          leftAction={() => {
            setIsAlertModalOpen(false);
          }}
          rightBtnTitle={strings.proceed}
          rightAction={() => tapOnProceed()}
          leftBtnVariant="light"
          rightBtnVariant="true"
          cardBtnStyle={styles.alertModalBtnStyle}
          isLoader={isAlertLoader}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.cdMainContainerStyle}>
      {isLoading ? (
        <Loader />
      ) : asset_id ? (
        <div className={styles.cdContainerStyle}>
          {renderHeader()}
          {asset_id && messages.length === 0 ? renderEmptyChatSection() : renderChatBox()}
          {renderInputSection()}
          {renderMenuOptionModal()}
          {renderModal()}
        </div>
      ) : (
        rendedrNotSelectedSection()
      )}
    </div>
  );
};

export default ChatDetails;
