import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import { useStrings } from 'providers/stringsprovider';
import Button from 'components/common/button';
import Input from 'components/common/input';
import DatePicker from 'components/common/date-picker';
import Image from 'components/common/image';
import PopOver from 'components/common/pop-over';
import {
  assetIcon,
  calendarIcon,
  checkBlueIcon,
  checkCircleFillGrayIcon,
  chevronDownFillGrayIcon,
  infoCircleUpFillGrayIcon,
  listAddBlueIcon,
  multiselectPurpleIcon,
  pencilEditBlackIcon,
  pencilEditIcon,
  scheduledIcon,
} from 'resources/images';
import { assetCreateApi } from 'networking/api/asset';
import UploadXLSFile from 'components/upload-xls-file';
import styles from './styles.module.css';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ImageUpload from 'components/image-upload';
import Modal from 'components/common/modal';
import SuccessCard from 'components/success-card';
import routePaths from 'routes/paths';
import Reminders from 'sections/assets/reminders';
import MultiFileUpload from 'components/multi-file-upload';
import AddEditReminder from 'sections/assets/add-edit-reminder';
import RegisterSuccessCard from 'components/register-success-card';
import MultiSelectInput from 'components/common/multi-seleect-input';
import { useAppData } from 'providers/appdataprovider';
import { reminderActionTypes } from 'resources/data';

const AssetRegistration = () => {
  const navigate = useNavigate();
  const { strings } = useStrings();
  const { company } = useAuth();
  const { pathname } = useLocation();
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const { width } = useWindowDimensions();
  const { setAssets, users } = useUserData();
  const [selectedTab, setSelectedTab] = useState('Manual');
  const [step, setStep] = useState(1);
  const [actionLoading, setActionLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [schemaObj, setSchemaObj] = useState({
    asset_name: Yup.string().required(strings.assetNameRequired),
    // asset_category: Yup.string().required(strings.assetcategoryRequired),
    asset_category: Yup.string(),
  });

  const default_categories = [strings.laptop, strings.mobile];

  const asset_types = [
    {
      title: strings.employeeAsset,
      key: 'Employee asset',
      img: assetIcon,
    },
    {
      title: strings.checkInOut,
      key: 'Check-in and out',
      img: checkCircleFillGrayIcon,
    },
    {
      title: strings.scheduledAsset,
      key: 'Scheduled asset',
      img: scheduledIcon,
    },
  ];
  const [categories, setCategories] = useState(default_categories);
  const [categoryAdding, setCategoryAdding] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [assetType, setAssetType] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [imgUploading, setImgUploading] = useState(false);
  const [imgUploadError, setImgUploadError] = useState(null);
  const [noteCheck, setNoteCheck] = useState(false);
  const [userListRef, setUserListRef] = useState();
  const [showDropDownList, setShowDropDownList] = useState(false);
  const [query, setQuery] = useState('');
  const [dropDownList, setDropDownList] = useState(users);
  const [selectedUser, setSelectedUser] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const steps = [strings.assetInfoRequire, strings.remindersAndattachments];
  const [typesRef, setTypesRef] = useState(null);
  const [isShowTypesOptions, setIsShowTypesOptions] = useState(false);
  const [customTypeRef, setCustomTypeRef] = useState(null);
  const [isShowCustomTypeInput, setIsShowCustomTypeInput] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [assignTo, setAssignTo] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [reminders, setReminders] = useState([]);
  const [isShowAlertModalOpen, setIsShowAlertModalOpen] = useState(false);
  const [createdAsset, setCreatedAsset] = useState({});
  const [assetRegDisabled, setAssetRegDisabled] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let new_categories = getCategories();
    if (new_categories && new_categories?.length > 0 && Array.isArray(new_categories)) {
      setCategories([...new_categories]);
    } else {
      setCategories(default_categories);
      localStorage.setItem('ASSET_CAT', JSON.stringify(default_categories));
    }
  }, []);
  useEffect(() => {
    if (width <= 480) {
      setShowHeader(true);
    }
  }, [width, pathname]);

  useEffect(() => {
    setBreadcrumbs([{ title: strings.addAsset, route: '' }]);
  }, []);

  const getCategories = () => {
    let new_categories = localStorage.getItem('ASSET_CAT');
    setCategories(JSON.parse(new_categories));
    let new_parsed_categories = JSON.parse(new_categories);
    return new_parsed_categories || [];
  };

  const handleNewCategory = () => {
    let old_categories = getCategories();
    let data = old_categories?.length > 0 ? old_categories : [];
    let existing = data.find((item) => item === newCategory);
    if (!existing) {
      data.push(newCategory);
    }
    localStorage.setItem('ASSET_CAT', JSON.stringify(data));
    getCategories();
    formik.setFieldValue('asset_category', newCategory);
    setIsShowCustomTypeInput(false);
  };

  useEffect(() => {
    if (step === 1) {
      setSchemaObj({
        ...schemaObj,
        asset_name: Yup.string().required(strings.assetNameRequired),
        asset_category: Yup.string().required(strings.assetcategoryRequired),
        assigned_to: undefined,
        asset_type: undefined,
        note_to_finder: undefined,
      });
    } else if (step === 2) {
      setSchemaObj({
        ...schemaObj,
        asset_type: undefined,
        note_to_finder: undefined,
      });
    }
    // eslint-disable-next-line
  }, [step, strings.assetNameRequired, strings.assetTypeRequired, strings.assignedToRequired]);

  useEffect(() => {
    if (query.length > 0) {
      setDropDownList(
        users.filter((user) => JSON.stringify(user).toLowerCase().includes(query.toLowerCase()))
      );
    } else {
      setDropDownList(users);
    }
  }, [users, query]);

  const handleNextAndSubmitBtn = (values) => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      handleAssetCreate(values);
    }
  };

  const handleAssetCreate = async (values) => {
    setIsLoading(true);
    try {
      setApiError('');
      setActionLoading(true);
      const response = await assetCreateApi({
        ...values,
        reminders,
        attachments: attachments.map((item) => item._id).filter(Boolean),
      });
      if (response.data.type === 'success') {
        setApiError('');
        setStep(1);
        setAssets((prev) => {
          let prevData = [...prev];
          let findIndex = prevData.findIndex((item) => item._id === response.data.data._id);
          if (findIndex !== -1) {
            prevData[findIndex] = response.data.data;
            return prevData;
          } else {
            return [response.data.data, ...prevData];
          }
        });
        setImgFile(null);
        setImgPreview(null);
        setNoteCheck(false);
        formik.resetForm();
        formik.setFieldValue('assigned_to', '');
        setReminders([]);
        setAttachments([]);
        setSelectedImageName('');
        setCreatedAsset(response.data.data);
        setIsShowAlertModalOpen(true);
        setIsLoading(false);
      } else {
        setApiError(response.data.message);
        setIsLoading(false);
      }
      setActionLoading(false);
    } catch (error) {
      setActionLoading(false);
      console.log('error.message', error.message);
      setApiError(error.message);
      setIsLoading(false);
    }
  };

  const assetSchema = Yup.object().shape(schemaObj);

  const formik = useFormik({
    initialValues: {
      asset_name: '',
      asset_category: '',
      asset_type: '',
      image: '',
      assigned_to: '',
      qr_id: '',
      company: company ? company._id : '',
      purchased_on: '',
      note_to_finder: '',
      description: '',
    },
    validationSchema: assetSchema,
    onSubmit: handleNextAndSubmitBtn,
  });

  const handlePreviousAction = () => {
    if (step === 1) {
      formik.resetForm();
    } else if (step === 2) {
      setStep(1);
    }
  };
  const handleReminders = (data) => {
    let remindersData = [...reminders];
    let findIndex = reminders.findIndex((item) => item.local_id === data.local_id);
    if (findIndex !== -1) {
      remindersData[findIndex] = data;
      setReminders(remindersData);
    } else {
      setReminders([...reminders, data]);
    }
  };

  // ui section
  const renderButtonGroup = () => {
    return (
      <div className={styles.btnGroupWrapperStyle}>
        <Button
          title={strings.addManually}
          onClick={() => setSelectedTab('Manual')}
          btnStyle={classNames(
            styles.tabBtnStyle,
            selectedTab === 'Manual' && styles.selectedTabBtnStyle
          )}
          leftIcon={selectedTab === 'Manual' ? pencilEditBlackIcon : pencilEditIcon}
          leftIconStyle={styles.tabBtnIconStyle}
        />
        {/* 
        // Todo commented for the feture use
        <Button
          title={strings.importXLSFile}
          onClick={() => setSelectedTab('XLSX')}
          btnStyle={classNames(
            styles.tabBtnStyle,
            selectedTab === 'XLSX' && styles.selectedTabBtnStyle
          )}
          leftIcon={selectedTab === 'XLSX' ? xlsDarkIcon : xlsGrayIcon}
          leftIconStyle={styles.tabBtnIconStyle}
        /> */}
        {/* <Button
          title={strings.importFromAPI}
          onClick={() => setSelectedTab('API')}
          btnStyle={classNames(
            styles.tabBtnStyle,
            selectedTab === 'API' && styles.selectedTabBtnStyle
          )}
          leftIcon={selectedTab === 'API' ? plugDarkIcon : plugGrayIcon}
          leftIconStyle={styles.tabBtnIconStyle}
        /> */}
      </div>
    );
  };
  const renderEmployeeTypes = () => {
    return (
      <PopOver
        show={isShowTypesOptions}
        reference={typesRef}
        onClose={() => {
          if (!isShowCustomTypeInput) {
            setIsShowTypesOptions(false);
          }
        }}
        containerStyle={styles.actionPopUpViewStyle}
        placement="bottom-end"
        offset={[0, -44]}
      >
        <div className={styles.actionOptionsViewStyle}>
          {categories?.map((item, index) => {
            return (
              <div className={styles.optiondTextWrapperStyle} key={index}>
                <p
                  onClick={() => {
                    formik.setFieldValue('asset_category', item);
                    setIsShowTypesOptions(false);
                  }}
                  className={classNames(styles.optionsViewStyles)}
                >
                  {item}
                </p>
                {formik.values.asset_category === item && (
                  <Image src={checkBlueIcon} containerStyle={styles.checkIconStyle} />
                )}
              </div>
            );
          })}
        </div>
        <div
          className={styles.customTypeContainerStyle}
          onClick={() => setIsShowCustomTypeInput(true)}
          ref={setCustomTypeRef}
        >
          <Image src={listAddBlueIcon} containerStyle={styles.listIconStyle} />
          <p className={styles.customOptionsViewStyles}>Add custom role</p>
        </div>
      </PopOver>
    );
  };
  const renderCustomEmployeeRole = () => {
    return (
      <PopOver
        show={isShowCustomTypeInput}
        reference={customTypeRef}
        onClose={() => setIsShowCustomTypeInput(false)}
        containerStyle={styles.inputPopUpViewStyle}
        showOverlay
        overlayStyle={styles.overlayStyle}
      >
        <div className={styles.customEmployeeViewStyle}>
          <Input
            name="employee_type"
            labelText={strings.addCustomCategory}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={'Ex: Electronics'}
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.employee_type && formik.errors.employee_type
                ? formik.errors.employee_type
                : ''
            }
            containerStyle={styles.inputStyle}
          />
          <div className={styles.popUpBtnContainerStyle}>
            <Button
              title="Cancel"
              size="md"
              variant="light"
              onClick={() => {
                setIsShowCustomTypeInput(false);
                setNewCategory('');
              }}
            />
            <Button
              title="Save entry"
              size="md"
              disabled={newCategory !== '' ? false : true}
              onClick={() => handleNewCategory()}
            />
          </div>
        </div>
      </PopOver>
    );
  };
  const renderManualProcess = () => {
    return (
      <div className={styles.assetDataWrapperStyle}>
        <h2 className={styles.formTitleStyle}>{strings.assetDetails}</h2>
        {renderSteps()}
        <form onSubmit={formik.handleSubmit} className={styles.formWrapperStyle}>
          {step === 1 ? renderStepOne() : renderStepTwo()}
          {apiError && <p className={styles.errorTextStyle}>{apiError}</p>}
          <div className={styles.paginationAndBtnWrapperStyle}>{renderBtns()}</div>
        </form>
      </div>
    );
  };
  const renderStepOne = () => {
    return (
      <div className={styles.nameCatWrapperStyle}>
        <div className={styles.inputsWrapperStyle}>
          <Input
            name="asset_name"
            labelText={strings.assetLabelNameRequired}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.assetTypeItem}
            value={formik.values.asset_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.asset_name && formik.errors.asset_name ? formik.errors.asset_name : ''
            }
            containerStyle={styles.inputStyle}
          />
          <div className={styles.selectTypeInputContainerStyle}>
            <p className={styles.lableTextStyle}>Asset category*</p>
            <div
              className={styles.selectTypeContainerStyle}
              onClick={() => {
                setIsShowTypesOptions(true);
              }}
              ref={setTypesRef}
            >
              <p
                className={
                  formik.values.asset_category ? styles.valueTextStyle : styles.placeHolderTextStyle
                }
              >
                {formik.values.asset_category
                  ? formik.values.asset_category
                  : strings.addAssetCategory}
              </p>
              <Image src={chevronDownFillGrayIcon} containerStyle={styles.arrowDownIconStyle} />
            </div>
            {formik.errors.asset_category && (
              <p className={styles.errorTextStyle}>
                {formik.touched.asset_category && formik.errors.asset_category
                  ? formik.errors.asset_category
                  : ''}
              </p>
            )}
          </div>
        </div>
        <div className={styles.inputsWrapperStyle}>
          <Input
            name="qr_id"
            labelText={'Unique ID (QR no.)'}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={'Enter unique code on QR sticker'}
            value={formik.values.qr_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.qr_id && formik.errors.qr_id ? formik.errors.qr_id : ''}
            containerStyle={styles.inputStyle}
          />
          <DatePicker
            labelText={strings.purchaseDateRequired}
            labelTextStyle={styles.lableTextStyle}
            placeholder="DD-MM-YYYY"
            format="DD MMMM YYYY"
            maxDate={new Date()}
            icon={calendarIcon}
            onChange={(date) => formik.setFieldValue('purchased_on', date)}
            containerStyle={styles.datePickerStyle}
            iconStyle={styles.dateIconStyle}
            valueStyle={
              formik.values.purchased_on ? styles.dateValueStyle : styles.datePlaceholdereStyle
            }
            value={formik.values.purchased_on}
          />
        </div>

        <ImageUpload
          label="Asset image"
          placeholder="JPG, JPEG, or PNG (Less than 5MB)"
          image={formik.values.image}
          onSuccess={(img) => formik.setFieldValue('image', img)}
          setImageName={(val) => setSelectedImageName(val)}
          imageName={selectedImageName}
        />
        <Input
          type="textarea"
          name="description"
          labelText={strings.descriptionOptional}
          inputLabelStyle={styles.lableTextStyle}
          placeholder={strings.enterAssetDescription}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.description && formik.errors.description ? formik.errors.description : ''
          }
        />
        <div className={styles.selectTypeInputContainerStyle}>
          <MultiSelectInput
            inputLabel={strings.assignedTo}
            placeholder={strings.searchUserLable}
            options={users?.map((user) => {
              return {
                label: user.full_name,
                value: user._id,
                desc: user.email,
              };
            })}
            multi={false}
            selectedData={assignTo}
            setSelectedData={(item) => {
              if (item.length > 0) {
                formik.setFieldValue('assigned_to', item[0]);
                setAssignTo(item);
              } else {
                formik.setFieldValue('assigned_to', '');
                setAssignTo(item);
              }
            }}
          />
          {formik.errors.assigned_to && (
            <p className={styles.errorTextStyle}>
              {formik.touched.assigned_to && formik.errors.assigned_to
                ? formik.errors.assigned_to
                : ''}
            </p>
          )}
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <React.Fragment>
        <div className={styles.userInfoWrapperStyle}>
          <div className={styles.labelWrapperStyle}>
            <div className={styles.viewAllWrapperStyle}>
              <div className={styles.viewAllSubWrapperStyle}>
                <p className={styles.lableTextStyle}>Reminders</p>
                <Image
                  containerStyle={styles.viewAllIconStyle}
                  src={infoCircleUpFillGrayIcon}
                  alt="icon"
                />
              </div>
              <Button
                title="Add reminder"
                leftIcon={multiselectPurpleIcon}
                btnStyle={styles.addNewReminderWrapperStyle}
                size="md"
                onClick={() => {
                  setIsShowEditModal(true);
                  setSelectedReminder(null);
                }}
              />
            </div>
          </div>
          <Reminders
            containerStyle={styles.remaindersContainerStyle}
            reminders={reminders}
            setReminders={setReminders}
            type="asset"
          />
        </div>
        <div className={styles.userInfoWrapperStyle}>
          <div className={styles.labelWrapperStyle}>
            <div className={styles.viewAllSubWrapperStyle}>
              <p className={styles.lableTextStyle}>Attachments</p>
              <Image
                containerStyle={styles.viewAllIconStyle}
                src={infoCircleUpFillGrayIcon}
                alt="icon"
              />
            </div>
          </div>
          <MultiFileUpload
            files={attachments || []}
            setFiles={(data) => setAttachments(data)}
            setFilesUploading={(val) => setAssetRegDisabled(val)}
            customListItemStyle={styles.customItemStyle}
          />
        </div>
      </React.Fragment>
    );
  };
  const renderAddReminderModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowEditModal}
        containerStyle={styles.reminderModalViewStyle}
        handleClickOutSide={() => setIsShowEditModal(false)}
      >
        <AddEditReminder
          type={reminderActionTypes.add}
          onclose={() => setIsShowEditModal(false)}
          reminder={selectedReminder}
          setReminder={(data) => handleReminders(data)}
        />
      </Modal>
    );
  };

  const renderSteps = () => {
    return (
      <div className={styles.paginationWrapperStyle}>
        {steps.map((item, index) => {
          return (
            <div key={index} className={styles.stepsContainerStyle}>
              <h4
                className={step >= index + 1 ? styles.stepsTextStyle : styles.stepActiveTextStyle}
              >
                {(index + 1).toString().padStart(2, '0')} - {item}
              </h4>
              <div
                className={
                  step >= index + 1 ? styles.stepStatusActiveBarStyle : styles.stepStatusBarStyle
                }
              ></div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.submitBtnWrapperStyle}>
        <Button
          title={step === 1 ? strings.cancel : strings.back}
          onClick={() => handlePreviousAction()}
          variant="light"
          size={'md'}
        />
        <Button
          title={step === 1 ? strings.nextStep : strings.assetAdd}
          size={'md'}
          type={'submit'}
          disabled={step !== 1 && assetRegDisabled}
          isLoading={isLoading}
        />
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal showOverlay={true} show={successModal} containerStyle={styles.modalViewStyle}>
        <SuccessCard
          title={strings.newAssetsAddedSuccessfull}
          description={strings.suggestionBoardDescp}
          leftBtnTitle={strings.backToDashBoard}
          leftAction={() => {
            setSuccessModal(false);
            navigate(routePaths.dashboard);
          }}
          rightBtnTitle={strings.continue}
          rightAction={() => setSuccessModal(false)}
        />
      </Modal>
    );
  };

  const renderSuccessDataAlertModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAlertModalOpen}
        containerStyle={styles.alertModalViewStyle}
      >
        <RegisterSuccessCard
          onClickAddAnother={() => {
            setIsShowAlertModalOpen(false);
            formik.resetForm();
            setAssignTo(null);
            setStep(1);
          }}
          onClickOverview={() => navigate(`/asset/details/${createdAsset._id}`)}
          headerText={'New asset added successfully!'}
          type={'asset'}
          data={createdAsset}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {/* <SuggestionCard
        title={strings.addingMuiltipleXlsFile}
        description={strings.suggestionBoardDescp}
      /> */}
      {renderButtonGroup()}
      {selectedTab === 'XLSX' && (
        <UploadXLSFile type="asset" onSuccess={() => setSuccessModal(true)} />
      )}
      {selectedTab === 'Manual' && renderManualProcess()}
      {selectedTab === 'API' && <p>Coming soon</p>}
      {renderModal()}
      {renderAddReminderModal()}
      {renderEmployeeTypes()}
      {renderCustomEmployeeRole()}
      {renderSuccessDataAlertModal()}
    </div>
  );
};

export default AssetRegistration;
