import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import { useStrings } from 'providers/stringsprovider';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Loader from 'components/common/loader';
import { googleIcon } from 'resources/images';
import { sendRegistrationOtpApi, superAdminRegisterApi } from 'networking/api/admin';
import NumberInput from 'components/common/phone-number-input';
import { useToast } from 'providers/toastprovider';
import styles from './style.module.css';

const SignUp = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { strings } = useStrings();
  const [actionLoading, setActionLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [registeredUserData, setRegisteredUserData] = useState({});
  const [apiError, setApiError] = useState('');
  const [schemaObj, setSchemaObj] = useState({
    full_name: Yup.string().required(strings.fullNameRequired),
    company_name: Yup.string().required(strings.companyNameRequired),
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (step === 1) {
      setSchemaObj({
        full_name: Yup.string().required(strings.fullNameRequired),
        company_name: Yup.string().required(strings.companyNameRequired),
      });
    }
    if (step === 2) {
      formik.setTouched({});
      setSchemaObj({
        ...schemaObj,
        email: Yup.string()
          .email(strings.validEmail)
          .matches(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, strings.validEmail)
          .required(strings.emailRequired),
        phone_number: Yup.object().shape({
          country_code: Yup.string().required(strings.countryCodeRequired),
          value: Yup.string().required(strings.phoneNumRequired),
        }),
        otp: undefined,
      });
    }
    if (step === 3) {
      setSchemaObj({
        ...schemaObj,
        otp: Yup.string().required(strings.otpRequired),
      });
    }
  }, [step]);

  const handleRegistration = async (values, { resetForm }) => {
    try {
      handleNextAndSubmitBtn(values);
      if (step === 3) {
        setActionLoading(true);
        const response = await superAdminRegisterApi(values);
        if (response.data.type === 'success') {
          toast.success('Registration success');
          setApiError('');
          resetForm();
          navigate('/login');
        } else {
          setApiError(response.data.message);
        }
        setActionLoading(false);
      }
    } catch (error) {
      console.log('API call error:', error);
      setActionLoading(false);
      setApiError(error.message);
    }
  };

  const signUpSchema = Yup.object().shape(schemaObj);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: '',
      company_name: '',
      email: '',
      phone_number: {
        country_code: '+46',
        value: '',
      },
      otp: '',
    },
    validationSchema: signUpSchema,
    onSubmit: handleRegistration,
  });

  const sendRegistrationOtp = async (data) => {
    setApiError('');
    try {
      setLoading(true);
      const updateData = { ...data, otp: undefined };
      const response = await sendRegistrationOtpApi(updateData);
      if (response.data.type === 'success') {
        setStep(3);
        setLoading(false);
        toast.success('OTP sent successfully');
      } else {
        console.log('send RegistrationOtp catch', response.data.message);
        setApiError(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log('send RegistrationOtp catch', error);
      setApiError(error.message);
      setLoading(false);
    }
  };
  const handleNextAndSubmitBtn = (data) => {
    if (step === 1) {
      formik.setTouched({});
      setStep(2);
    } else if (step === 2) {
      formik.setTouched({});
      setRegisteredUserData(data);
      sendRegistrationOtp(data);
    } else {
    }
  };

  const handleBackClick = () => {
    setStep(step - 1);
    setApiError('');
  };

  // ui sectioon

  const renderPagination = () => {
    return (
      <div className={styles.paginationContainerStyle}>
        {Array.from({ length: 3 }, (_, i) => (
          <div
            key={'pagination' + i + 1}
            className={step === i + 1 ? styles.activeDotBtnStyle : styles.dotBtnStyle}
          />
        ))}
      </div>
    );
  };

  const renderInputContainer = () => {
    return (
      <div className={styles.inputContainerStyle}>
        <div className={styles.signUpTopStyles}>
          <h2 className={styles.titleTestStyle}>
            {step === 1 || step === 2
              ? 'Create a trakr account in just a few steps.'
              : `We sent an OTP to ${formik.values.email}`}
          </h2>
          <div className={styles.signUpBlockStyles}>{renderHaveAnAccountContent()}</div>
        </div>
        <div className={styles.inputSubContainerStyle}>
          {step === 1
            ? renderStepOneContent()
            : step === 2
            ? renderStepTwoContent()
            : renderStepThreeContent()}
        </div>
        {renderBtnContainer()}
        <div className={styles.signUpBottomStyles}>{renderHaveAnAccountContent()}</div>
      </div>
    );
  };

  const renderBtnContainer = () => {
    return (
      <div className={styles.btnWrapperStyle}>
        {apiError && <p className={styles.errorTextStyle}>{apiError}</p>}
        <Button
          title={
            actionLoading
              ? 'Loading'
              : step === 1
              ? strings.nextStep
              : step === 2
              ? strings.continue
              : strings.finishSetup
          }
          type="submit"
          btnStyle={styles.proceedBtnStyles}
        >
          {actionLoading && <Loader loaderStyle={styles.loaderStyle} />}
        </Button>
        <Button
          title={step === 1 ? 'Login with Google' : 'Back'}
          btnStyle={step === 1 ? styles.googleBtnStyles : styles.proceedBtnStyles}
          leftIcon={step === 1 ? googleIcon : ''}
          leftIconStyle={styles.googleIconStyle}
          onClick={() => {
            if (step === 1) {
              console.log('cc');
            } else {
              handleBackClick();
            }
          }}
        />
      </div>
    );
  };

  const renderHaveAnAccountContent = () => {
    return (
      <div className={styles.haveAnAccountStyle}>
        <p className={styles.haveAnAccountTextStyle}>
          {strings.haveAnAccount}
          <span onClick={() => navigate('/login')} className={styles.loginTextStyle}>
            {strings.logIn}
          </span>
        </p>
      </div>
    );
  };

  const renderStepOneContent = () => {
    return (
      <div className={styles.inputwrapperStyle}>
        <Input
          name="full_name"
          labelText={strings.fullNameLabel}
          inputLabelStyle={styles.inputLabelStyle}
          placeholder={strings.enterFullName}
          value={formik.values.full_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('full_name', '')}
          hasError={
            formik.touched.full_name && formik.errors.full_name ? formik.errors.full_name : ''
          }
        />
        <Input
          name="company_name"
          labelText={strings.companyNameLabel + '*'}
          inputLabelStyle={styles.inputLabelStyle}
          placeholder={strings.enterCompanyName}
          value={formik.values.company_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('company_name', '')}
          hasError={
            formik.touched.company_name && formik.errors.company_name
              ? formik.errors.company_name
              : ''
          }
        />
      </div>
    );
  };

  const renderStepTwoContent = () => {
    return (
      <div className={styles.inputwrapperStyle}>
        <Input
          name="email"
          type="email"
          labelText={strings.emailLabelRequired}
          inputLabelStyle={styles.inputLabelStyle}
          placeholder={strings.enterEmailId}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('email', '')}
          hasError={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
        />
        <NumberInput
          name="phone_number.value"
          lableText={strings.phoneNumCompulsory}
          lableTextStyle={styles.inputLabelStyle}
          placeholder={strings.phoneNumHere}
          value={formik.values.phone_number?.value}
          // onChange={formik.handleChange}
          onChange={(e) => formik.setFieldValue('phone_number.value', e.target.value.toString())}
          countryCode={formik.values.phone_number?.country_code}
          onChangeCountryCode={(value) => formik.setFieldValue('phone_number.country_code', value)}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.phone_number?.value && formik.errors.phone_number?.value
              ? formik.errors.phone_number?.value
              : ''
          }
        />
      </div>
    );
  };

  const renderStepThreeContent = () => {
    return (
      <div className={styles.otpContainerStyle}>
        <Input
          name="otp"
          lableText={strings.enterOtpLabel}
          placeholder={strings.otpPlaceHolder}
          value={formik.values.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('otp', '')}
          hasError={formik.touched.otp && formik.errors.otp ? formik.errors.otp : ''}
        />
        <p className={styles.resendEditEmailTextViewStyle}>
          <span onClick={() => sendRegistrationOtp(registeredUserData)}>{strings.reSend}</span>
          {strings.or}{' '}
          <span
            onClick={() => {
              setStep(2);
              formik.setTouched({});
            }}
          >
            {strings.editEmail}
          </span>
        </p>
      </div>
    );
  };

  return (
    <AuthLayout>
      <div className={styles.mainContainerStyle}>
        {loading && <Loader />}
        {renderPagination()}
        <form onSubmit={formik.handleSubmit}>{renderInputContainer()}</form>
      </div>
    </AuthLayout>
  );
};

export default SignUp;
