import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import { useStrings } from 'providers/stringsprovider';
import SuggestionCard from 'components/suggestion-card';
import Button from 'components/common/button';
import Input from 'components/common/input';
import {
  checkBlueIcon,
  chevronDownFillGrayIcon,
  infoCircleUpFillGrayIcon,
  listAddBlueIcon,
  multiselectPurpleIcon,
  pencilEditBlackIcon,
  pencilEditIcon,
  xlsDarkIcon,
  xlsGrayIcon,
} from 'resources/images';
import Image from 'components/common/image';
import UploadXLSFile from 'components/upload-xls-file';
import SuccessCard from 'components/success-card';
import Modal from 'components/common/modal';
import routePaths from 'routes/paths';
import useWindowDimensions from 'hooks/useWindowDimensions';
import NumberInput from 'components/common/phone-number-input';
import PopOver from 'components/common/pop-over';
import RegisterSuccessCard from 'components/register-success-card';
import { employeeCreateApi } from 'networking/api/employee';
import { useAppData } from 'providers/appdataprovider';
import Reminders from 'sections/assets/reminders';
import MultiFileUpload from 'components/multi-file-upload';
import AddEditReminder from 'sections/assets/add-edit-reminder';
import { reminderActionTypes } from 'resources/data';
import styles from './styles.module.css';
import ImageUpload from 'components/image-upload';

const EmployeeRegistration = () => {
  const navigate = useNavigate();
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const { company } = useAuth();
  const { pathname } = useLocation();
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const { setUsers } = useUserData();
  const [typesRef, setTypesRef] = useState(null);
  const [isShowTypesOptions, setIsShowTypesOptions] = useState(false);
  const [customTypeRef, setCustomTypeRef] = useState(null);
  const [isShowCustomTypeInput, setIsShowCustomTypeInput] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Manual');
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [schemaObj, setSchemaObj] = useState({
    full_name: Yup.string().required(strings.fullNameRequired),
    // first_name: Yup.string().required(strings.firstNameRequired),
    // last_name: Yup.string().required(strings.lastNameRequired),
    employee_id: Yup.string().required(strings.employeeIdRequired),
    email: Yup.string()
      .email(strings.validEmail)
      .matches(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, strings.validEmail)
      .required(strings.emailRequired),
    account_type: Yup.string().required(strings.accountTypeRequired),
    phone_number: Yup.object().shape({
      country_code: Yup.string().notRequired(),
      value: Yup.number().required(strings.phoneNumRequired),
    }),
    employee_type: Yup.string().required(strings.employeeTypeRequired),
    bio: Yup.string(),
  });

  const default_categories = [strings.acountant, strings.developer, strings.designer];

  const account_types = [
    { value: 'Admin', label: strings.adminAccount },
    { value: 'Employee', label: strings.employeeAccount },
  ];

  const [categories, setCategories] = useState(default_categories);
  const [newCategory, setNewCategory] = useState('');
  const [isShowAlertModalOpen, setIsShowAlertModalOpen] = useState(false);
  const [createdEmployee, setCreatedEmployee] = useState({});
  const steps = [strings.employeeInfoRequire, strings.remindersAndattachments];
  const [step, setStep] = useState(1);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [reminders, setReminders] = useState([]);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [employeeRegDisabled, setEmployeeRegDisabled] = useState(false);
  const [showAccountTypeOptions, setShowAccountTypeOptions] = useState(false);
  const [showAccountTypesRef, setShowAccountTypesRef] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(true);
    }
  }, [width, pathname]);

  useEffect(() => {
    setBreadcrumbs([{ title: strings.addEmployees, route: '' }]);
  }, []);

  useEffect(() => {
    const new_categories = getCategories();
    if (new_categories && new_categories?.length > 0 && Array.isArray(new_categories)) {
      setCategories([...new_categories]);
    } else {
      setCategories(default_categories);
      localStorage.setItem('EMPLOYEE_CAT', JSON.stringify(default_categories));
    }
  }, []);

  const handleNextAndSubmitBtn = (values) => {
    if (step === 1) {
      setStep(2);
      if (apiError) {
        setApiError('');
      }
    } else if (step === 2) {
      handleEmployeeCreate(values);
    }
  };

  const handleEmployeeCreate = async (values) => {
    setIsLoading(true);
    try {
      const response = await employeeCreateApi({
        ...values,
        reminders,
        attachments: attachments.map((item) => item._id).filter(Boolean),
      });
      if (response.data.type === 'success') {
        setApiError('');
        setStep(1);
        setCreatedEmployee(response.data.data);
        setIsShowAlertModalOpen(true);
        setUsers((prev) => {
          let prevData = [...prev];
          let findIndex = prevData.findIndex((item) => item._id === response.data.data._id);
          if (findIndex !== -1) {
            prevData[findIndex] = response.data.data;
            return prevData;
          } else {
            return [response.data.data, ...prevData];
          }
        });
        formik.resetForm();
        setCreatedEmployee(response.data.data);
        setAttachments([]);
        setReminders([]);
        setSelectedImageName('');
        setIsLoading(false);
      } else {
        setApiError(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setApiError(error.message);
      console.log('API call error:', error.message);
    }
  };

  const userSchema = Yup.object().shape(schemaObj);

  const formik = useFormik({
    initialValues: {
      // first_name: '',
      // last_name: '',
      full_name: '',
      employee_id: '',
      email: '',
      account_type: '',
      phone_number: {
        country_code: '+46',
        value: '',
      },
      employee_type: '',
      company: company ? company._id : '',
      image: '',
      bio: '',
    },
    validationSchema: userSchema,
    onSubmit: handleNextAndSubmitBtn,
  });

  const getCategories = () => {
    let new_categories = localStorage.getItem('EMPLOYEE_CAT');
    setCategories(JSON.parse(new_categories));
    let new_parsed_categories = JSON.parse(new_categories);
    return new_parsed_categories || [];
  };

  const handleNewCategory = () => {
    let old_categories = getCategories();
    let data = old_categories?.length > 0 ? old_categories : [];
    let existing = data.find((item) => item === newCategory);
    if (!existing) {
      data.push(newCategory);
    }
    localStorage.setItem('EMPLOYEE_CAT', JSON.stringify(data));
    getCategories();
    formik.setFieldValue('employee_type', newCategory);
    setIsShowCustomTypeInput(false);
    setNewCategory('');
  };

  const handlePreviousAction = () => {
    if (step === 1) {
      formik.resetForm();
      formik.setFieldValue('account_type', '');
    } else if (step === 2) {
      setStep(1);
    }
    // else if (step === 3) {
    //   setStep(1);
    // } else if (step === 4) {
    //   setStep(3);
    // }
  };

  const handleReminders = (data) => {
    let remindersData = [...reminders];
    let findIndex = reminders.findIndex((item) => item.local_id === data.local_id);

    if (findIndex !== -1) {
      remindersData[findIndex] = data;
      setReminders(remindersData);
    } else {
      setReminders([...reminders, data]);
    }
  };

  // ui section

  const renderButtonGroup = () => {
    return (
      <div className={styles.btnGroupWrapperStyle}>
        <Button
          title={strings.addManually}
          onClick={() => setSelectedTab('Manual')}
          btnStyle={classNames(
            styles.tabBtnStyle,
            selectedTab === 'Manual' && styles.selectedTabBtnStyle
          )}
          leftIcon={selectedTab === 'Manual' ? pencilEditBlackIcon : pencilEditIcon}
          leftIconStyle={styles.tabBtnIconStyle}
        />
        {/* 
         // Todo commented for the feture use
        <Button
          title={strings.importXLSFile}
          onClick={() => setSelectedTab('XLSX')}
          btnStyle={classNames(
            styles.tabBtnStyle,
            selectedTab === 'XLSX' && styles.selectedTabBtnStyle
          )}
          leftIcon={selectedTab === 'XLSX' ? xlsDarkIcon : xlsGrayIcon}
          leftIconStyle={styles.tabBtnIconStyle}
        /> */}
        {/* <Button
          title={strings.importFromAPI}
          onClick={() => setSelectedTab('API')}
          btnStyle={classNames(
            styles.tabBtnStyle,
            selectedTab === 'API' && styles.selectedTabBtnStyle
          )}
          leftIcon={selectedTab === 'API' ? plugDarkIcon : plugGrayIcon}
          leftIconStyle={styles.tabBtnIconStyle}
        /> */}
      </div>
    );
  };

  const renderManualProcess = () => {
    return (
      <div className={styles.employeeDataWrapperStyle}>
        <h2 className={styles.formTitleStyle}>{strings.employeeDetails}</h2>
        {renderSteps()}
        <form onSubmit={formik.handleSubmit} className={styles.formWrapperStyle}>
          {step === 1 ? renderForm() : renderRemaindersAttachments()}
          {renderBtns()}
        </form>
      </div>
    );
  };

  const renderSteps = () => {
    return (
      <div className={styles.paginationWrapperStyle}>
        {steps.map((item, index) => {
          return (
            <div key={index} className={styles.stepsContainerStyle}>
              <h4
                className={step >= index + 1 ? styles.stepsTextStyle : styles.stepActiveTextStyle}
              >
                {(index + 1).toString().padStart(2, '0')} - {item}
              </h4>
              <div
                className={
                  step >= index + 1 ? styles.stepStatusActiveBarStyle : styles.stepStatusBarStyle
                }
              ></div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderEmployeeAccountTypes = () => {
    return (
      <PopOver
        show={showAccountTypeOptions}
        reference={showAccountTypesRef}
        onClose={() => setShowAccountTypeOptions(false)}
        containerStyle={styles.optionsSectionStyle}
        placement="bottom-end"
        offset={[0, 12]}
      >
        <div className={styles.optionsContainerStyle}>
          {account_types?.map((item, index) => {
            return (
              <div
                className={classNames(
                  styles.optionItemWrapperStyle,
                  formik.values.account_type === item.value
                    ? styles.selectedOptionItemWrapperStyle
                    : ''
                )}
                key={index}
              >
                <p
                  onClick={() => {
                    formik.setFieldValue('account_type', item.value);
                    setShowAccountTypeOptions(false);
                  }}
                  className={classNames(styles.optionItemTextStyle)}
                >
                  {item.label}
                </p>
              </div>
            );
          })}
        </div>
      </PopOver>
    );
  };

  const renderEmployeeTypes = () => {
    return (
      <PopOver
        show={isShowTypesOptions}
        reference={typesRef}
        onClose={() => {
          if (!isShowCustomTypeInput) {
            setIsShowTypesOptions(false);
          }
        }}
        containerStyle={styles.actionPopUpViewStyle}
        placement="bottom-end"
        // offset={[0, -44]}
      >
        <div className={styles.actionOptionsViewStyle}>
          {categories?.map((item, index) => {
            return (
              <div className={styles.optiondTextWrapperStyle} key={index}>
                <p
                  onClick={() => {
                    formik.setFieldValue('employee_type', item);
                    setIsShowTypesOptions(false);
                  }}
                  className={classNames(styles.optionsViewStyles)}
                >
                  {item}
                </p>
                {formik.values.employee_type === item && (
                  <Image src={checkBlueIcon} containerStyle={styles.checkIconStyle} />
                )}
              </div>
            );
          })}
        </div>
        <div
          className={styles.customTypeContainerStyle}
          onClick={() => setIsShowCustomTypeInput(true)}
          ref={setCustomTypeRef}
        >
          <Image src={listAddBlueIcon} containerStyle={styles.listIconStyle} />
          <p className={styles.customOptionsViewStyles}>Add custom role</p>
        </div>
      </PopOver>
    );
  };

  const renderCustomEmployeeRole = () => {
    return (
      <PopOver
        show={isShowCustomTypeInput}
        reference={customTypeRef}
        onClose={() => setIsShowCustomTypeInput(false)}
        containerStyle={styles.inputPopUpViewStyle}
        showOverlay
        overlayStyle={styles.overlayStyle}
      >
        <div className={styles.customEmployeeViewStyle}>
          <Input
            labelText={strings.employeeRole}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.employeeTypeRequired}
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onBlur={formik.handleBlur}
            containerStyle={styles.inputStyle}
          />
          <div className={styles.popUpBtnContainerStyle}>
            <Button
              title="Cancel"
              size="md"
              variant="light"
              onClick={() => {
                setIsShowCustomTypeInput(false);
                setNewCategory('');
              }}
            />
            <Button
              title="Save entry"
              size="md"
              disabled={newCategory !== '' ? false : true}
              onClick={() => handleNewCategory()}
            />
          </div>
        </div>
      </PopOver>
    );
  };

  const renderForm = () => {
    return (
      <div className={styles.inputWrapperStyle}>
        <div className={styles.inputSectionViewStyle}>
          <Input
            name="full_name"
            labelText={strings.fullNameLabel}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.userNameRequired}
            value={formik.values.full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.full_name && formik.errors.full_name ? formik.errors.full_name : ''
            }
            containerStyle={styles.inputStyle}
          />
          <Input
            name="email"
            labelText={strings.emailIdRequired}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.emailIdType}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            containerStyle={styles.inputStyle}
          />
          {/* <Input
            name="last_name"
            labelText={strings.lastName}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.userNameRequired}
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.last_name && formik.errors.last_name ? formik.errors.last_name : ''
            }
            containerStyle={styles.inputStyle}
          /> */}
        </div>
        <div className={styles.inputSectionViewStyle}>
          <Input
            name="employee_id"
            labelText={strings.uniqueIdRequire}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.uidExamp}
            value={formik.values.employee_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.employee_id && formik.errors.employee_id
                ? formik.errors.employee_id
                : ''
            }
            containerStyle={styles.inputStyle}
          />
          <div className={styles.selectTypeInputContainerStyle}>
            <p className={styles.lableTextStyle}>{strings.accountTypeLabel}</p>
            <div
              className={styles.selectTypeContainerStyle}
              onClick={() => setShowAccountTypeOptions(true)}
              ref={setShowAccountTypesRef}
            >
              <p
                className={
                  formik.values.account_type ? styles.valueTextStyle : styles.placeHolderTextStyle
                }
              >
                {formik.values.account_type
                  ? formik.values.account_type
                  : strings.selectAccountType}
              </p>
              <Image
                src={chevronDownFillGrayIcon}
                containerStyle={styles.arrowDownIconStyle}
                imgStyle={showAccountTypeOptions ? styles.dropDownIconStyle : ''}
              />
            </div>
            {formik.errors.account_type && (
              <p className={styles.errorTextStyle}>
                {formik.touched.account_type && formik.errors.account_type
                  ? formik.errors.account_type
                  : ''}
              </p>
            )}
          </div>
        </div>
        <div className={styles.inputSectionViewStyle}>
          <NumberInput
            name="phone_number.value"
            lableText={strings.phoneNumCompulsory}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.phoneNumHere}
            value={formik.values.phone_number?.value}
            onChange={(e) => formik.setFieldValue('phone_number.value', e.target.value.toString())}
            countryCode={formik.values.phone_number?.country_code}
            onChangeCountryCode={(value) =>
              formik.setFieldValue('phone_number.country_code', value)
            }
            onBlur={formik.handleBlur}
            // onFocus={() => formik.setFieldError('phone_number.value', '')}
            hasError={
              formik.touched.phone_number?.value && formik.errors.phone_number?.value
                ? formik.errors.phone_number?.value
                : ''
            }
          />
          <div className={styles.selectTypeInputContainerStyle}>
            <p className={styles.lableTextStyle}>{strings.employeeRole}</p>
            <div
              className={styles.selectTypeContainerStyle}
              onClick={() => {
                setIsShowTypesOptions(true);
              }}
              ref={setTypesRef}
            >
              <p
                className={
                  formik.values.employee_type ? styles.valueTextStyle : styles.placeHolderTextStyle
                }
              >
                {formik.values.employee_type ? formik.values.employee_type : strings.selectEmpROle}
              </p>
              <Image
                src={chevronDownFillGrayIcon}
                containerStyle={styles.arrowDownIconStyle}
                imgStyle={isShowTypesOptions ? styles.dropDownIconStyle : ''}
              />
            </div>
            {formik.errors.employee_type && (
              <p className={styles.errorTextStyle}>
                {formik.touched.employee_type && formik.errors.employee_type
                  ? formik.errors.employee_type
                  : ''}
              </p>
            )}
          </div>
        </div>
        <ImageUpload
          label="Employee image"
          placeholder="JPG, JPEG, or PNG (Less than 5MB)"
          image={formik.values.image}
          onSuccess={(img) => formik.setFieldValue('image', img)}
          setImageName={(val) => setSelectedImageName(val)}
          imageName={selectedImageName}
        />
        <Input
          type="textarea"
          name="bio"
          labelText={strings.bio}
          inputLabelStyle={styles.lableTextStyle}
          placeholder={strings.employeeBio}
          value={formik.values.bio}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inputStyle={styles.inputStyle}
        />
      </div>
    );
  };

  const renderRemaindersAttachments = () => {
    return (
      <React.Fragment>
        <div className={styles.userInfoWrapperStyle}>
          <div className={styles.labelWrapperStyle}>
            <div className={styles.viewAllWrapperStyle}>
              <div className={styles.viewAllSubWrapperStyle}>
                <p className={styles.lableTextStyle}>Reminders</p>
                <Image
                  containerStyle={styles.viewAllIconStyle}
                  src={infoCircleUpFillGrayIcon}
                  alt="icon"
                />
              </div>
              <Button
                title="Add reminder"
                leftIcon={multiselectPurpleIcon}
                btnStyle={styles.addNewReminderWrapperStyle}
                size="md"
                onClick={() => {
                  setIsShowEditModal(true);
                  setSelectedReminder(null);
                }}
              />
            </div>
          </div>
          <Reminders
            containerStyle={styles.remaindersContainerStyle}
            reminders={reminders}
            setReminders={setReminders}
            type="user"
          />
        </div>
        <div className={styles.userInfoWrapperStyle}>
          <div className={styles.labelWrapperStyle}>
            <div className={styles.viewAllSubWrapperStyle}>
              <p className={styles.lableTextStyle}>Attachments</p>
              <Image
                containerStyle={styles.viewAllIconStyle}
                src={infoCircleUpFillGrayIcon}
                alt="icon"
              />
            </div>
          </div>
          <MultiFileUpload
            files={attachments || []}
            setFiles={(data) => setAttachments(data)}
            setFilesUploading={(val) => setEmployeeRegDisabled(val)}
            customListItemStyle={styles.customItemStyle}
          />
        </div>
      </React.Fragment>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnContainerStyle}>
        <p className={styles.errorTextStyle}>{apiError}</p>
        <div className={styles.btnsViewStyle}>
          <Button
            onClick={() => {
              // formik.resetForm();
              // formik.setFieldValue('account_type', '');
              handlePreviousAction();
            }}
            title={step === 1 ? strings.cancel : strings.back}
            size={width > 480 ? 'lg' : 'md'}
            variant="light"
          />
          <Button
            title={step === 1 ? strings.nextStep : strings.addEmployee}
            type={'submit'}
            size={width > 480 ? 'lg' : 'md'}
            isLoading={isLoading}
            disabled={step !== 1 && employeeRegDisabled}
          />
        </div>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal showOverlay={true} show={isModalOpen} containerStyle={styles.modalViewStyle}>
        <SuccessCard
          title={strings.newUsersAddedSuccessful}
          description={strings.newUserAddedSuccessfulDesc}
          leftBtnTitle={strings.backToDashBoard}
          leftAction={() => {
            setIsModalOpen(false);
            navigate(routePaths.dashboard);
          }}
          rightBtnTitle={strings.continue}
          rightAction={() => setIsModalOpen(false)}
        />
      </Modal>
    );
  };

  const renderEmployeesucessAlertModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAlertModalOpen}
        containerStyle={styles.alertModalViewStyle}
      >
        <RegisterSuccessCard
          headerText={'New employee added successfully!'}
          onClickAddAnother={() => {
            setIsShowAlertModalOpen(false);
            formik.resetForm();
          }}
          onClickOverview={() => navigate(routePaths.employeeDetails(createdEmployee._id))}
          data={createdEmployee}
        />
      </Modal>
    );
  };

  const renderAddReminderModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowEditModal}
        containerStyle={styles.reminderModalViewStyle}
        handleClickOutSide={() => setIsShowEditModal(false)}
      >
        <AddEditReminder
          type={reminderActionTypes.add}
          onclose={() => setIsShowEditModal(false)}
          reminder={selectedReminder}
          setReminder={(data) => handleReminders(data)}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      <SuggestionCard
        title={
          selectedTab === 'XLSX'
            ? strings.suggestionBoardTitleForAddUserByXls
            : strings.suggestionBoardTtleForAddUserByManual
        }
        description={
          selectedTab === 'XLSX'
            ? strings.suggestionBoardDescForAddUserByXls
            : strings.suggestionBoardDescForAddUserByManual
        }
      />
      <div className={styles.subContainerStyle}>
        {renderButtonGroup()}
        {selectedTab === 'XLSX' && (
          <UploadXLSFile type="user" onSuccess={() => setIsModalOpen(true)} />
        )}
        {selectedTab === 'Manual' && renderManualProcess()}
      </div>
      {selectedTab === 'API' && <p>Coming soon</p>}
      {renderModal()}
      {renderEmployeeAccountTypes()}
      {renderEmployeeTypes()}
      {renderCustomEmployeeRole()}
      {renderEmployeesucessAlertModal()}
      {renderAddReminderModal()}
    </div>
  );
};

export default EmployeeRegistration;
