import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { assetIcon, closeIcon, searchIcon } from 'resources/images';
import { useUserData } from 'providers/userdataprovider';
import routePaths from 'routes/paths';
import Input from 'components/common/input';
import Capsule from 'components/common/capsule';
import AvatarInfoCard from 'components/common/avatar-info-card';
import styles from './styles.module.css';

const SearchBox = (props) => {
  const { setClose } = props;
  const { users, assets, tickets } = useUserData();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [filterBy, setFilterBy] = useState('All');
  const [filteredUsers, setFlteredUsers] = useState([]);
  const [filteredAssets, setFlteredAssets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const Options = ['All', 'Users', 'Assets', 'Tickets'];

  useEffect(() => {
    if (query.length > 0) {
      setFlteredUsers(
        users.filter((user) => JSON.stringify(user).toLowerCase().includes(query.toLowerCase()))
      );
      setFlteredAssets(
        assets.filter((asset) => JSON.stringify(asset).toLowerCase().includes(query.toLowerCase()))
      );
      setFilteredTickets(
        tickets.filter((ticket) =>
          JSON.stringify(ticket).toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setFlteredUsers([]);
      setFlteredAssets([]);
      setFilteredTickets([]);
    }
  }, [users, query]);

  const renderSearchInput = () => {
    return (
      <div className={styles.searchInputWrapperStyle}>
        <Input
          placeholder="Type anything here . . ."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          leftIcon={searchIcon}
          rightIcon={closeIcon}
          inputStyle={styles.searchInputStyle}
          onClickRightIcon={() => setClose(false)}
        />
      </div>
    );
  };

  const renderSearchResults = () => {
    return (
      <div className={styles.searchResultsWrapperStyle}>
        {renderFilterBySection()}
        {(filterBy === 'All' || filterBy === 'Users') && renderUserSection()}
        {(filterBy === 'All' || filterBy === 'Assets') && renderAssetsSection()}
        {(filterBy === 'All' || filterBy === 'Tickets') && renderTicketsSection()}
      </div>
    );
  };

  const renderFilterBySection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>Filter by</p>
        <div className={styles.filterOptionsViewStyle}>
          {Options.map((opt, index) => (
            <Capsule
              key={index}
              label={opt}
              onClick={() => setFilterBy(opt)}
              labelStyle={classNames(
                styles.capsuleLableStyle,
                filterBy === opt && styles.activeOptionTextStyle
              )}
              containerStyle={classNames(
                styles.capsuleViewStyle,
                filterBy === opt && styles.activeOptionStyle
              )}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderUserSection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>
          Users <span>{filteredUsers?.length}</span>
        </p>
        {filteredUsers.length > 0 && (
          <div className={styles.usersViewStyle}>
            {filteredUsers
              ?.slice(0, filterBy === 'Users' ? filteredUsers.length : 3)
              .map((user, index) => (
                <AvatarInfoCard
                  key={'usr' + index}
                  icon={user.image}
                  title={user.full_name}
                  description={user.email}
                  containerStyle={styles.blockListItemStyle}
                  avatarStyle={styles.blockListItemUserIconStyle}
                  titleStyle={styles.blockListItemTitleStyle}
                  onClick={() => {
                    navigate(routePaths.employeeDetails(user._id));
                    setClose(false);
                  }}
                />
              ))}
            <p
              className={styles.blockLinkStyle}
              onClick={() => {
                navigate(routePaths.employeeList);
                setClose(false);
              }}
            >
              See all results
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderAssetsSection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>
          Assets <span>{filteredAssets?.length}</span>
        </p>
        {filteredAssets.length > 0 && (
          <div className={styles.usersViewStyle}>
            {filteredAssets
              ?.slice(0, filterBy === 'Assets' ? filteredAssets.length : 3)
              .map((asset, index) => (
                <AvatarInfoCard
                  key={'asset' + index}
                  icon={asset.image || assetIcon}
                  title={`${asset.asset_uid} : ${asset.asset_name}`}
                  description={
                    asset.assigned_to ? `Assigned to ${asset.assigned_to.full_name}` : 'UnAssigned'
                  }
                  containerStyle={styles.blockListItemStyle}
                  avatarStyle={styles.blockListItemAssetIconStyle}
                  titleStyle={styles.blockListItemTitleStyle}
                  onClick={() => {
                    navigate(routePaths.assetDetails(asset._id));
                    setClose(false);
                  }}
                />
              ))}
            <p
              className={styles.blockLinkStyle}
              onClick={() => {
                navigate(routePaths.assetList);
                setClose(false);
              }}
            >
              See all resultes
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderTicketsSection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>
          Tickets <span>{filteredTickets?.length}</span>
        </p>
        {filteredTickets.length > 0 && (
          <div className={styles.usersViewStyle}>
            {filteredTickets
              ?.slice(0, filterBy === 'Tickets' ? filteredTickets.length : 3)
              .map((ticket, index) => (
                <AvatarInfoCard
                  key={'ticket' + index}
                  icon={ticket?.image || ticket?.asset?.image}
                  title={`${ticket.ticket_uid} : ${ticket.subject}`}
                  description={ticket.ticket_status}
                  containerStyle={styles.blockListItemStyle}
                  avatarStyle={styles.blockListItemIconStyle}
                  titleStyle={styles.blockListItemTitleStyle}
                  onClick={() => {
                    navigate(routePaths.ticketList + `?selected=${ticket._id}`);
                    setClose(false);
                  }}
                />
              ))}
            <p
              className={styles.blockLinkStyle}
              onClick={() => {
                navigate(routePaths.ticketList);
                setClose(false);
              }}
            >
              See all resultes
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.searchBoxWrapperStyle}>
      {renderSearchInput()}
      {query.length > 0 && renderSearchResults()}
    </div>
  );
};

SearchBox.propTypes = {
  setClose: PropTypes.func,
};

export default SearchBox;
