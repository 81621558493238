import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Image = (props) => {
  const { reference, src, alt, containerStyle, imgStyle, onClick, children } = props;
  return (
    <div
      className={classNames(styles.containerStyle, onClick && styles.pointerSyle, containerStyle)}
      onClick={onClick}
      ref={reference}
    >
      <img src={src} alt={alt} className={classNames(styles.imgStyle, imgStyle)} />
      {children && children}
    </div>
  );
};

Image.propTypes = {
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
  imgStyle: PropTypes.string,
  children: PropTypes.node,
};

export default Image;
