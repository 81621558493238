import Image from 'components/common/image';
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  checkCircleFillPurpleIcon,
  infoCircleIcon,
  multiSelectIcon,
  multiselectPurpleIcon,
  optionsVerticalGrayIcon,
  uncheckPurpleIcon,
} from 'resources/images';
import PopOver from 'components/common/pop-over';
import classNames from 'classnames';
import Modal from 'components/common/modal';
import Button from 'components/common/button';
import AddEditReminder from '../add-edit-reminder';
import { deleteReminderApi } from 'networking/api/asset';
import { useAuth } from 'providers/authprovider';
import { account_types, reminderActionTypes } from 'resources/data';
import { deleteUserReminderApi } from 'networking/api/employee';
import ReminderDetails from 'components/reminder-details';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const Reminders = (props) => {
  const { containerStyle, reminders, setReminders, type } = props;
  const { userType } = useAuth();
  const toast = useToast();
  const [assetRef, setAssetRef] = useState();
  const [isShowReminderOptions, setIsShowReminderOptions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [selectedReminderIndex, setSelectedReminderIndex] = useState(-1);
  const [actionLoading, setActionLoading] = useState(false);
  const reminderOptions = ['Edit reminder', 'Delete reminder'];
  const [showReminderDetails, setShowReminderDetails] = useState(false);

  const handleOptions = (option) => {
    if (option === 'Edit reminder') {
      setIsShowReminderOptions(false);
      setReminderModal(true);
    } else {
      setIsShowReminderOptions(false);
      setIsModalOpen(true);
    }
  };

  const handleReminders = (data) => {
    let remindersData = [...reminders];
    let findIndex = remindersData.findIndex(
      (item) =>
        (data._id && item._id === data._id) || (data.local_id && item?.local_id === data?.local_id)
    );
    if (findIndex !== -1) {
      remindersData[findIndex] = data;
      setReminders(remindersData);
    } else {
      setReminders([...reminders, data]);
    }
  };

  const handleDeleteReminder = async () => {
    try {
      if (selectedReminder?._id) {
        setActionLoading(true);
        let response = null;
        if (type === 'user') {
          response = await deleteUserReminderApi(selectedReminder._id);
        } else {
          response = await deleteReminderApi(selectedReminder._id);
        }
        if (response.data.type === 'success') {
          setReminders((prev) => {
            let previous_data = [...prev];
            previous_data = previous_data.filter((item) => item._id !== selectedReminder._id);
            return previous_data;
          });
          setSelectedReminder(null);
          setSelectedReminderIndex(-1);
          setIsModalOpen(false);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
          console.log('Delete reminder api fail', response.data.message);
        }
        setActionLoading(false);
      } else {
        setReminders((prev) => {
          let previous_data = [...prev];
          previous_data = previous_data.filter((item, index) => index !== selectedReminderIndex);
          return previous_data;
        });
        setSelectedReminder(null);
        setSelectedReminderIndex(-1);
        setIsModalOpen(false);
      }
    } catch (error) {
      setActionLoading(false);
      toast.error(error.message);
      console.log('Delete reminder api catch', error.message);
    }
  };

  const renderAddReminderModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={reminderModal}
        containerStyle={styles.reminderModalViewStyle}
        handleClickOutSide={() => setReminderModal(false)}
      >
        <AddEditReminder
          type={reminderActionTypes.edit}
          handleWithApi
          onclose={() => setReminderModal(false)}
          reminder={selectedReminder}
          setReminder={(data) => handleReminders(data)}
        />
      </Modal>
    );
  };

  const renderReminderOptions = () => {
    return (
      <PopOver
        show={isShowReminderOptions}
        reference={assetRef}
        onClose={() => {
          setIsShowReminderOptions(false);
          setSelectedReminder(null);
          setSelectedReminderIndex(-1);
        }}
        placement="bottom-end"
        containerStyle={styles.actionPopUpViewStyle}
      >
        <div className={styles.actionOptionsViewStyle}>
          {reminderOptions.map((option, index) => {
            return (
              <p
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptions(option);
                }}
                className={classNames(styles.optionsViewStyles)}
              >
                {option}
              </p>
            );
          })}
        </div>
      </PopOver>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal showOverlay={true} show={isModalOpen} containerStyle={styles.alertModalViewStyle}>
        <div className={styles.deleteModalContainerStyle}>
          <p className={styles.deleteTextStyle}>
            Are you sure you want to delete the reminder with title
            <span className={styles.spanTextStyle}>{`“ ${selectedReminder?.title}”`}</span>
          </p>
          <div className={styles.btnContainerStyle}>
            <Button
              title="Cancel"
              variant="light"
              disabled={actionLoading}
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              title="Yes"
              btnStyle={styles.yesBtnStyle}
              disabled={actionLoading}
              onClick={handleDeleteReminder}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderReminderOverViewModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={showReminderDetails}
        onClose={() => setShowReminderDetails(false)}
        closeOnOutSideClick
        containerStyle={styles.remainderOverModalStyle}
        overlayStyle={styles.remainderOverModalOverlayStyle}
      >
        <ReminderDetails
          reminder={selectedReminder}
          onCloseAction={() => {
            setShowReminderDetails(false);
            setSelectedReminder({});
          }}
        />
      </Modal>
    );
  };
  return (
    <div className={classNames(styles.remaindersMainContainerStyle, containerStyle)}>
      {reminders.length > 0 ? (
        <React.Fragment>
          {reminders?.map((item, index) => {
            return (
              <div key={index} className={styles.remainderItemWrapperStyle}>
                <div
                  onClick={() => {
                    if (item._id) {
                      setSelectedReminder(item);
                      setShowReminderDetails(true);
                    }
                  }}
                  className={styles.remainderItemLeftWrapperStyle}
                >
                  <Image
                    containerStyle={styles.iconCheckContainerStyle}
                    src={item.status?.length > 0 ? checkCircleFillPurpleIcon : uncheckPurpleIcon}
                    alt="icon"
                  />
                  <div className={styles.remainderTextWrapperStyle}>
                    <p className={styles.remainderTitleTextStyle}>{item.title}</p>
                    <p className={styles.remainderTimeTextStyle}>
                      {moment(item.start_date).format('DD/MM/YYYY')} •{' '}
                      {moment(item.start_date).format('LT')}{' '}
                      {item?.repeat_interval &&
                        ` • ${item?.repeat_interval?.value}
                      ${item?.repeat_interval?.type}.`}
                    </p>
                  </div>
                </div>
                <Image
                  containerStyle={classNames(
                    styles.iconOptionsContainerStyle,
                    userType === account_types.employee && styles.disableviewStyle
                  )}
                  src={optionsVerticalGrayIcon}
                  alt="icon"
                  onClick={(e) => {
                    setSelectedReminder(item);
                    setSelectedReminderIndex(index);
                    setAssetRef(e.target);
                    setIsShowReminderOptions(true);
                  }}
                />
              </div>
            );
          })}
        </React.Fragment>
      ) : (
        <div className={styles.noRemaindersWrapperStyle}>
          <Image containerStyle={styles.iconContainerStyle} src={infoCircleIcon} alt="icon" />
          <p className={styles.noRemindersTextStyle}>
            You have no reminders set for this {type}. Click on “Add reminder” to get started.
            <span className={styles.learnMoreTextStyle}> Learn more</span> about reminders.
          </p>
        </div>
      )}
      {renderDeleteModal()}
      {renderReminderOptions()}
      {renderAddReminderModal()}
      {renderReminderOverViewModal()}
    </div>
  );
};
Reminders.propTypes = {
  reminders: PropTypes.array,
  setReminders: PropTypes.func,
  type: PropTypes.oneOf(['asset', 'user']),
};
export default Reminders;
