import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { closeIcon, pencilEditIcon, pencilIcon } from 'resources/images';
import Image from 'components/common/image';
import Capsule from 'components/common/capsule';
import styles from './styles.module.css';

const ClusterInfo = (props) => {
  const { cluster, closeAction = () => {}, editClusterAction = () => {} } = props;

  const renderHeaderSection = () => {
    return (
      <div className={styles.clusterInfoHeaderViewStyle}>
        <div className={styles.clusterInfoHeaderTextViewStyle}>
          <p className={styles.headerTextStyle}>{cluster.name}</p>
          <p className={styles.headerDescTextStyle}>{cluster.type} maintenance</p>
        </div>
        <div className={styles.clusterInfoHeaderRightViewStyle}>
          {!cluster.archived_at && (
            <Image
              src={pencilIcon}
              alt="img"
              onClick={editClusterAction}
              containerStyle={styles.closeIconStyle}
            />
          )}
          <Image
            src={closeIcon}
            alt="img"
            onClick={closeAction}
            containerStyle={styles.closeIconStyle}
          />
        </div>
      </div>
    );
  };

  const renderAssetsSection = () => {
    return (
      <div className={styles.assetsSectionViewStyle}>
        <p className={styles.labelTextStyle}>Added assets: </p>
        <div className={styles.assestsViewStyle}>
          {cluster.cluster_assets.map((item, index) => (
            <Capsule
              key={'ast' + index}
              label={item.asset.asset_name}
              containerStyle={styles.capsuleViewStyle}
              labelStyle={styles.capsuleTitleStyle}
            />
          ))}
        </div>
      </div>
    );
  };
  const renderassetUsersSection = () => {
    return (
      <div className={styles.assetsSectionViewStyle}>
        <p className={styles.labelTextStyle}>Assigned to:</p>
        <div className={styles.assestsViewStyle}>
          {cluster.users.map((item, index) => (
            <Capsule
              key={'ast' + index}
              label={item.user.full_name}
              containerStyle={styles.userCapsuleViewStyle}
              labelStyle={styles.capsuleTitleStyle}
            />
          ))}
        </div>
      </div>
    );
  };

  const getTitle = () => {
    let msg = '';
    if (cluster.type !== 'Custom') {
      msg += cluster.type;
      if (cluster.end_date) {
        msg += ` until ${moment(cluster.end_date).format('YYYY-MM-DD')}`;
      }
    } else {
      msg += `Every ${cluster.recurrence.value} ${cluster.recurrence.type}`;
      if (cluster.end_date) {
        msg += ` until ${moment(cluster.end_date).format('YYYY-MM-DD')}`;
      }
    }
    return msg;
  };

  const renderMaintenanceSection = () => {
    return (
      <div className={styles.assetsSectionViewStyle}>
        <p className={styles.labelTextStyle}>Maintenance frequency:</p>
        <div className={styles.assestsMaintanenceViewStyle}>
          <Capsule
            label={getTitle()}
            containerStyle={styles.maintenanceCapsuleViewStyle}
            labelStyle={styles.capsuleTitleStyle}
          />
          <div className={styles.startDateViewStyle}>
            <p>Start date:</p>
            <Capsule
              label={moment(cluster.start_date).format('Do MMM YYYY')}
              containerStyle={styles.startDateCapsuleViewStyle}
              labelStyle={styles.capsuleTitleStyle}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.clusterInfoViewStyle}>
      {renderHeaderSection()}
      <div className={styles.bodyViewStyle}>
        {renderAssetsSection()}
        {renderassetUsersSection()}
        {renderMaintenanceSection()}
      </div>
    </div>
  );
};

ClusterInfo.propTypes = {
  cluster: PropTypes.object,
  closeAction: PropTypes.func,
  editClusterAction: PropTypes.func,
};

export default ClusterInfo;
