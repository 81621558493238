import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as PopperJS from '@popperjs/core';
import { closeLightGrayIcon } from 'resources/images';
import PopOver from 'components/common/pop-over';
import Input from 'components/common/input';
import SelectedCard from 'components/common/selected-card';
import Avatar from 'components/common/avatar';
import useWindowDimensions from 'hooks/useWindowDimensions';
import styles from './styles.module.css';

const MultiSelectInput = (props) => {
  const {
    inputLabel,
    placeholder,
    setSelectedData,
    selectedData,
    options,
    optionsPlacement,
    type,
    image,
    customMultiSelectContsinerStyle,
    customMultiSelectInputStyle,
    disabledInput,
    eachOptionViewStyle,
    multiInputStyle,
    optionsModalViewStyle,
    multi = false,
  } = props;
  const { width } = useWindowDimensions();
  const [searchText, setSearchText] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [anchorElement, setAnchorElement] = useState();
  const [filteredData, setFilteredOptions] = useState(options || []);
  const [selectedOptionsData, setSelectedOptionsData] = useState([]);
  useEffect(() => {
    if (options) {
      let notSelectedOptions = [...options].filter(
        (opt) => !selectedOptionsData.map((sa) => sa.value).includes(opt.value)
      );
      setFilteredOptions(
        notSelectedOptions.filter((option) =>
          JSON.stringify(option).toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [searchText, selectedOptionsData]);
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (options) {
      let data = [];
      options.map((item) => {
        if (selectedData?.includes(item.value)) {
          data.push(item);
        }
      });
      setSelectedOptionsData(data);
    }
  }, [selectedData]);

  const selectOption = (option) => {
    setSearchText('');
    if (multi) {
      const isSelected = selectedData.includes(option.value);
      if (isSelected) {
        setSelectedData(selectedData.filter((item) => item !== option.value));
      } else {
        setSelectedData([...selectedData, option.value]);
      }
    } else {
      setShowOptions(false);
      setSelectedData([option.value]);
    }
  };

  const removeSelectedOptions = (option) => {
    const data = [...selectedData];
    let filteredData = data.filter((item) => item !== option.value);
    setSelectedData(filteredData);
  };

  const isPresent = (data) => {
    return selectedData?.includes(data.value);
  };

  const renderOptionsPopUp = () => {
    return (
      <PopOver
        show={showOptions}
        showOverlay={false}
        reference={anchorElement}
        placement={width > 480 ? 'bottom-start' : optionsPlacement ? optionsPlacement : 'top-start'}
        onClose={() => setShowOptions(false)}
        containerStyle={classNames(styles.optionsModalViewStyle, optionsModalViewStyle)}
      >
        <div className={styles.optionsViewStyle}>
          {filteredData?.length > 0 ? (
            filteredData?.map((item, index) => (
              <div
                key={'#' + index}
                onClick={() => selectOption(item)}
                className={classNames(
                  styles.eachOptionViewStyle,
                  isPresent(item) && styles.selectedOptionViewStyle,
                  eachOptionViewStyle
                )}
              >
                <Avatar
                  src={image}
                  label={item.label}
                  containerStyle={styles.optionImageViewStyle}
                />
                <div className={styles.optionDetailsViewStyle}>
                  <p className={styles.optionTitleTextStyle}>
                    {`${item.label} ${item?.desig ? `(${item.desig})` : ''}`}
                  </p>
                  <p className={styles.optionDescTextStyle}>{item.desc}</p>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.emptyDataViewStyle}>
              <p className={styles.optionDescTextStyle}>No data available</p>
            </div>
          )}
        </div>
      </PopOver>
    );
  };

  return (
    <div className={classNames(styles.multiSelectContsinerStyle, customMultiSelectContsinerStyle)}>
      {inputLabel && <p className={styles.lableStyle}>{inputLabel}</p>}
      <div
        className={classNames(
          styles.multiSelectInputStyle,
          selectedOptionsData.length > 0 && styles.multiSelectInputStyle2,
          customMultiSelectInputStyle
        )}
        ref={setAnchorElement}
      >
        {selectedOptionsData.length > 0 && (
          <div className={styles.selectedOptionsViewStyle}>
            {selectedOptionsData.map((data, index) => (
              <SelectedCard
                key={index}
                title={data.label}
                icon={closeLightGrayIcon}
                removeItem={() => removeSelectedOptions(data)}
                containerStyle={
                  type === 'asset' ? styles.selectedCardViewStyle : styles.selectedCardViewStyle2
                }
                iconStyle={styles.iconStyle}
              />
            ))}
          </div>
        )}

        <Input
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          inputStyle={classNames(styles.inputCustomStyle, multiInputStyle)}
          onFocus={(e) => {
            setShowOptions(true);
          }}
          disabled={disabledInput}
        />
        {renderOptionsPopUp()}
      </div>
    </div>
  );
};

MultiSelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  setSelectedData: PropTypes.func,
  selectedData: PropTypes.array,
  optionsPlacement: PropTypes.string,
  type: PropTypes.oneOf(['assets', 'users']),
  image: PropTypes.string,
  customMultiSelectContsinerStyle: PropTypes.string,
  customMultiSelectInputStyle: PropTypes.string,
  multiInputStyle: PropTypes.string,
  disabledInput: PropTypes.bool,
  eachOptionViewStyle: PropTypes.string,
  optionsModalViewStyle: PropTypes.oneOf(PopperJS.placements),
  multi: PropTypes.bool,
};

export default MultiSelectInput;
