import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles.module.css';
import classNames from 'classnames';
import { useStrings } from 'providers/stringsprovider';
import {
  avatar1Icon,
  avatar2Icon,
  avatar3Icon,
  avatar4Icon,
  infoCircleIcon,
} from 'resources/images';
import AvatarGroup from 'components/common/avatar-group';
import Image from 'components/common/image';
import { useLocation, useNavigate } from 'react-router';
import routePaths from 'routes/paths';

const UserTickets = (props) => {
  const { tickets } = props;
  const { strings } = useStrings();
  const navigate = useNavigate();
  const location = useLocation();

  const images = [avatar1Icon, avatar2Icon, avatar3Icon, avatar4Icon];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const getBgClolor = (status) => {
    if (status === 'Unresolved' || status === 'Found, Unrecovered') {
      return styles.unresolvedBgViewStyles;
    } else if (status === 'Assigned') {
      return styles.assigenedBgViewStyles;
    } else if (status === 'In progress' || status === 'Recovered') {
      return styles.inProgressBgViewStyles;
    } else if (status === 'In review') {
      return styles.inReviewBgViewStyles;
    } else if (status === 'Marked lost' || status === 'Complaint raised') {
      return styles.inMarkAsLostBgViewStyles;
    } else if (status === 'In review*') {
      return styles.cannotSolveBgViewStyles;
    } else if (status === 'Closed') {
      return styles.inClosedBgViewStyles;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const getTextClolor = (status) => {
    if (status === 'Unresolved' || status === 'Found, Unrecovered') {
      return styles.unresolvedTextStyles;
    } else if (status === 'Assigned') {
      return styles.assigenedTextStyles;
    } else if (status === 'In progress' || status === 'Recovered') {
      return styles.inProgressTextStyles;
    } else if (status === 'In review') {
      return styles.inReviewTextStyles;
    } else if (status === 'Marked lost' || status === 'Complaint raised') {
      return styles.inMarkAsLostTextStyles;
    } else if (status === 'In review*') {
      return styles.cannotSolveTextStyles;
    } else if (status === 'Closed') {
      return styles.inClosedTextStyles;
    }
  };

  const renderTickets = () => {
    return (
      <div className={styles.tableSectionStyle}>
        <table className={styles.ticketsTableWrapperStyle}>
          <thead>
            <tr className={styles.tableHeaderRowStyle}>
              <th>{'Ticket details'}</th>
              <th>{'Current status'}</th>
              <th>Raised on</th>
              <th>Assigned to</th>
              <th>Closed on</th>
            </tr>
          </thead>
          <tbody>
            {tickets.length > 0 ? (
              tickets.map((item, index) => (
                <tr
                  key={index}
                  className={[styles.tableRowStyle].join(' ')}
                  onClick={() => navigate(routePaths.ticketList + `?selected=${item._id}`)}
                >
                  <td>
                    <div className={styles.titleCustomColStyle}>
                      <span className={styles.ticketTitleTextStyle}>{item.subject}</span>
                      <span className={styles.tableSmallTextStyle}>#{item.ticket_uid}</span>
                    </div>
                  </td>
                  <td>
                    <div className={styles.ticketStatusWrapperStyle}>
                      <div
                        className={classNames(styles.dotViewStyle, getBgClolor(item.ticket_status))}
                      ></div>
                      <span
                        className={classNames(
                          styles.ticketTitleTextStyle,
                          getTextClolor(item.ticket_status)
                        )}
                      >
                        {item.ticket_status === 'In review*'
                          ? "In review (Can't solve)"
                          : item.ticket_status}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className={styles.titleCustomColStyle}>
                      <span className={styles.ticketDescTextStyle}>
                        {moment(item.created_at).format('ll')} at
                      </span>
                      <span className={styles.ticketDescTextStyle}>
                        {moment(item.created_at).format('LT')}
                      </span>
                    </div>
                  </td>
                  <td>
                    <AvatarGroup
                      data={item?.ticket_users?.map((u) => ({
                        label: u.user.full_name,
                        src: getRandomImage(),
                      }))}
                      maxItems={3}
                      imgContainerStyle={styles.avatarImgStyle}
                    />
                  </td>
                  <td>
                    {item.ticket_status === 'Closed' ? (
                      <div className={styles.titleCustomColStyle}>
                        <span className={styles.ticketDescTextStyle}>
                          {moment(item.updated_at).format('ll')}
                        </span>
                        <span className={styles.ticketDescTextStyle}>
                          {moment(item.updated_at).format('LT')}
                        </span>
                      </div>
                    ) : (
                      <span className={styles.ticketDescTextStyle}>Not closed</span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles.tableRowStyle}>
                <td className={styles.emptyDataStyle}>{strings.noData}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderEmptyDataSection = () => {
    return (
      <div className={styles.emptyDataViewStyle}>
        <div className={styles.emptyDataSubViewStyle}>
          <Image src={infoCircleIcon} />
          <p className={styles.emptyDataTextStyle}>No tickets is assigned yet</p>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {tickets.length > 0 ? renderTickets() : renderEmptyDataSection()}
    </React.Fragment>
  );
};

UserTickets.propTypes = {
  tickets: PropTypes.array,
};

export default UserTickets;
