import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  assetIcon,
  avatar2Icon,
  chevronDownFillBlackIcon,
  chevronDownFillGrayIcon,
  closeIcon,
  searchIcon,
} from 'resources/images';
import Image from 'components/common/image';
import Input from 'components/common/input';
import classNames from 'classnames';
import { useUserData } from 'providers/userdataprovider';
import PopOver from 'components/common/pop-over';
import Button from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import { forwardTicketApi } from 'networking/api/alltickets';
import Loader from 'components/common/loader';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const TicketForward = (props) => {
  const { ticket, setTicket, onClose } = props;
  const toast = useToast();
  const { users, setTickets } = useUserData();
  const { strings } = useStrings();
  const [adminsList, setAdminsList] = useState([]);
  const [adminsListOpne, setAdminsListOpne] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [comment, setComment] = useState('');
  const [filteredAdmins, setFilteredAdmins] = useState(adminsList || []);
  const [adminsRef, setAdminsRef] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [forwardErrorMsg, setForwardErrorMsg] = useState('');

  useEffect(() => {
    if (users) {
      let admins = users.filter(
        (user) =>
          user.account_type === 'Admin' && !ticket.ticket_users.some((u) => u.user._id === user._id)
      );
      setAdminsList(admins);
      setFilteredAdmins(admins);
    }
  }, [users, ticket]);

  useEffect(() => {
    if (query.length > 0) {
      let data = adminsList.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
      );
      setFilteredAdmins(data);
    }
  }, [adminsList, query]);

  const onSelectAdmin = (admin) => {
    let findIndx = selectedAdmins.findIndex((item) => item._id === admin._id);
    if (findIndx === -1) {
      setSelectedAdmins((prev) => [...prev, admin]);
    } else {
      let filterData = selectedAdmins.filter((item) => item._id !== admin._id);
      setSelectedAdmins(filterData);
    }
  };

  const handleRemoveAdmin = (admin) => {
    let filterData = selectedAdmins.filter((item) => item._id !== admin._id);
    setSelectedAdmins(filterData);
  };

  const selected = (admin) => {
    let findData = selectedAdmins.find((item) => item._id === admin._id);
    if (findData) {
      return true;
    } else {
      return false;
    }
  };

  const tapOnConfirm = async () => {
    if (selectedAdmins.length > 0) {
      const selectedAdminIds = selectedAdmins.map((item) => item._id);
      try {
        setIsLoading(true);
        const data = {
          users: selectedAdminIds,
          note: comment,
          ticket: ticket?._id,
        };
        const response = await forwardTicketApi(data);
        if (response.data.type === 'success') {
          setTicket(response.data.data);
          setTickets((prev) => {
            let previous_data = [...prev];
            let ticketIndex = previous_data.findIndex(
              (item) => item._id === response.data.data._id
            );
            if (ticketIndex !== -1) {
              previous_data[ticketIndex] = { ...response.data.data };
            }
            return previous_data;
          });
          onClose(false);
          setSelectedAdmins([]);
          setIsLoading(false);
          toast.success(response.data.message);
        } else {
          console.log('error', response.data.message);
          setForwardErrorMsg(response.data.message);
          setIsLoading(false);
        }
      } catch (error) {
        console.log('error', error);
        setIsLoading(false);
        setForwardErrorMsg(error.message);
      }
    } else {
      setForwardErrorMsg('Please Select at leaset one admin ');
    }
  };

  //   ui section -----

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <p className={styles.headerTextStyle}>{strings.forwardTo}</p>
        <Image
          src={closeIcon}
          alt="X"
          onClick={() => onClose(false)}
          containerStyle={styles.crossImgStyle}
        />
      </div>
    );
  };

  const renderInputSecction = () => {
    return (
      <div className={styles.inputSectionStyles}>
        <div className={styles.inputSubWrapperStyles}>
          <p className={styles.noteViewStyle}>{strings.ticketForwardNote}</p>
          <div
            className={classNames(styles.inputWrapperStyle)}
            ref={setAdminsRef}
            onClick={() => setAdminsListOpne(!adminsListOpne)}
          >
            {selectedAdmins.length > 0 ? (
              <div className={styles.selectedDataViewStyle}>
                {selectedAdmins.map((item, index) => (
                  <Button key={index} title={item.full_name} btnStyle={styles.btnViewStyle}>
                    <Image
                      src={closeIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleRemoveAdmin(item);
                      }}
                      containerStyle={styles.closeIconStyle}
                    />
                  </Button>
                ))}
              </div>
            ) : (
              <p className={styles.adminPlaceHolderStyle}>{strings.selectAdmins}</p>
            )}
            <Image
              src={chevronDownFillGrayIcon}
              containerStyle={classNames(
                styles.adminSelectDropDownIconStyle,
                adminsListOpne && styles.adminSelectDropDownIconStyle2
              )}
            />
          </div>
          <Input
            type="textarea"
            labelText={strings.addComment}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.addCommentPlaceholder}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            inputStyle={styles.textAreaStyle}
          />
        </div>
        {renderBtnsSection()}
      </div>
    );
  };

  const renderAdminsList = () => {
    return (
      <PopOver
        show={adminsListOpne}
        reference={adminsRef}
        onClose={() => setAdminsListOpne(false)}
        containerStyle={styles.listPopUpViewStyle}
        placement="bottom-end"
      >
        <div className={styles.adminsListWithInputStyle}>
          <div className={styles.searchViewStyle}>
            <Input
              leftIcon={searchIcon}
              rightIcon={closeIcon}
              onClickRightIcon={() => setAdminsListOpne(false)}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={strings.searchAdmins}
              inputStyle={styles.adminsListInputStyle}
            />
          </div>
          <div className={styles.adminsListWrapperStyle}>
            {filteredAdmins.length > 0 ? (
              filteredAdmins.map((admin, index) => (
                <div
                  key={'admin' + index}
                  className={classNames(
                    styles.adminsListStyle,
                    selected(admin) && styles.selectedAdminStyle
                  )}
                  onClick={() => {
                    onSelectAdmin(admin);
                    // setAdminsListOpne(false);
                  }}
                >
                  <Image
                    src={admin.image || avatar2Icon}
                    alt={admin.full_name}
                    containerStyle={styles.adminsListImgStyle}
                  />
                  <div>
                    <p className={styles.adminsListTitleStyle}>{admin.full_name}</p>
                    <p className={styles.adminsListDescStyle}>{admin.account_type}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className={styles.emptyDataStyle}>{strings.noData}</p>
            )}
          </div>
        </div>
      </PopOver>
    );
  };

  const renderBtnsSection = () => {
    return (
      <div className={styles.btnsSectionStyle}>
        <p className={styles.errorTextStyle}>{forwardErrorMsg}</p>
        <div className={styles.btnsWrappeerStyle}>
          <Button
            title={strings.cancel}
            onClick={() => {
              setSelectedAdmins([]);
              setComment('');
              onClose(false);
            }}
            variant="light"
            btnStyle={styles.btnStyle}
          />
          <Button
            title={strings.confirm}
            disabled={selectedAdmins.length === 0}
            onClick={() => tapOnConfirm()}
            btnStyle={styles.btnStyle}
          />
        </div>
      </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      {isLoading && <Loader />}
      {renderHeaderSection()}
      <div className={styles.inputsAndBtnSectionStyles}>{renderInputSecction()}</div>
      {renderAdminsList()}
    </div>
  );
};

TicketForward.propTypes = {
  onClose: PropTypes.func,
  ticket: PropTypes.object,
};

export default TicketForward;
