import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/common/input';
import BasicSelect from 'components/common/basic-select';
import NumberInput from 'components/common/phone-number-input';
import Image from 'components/common/image';
import Button from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import PopOver from 'components/common/pop-over';
import {
  checkBlueIcon,
  chevronDownFillGrayIcon,
  closeIcon,
  listAddBlueIcon,
} from 'resources/images';
import { employeeUpdateApi } from 'networking/api/employee';
import Loader from 'components/common/loader';
import { isMatch } from 'lodash';
import ImageUpload from 'components/image-upload';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const EditEmployee = (props) => {
  const { employee, onSuccess = () => {}, onCancel = () => {} } = props;

  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const { company } = useAuth();
  const toast = useToast();
  const { users, setUsers } = useUserData();
  const [typesRef, setTypesRef] = useState(null);
  const [isShowTypesOptions, setIsShowTypesOptions] = useState(false);
  const [customTypeRef, setCustomTypeRef] = useState(null);
  const [isShowCustomTypeInput, setIsShowCustomTypeInput] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [schemaObj, setSchemaObj] = useState({
    full_name: Yup.string().required(strings.fullNameRequired),
    // first_name: Yup.string().required(strings.firstNameRequired),
    // last_name: Yup.string().required(strings.lastNameRequired),
    employee_id: Yup.string().required(strings.employeeIdRequired),
    phone_number: Yup.object().shape({
      country_code: Yup.string().notRequired(),
      value: Yup.string().required(strings.phoneNumRequired),
    }),
    employee_type: Yup.string().required(strings.employeeTypeRequired),
    bio: Yup.string(),
  });

  const default_categories = [strings.acountant, strings.developer, strings.designer];

  const account_types = [
    { value: 'Admin', label: strings.adminAccount },
    { value: 'Employee', label: strings.employeeAccount },
  ];

  const [categories, setCategories] = useState(default_categories);
  const [categoryAdding, setCategoryAdding] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  const userSchema = Yup.object().shape(schemaObj);

  const formik = useFormik({
    initialValues: {
      // first_name: employee?.first_name || '',
      // last_name: employee?.last_name || '',
      full_name: employee?.full_name || '',
      employee_id: employee?.employee_id || '',
      phone_number: {
        country_code: employee?.phone_number?.country_code || '+46',
        value: employee?.phone_number?.value || '',
      },
      employee_type: employee?.employee_type || '',
      _id: employee?._id,
      bio: employee?.bio || '',
      image: employee?.image || null,
    },
    validationSchema: userSchema,
    onSubmit: handleUpdateUser,
  });

  useEffect(() => {
    let new_categories = getCategories();
    if (new_categories && new_categories?.length > 0 && Array.isArray(new_categories)) {
      setCategories([...new_categories]);
    } else {
      setCategories(default_categories);
      localStorage.setItem('EMPLOYEE_CAT', JSON.stringify(default_categories));
    }
  }, []);

  useEffect(() => {
    setIsValuesChanged(isMatch(employee, formik.values));
  }, [formik.values, employee, setIsValuesChanged]);

  async function handleUpdateUser(values, { resetForm }) {
    setApiError('');
    setIsLoading(true);
    try {
      const response = await employeeUpdateApi(values);
      if (response.data.type === 'success') {
        setIsLoading(false);
        onSuccess(response.data.data);
        setUsers((prev) => {
          let previous_data = [...prev];
          let findIndex = previous_data.findIndex((item) => item._id === response.data.data._id);
          if (findIndex !== -1) {
            previous_data[findIndex] = { ...previous_data[findIndex], ...response.data.data };
            return previous_data;
          } else {
            return previous_data;
          }
        });
        toast.success(response.data.message);
      } else {
        setApiError(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setApiError(error.message);
      console.log('API call error:', error);
    }
  }

  const getCategories = () => {
    let new_categories = localStorage.getItem('EMPLOYEE_CAT');
    setCategories(JSON.parse(new_categories));
    let new_parsed_categories = JSON.parse(new_categories);
    return new_parsed_categories || [];
  };

  const handleNewCategory = () => {
    let old_categories = getCategories();
    let data = old_categories?.length > 0 ? old_categories : [];
    let existing = data.find((item) => item === newCategory);
    if (!existing) {
      data.push(newCategory);
    }
    localStorage.setItem('EMPLOYEE_CAT', JSON.stringify(data));
    getCategories();
    formik.setFieldValue('employee_type', newCategory);
    setIsShowCustomTypeInput(false);
  };

  //   ui section

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerStyle}>
        <p className={styles.headerTextStyle}>Edit details</p>
        <div className={styles.headerBtnsViewStyle}>
          <Button title="Cancel" variant="light" size="md" onClick={onCancel} />
          <Button
            type={'submit'}
            title="Save changes"
            size="md"
            disabled={isValuesChanged || isLoading}
          />
        </div>
        <Image src={closeIcon} onClick={onCancel} containerStyle={styles.closeIconStyle} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.contentWrapperStyle}>
        <p className={styles.textStyle}>Employee info</p>
        <div className={styles.contentSubWrapperStyle}>
          {renderForm()}
          {renderMobileViewBtns()}
        </div>
      </div>
    );
  };

  const renderEmployeeTypes = () => {
    return (
      <PopOver
        show={isShowTypesOptions}
        reference={typesRef}
        onClose={() => {
          if (!isShowCustomTypeInput) {
            setIsShowTypesOptions(false);
          }
        }}
        containerStyle={styles.actionPopUpViewStyle}
        placement="bottom-end"
        offset={[0, -44]}
      >
        <div className={styles.actionOptionsViewStyle}>
          {categories?.map((item, index) => {
            return (
              <div className={styles.optiondTextWrapperStyle} key={index}>
                <p
                  onClick={() => {
                    formik.setFieldValue('employee_type', item);
                    setIsShowTypesOptions(false);
                  }}
                  className={classNames(styles.optionsViewStyles)}
                >
                  {item}
                </p>
                {formik.values.employee_type === item && (
                  <Image src={checkBlueIcon} containerStyle={styles.checkIconStyle} />
                )}
              </div>
            );
          })}
        </div>
        <div
          className={styles.customTypeContainerStyle}
          onClick={() => setIsShowCustomTypeInput(true)}
          ref={setCustomTypeRef}
        >
          <Image src={listAddBlueIcon} containerStyle={styles.listIconStyle} />
          <p className={styles.customOptionsViewStyles}>Add custom role</p>
        </div>
      </PopOver>
    );
  };

  const renderCustomEmployeeRole = () => {
    return (
      <PopOver
        show={isShowCustomTypeInput}
        reference={customTypeRef}
        onClose={() => setIsShowCustomTypeInput(false)}
        containerStyle={styles.inputPopUpViewStyle}
        showOverlay
        overlayStyle={styles.overlayStyle}
      >
        <div className={styles.customEmployeeViewStyle}>
          <Input
            name="employee_type"
            labelText={strings.employeeRole}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.employeeTypeRequired}
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.employee_type && formik.errors.employee_type
                ? formik.errors.employee_type
                : ''
            }
            containerStyle={styles.inputStyle}
          />
          <div className={styles.popUpBtnContainerStyle}>
            <Button
              title="Cancel"
              size="md"
              variant="light"
              onClick={() => {
                setIsShowCustomTypeInput(false);
                setNewCategory('');
              }}
            />
            <Button
              title="Save entry"
              size="md"
              disabled={newCategory !== '' ? false : true}
              onClick={() => handleNewCategory()}
            />
          </div>
        </div>
      </PopOver>
    );
  };

  const renderForm = () => {
    return (
      <div className={styles.inputWrapperStyle}>
        <div className={styles.inputSectionViewStyle}>
          <Input
            name="full_name"
            labelText={strings.fullNameLabel}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.userNameRequired}
            value={formik.values.full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.full_name && formik.errors.full_name ? formik.errors.full_name : ''
            }
            containerStyle={styles.inputStyle}
          />
          <Input
            name="email"
            labelText={strings.emailIdRequired}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.emailIdType}
            value={employee.email}
            inputStyle={styles.disableinputStyle}
            disabled={true}
          />
          {/* <Input
            name="last_name"
            labelText={strings.lastName}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.userNameRequired}
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.last_name && formik.errors.last_name ? formik.errors.last_name : ''
            }
            containerStyle={styles.inputStyle}
          /> */}
        </div>
        <div className={styles.inputSectionViewStyle}>
          <Input
            name="employee_id"
            labelText={'Employee unique ID*'}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.uidExamp}
            value={formik.values.employee_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.employee_id && formik.errors.employee_id
                ? formik.errors.employee_id
                : ''
            }
            containerStyle={styles.inputStyle}
          />
          <BasicSelect
            label={strings.accountTypeLabel}
            options={account_types}
            selectedOption={account_types.find((item) => {
              if (item.value === employee.account_type) {
                return item;
              }
            })}
            disabled={true}
          />
        </div>
        <div className={styles.inputSectionViewStyle}>
          <NumberInput
            name="phone_number.value"
            lableText={strings.phoneNumCompulsory}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.phoneNumHere}
            value={formik.values.phone_number?.value}
            onChange={(e) => formik.setFieldValue('phone_number.value', e.target.value.toString())}
            countryCode={formik.values.phone_number?.country_code}
            onChangeCountryCode={(value) =>
              formik.setFieldValue('phone_number.country_code', value)
            }
            onBlur={formik.handleBlur}
            // onFocus={() => formik.setFieldError('phone_number.value', '')}
            hasError={
              formik.touched.phone_number?.value && formik.errors.phone_number?.value
                ? formik.errors.phone_number?.value
                : ''
            }
          />
          <div className={styles.selectTypeInputContainerStyle}>
            <p className={styles.lableTextStyle}>{strings.employeeRole}</p>
            <div
              className={styles.selectTypeContainerStyle}
              onClick={() => {
                setIsShowTypesOptions(true);
              }}
              ref={setTypesRef}
            >
              <p
                className={
                  formik.values.employee_type ? styles.valueTextStyle : styles.placeHolderTextStyle
                }
              >
                {formik.values.employee_type ? formik.values.employee_type : strings.selectEmpROle}
              </p>
              <Image src={chevronDownFillGrayIcon} containerStyle={styles.arrowDownIconStyle} />
            </div>
            <p>
              {formik.touched.employee_type && formik.errors.employee_type
                ? formik.errors.employee_type
                : ''}
            </p>
          </div>
        </div>
        <ImageUpload
          label="Employee image (optional)"
          placeholder="JPG, JPEG, or PNG (Less than 5MB)"
          image={formik.values.image}
          onSuccess={(img) => formik.setFieldValue('image', img)}
        />
        <Input
          type="textarea"
          name="bio"
          labelText={strings.bio}
          inputLabelStyle={styles.lableTextStyle}
          placeholder={strings.employeeBio}
          value={formik.values.bio}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inputStyle={styles.inputStyle}
        />
        {apiError && <p className={styles.errorTextStyle}>{apiError}</p>}
      </div>
    );
  };

  const renderMobileViewBtns = () => {
    return (
      <div className={styles.mobileViewBtnsViewStyle}>
        <Button title="Cancel" variant="light" size="md" onClick={onCancel} />
        <Button type={'submit'} title="Save changes" size="md" />
      </div>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.containerStyle}>
      {isLoading && <Loader />}
      {renderHeaderSection()}
      {renderContent()}
      {renderCustomEmployeeRole()}
      {renderEmployeeTypes()}
    </form>
  );
};

EditEmployee.propTypes = {
  employee: PropTypes.object,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditEmployee;
