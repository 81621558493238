import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAuth } from 'providers/authprovider';
import Input from 'components/common/input';
import Button from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import { filterIcon, multiSelectIcon, searchIcon } from 'resources/images';
import Avatar from 'components/common/avatar';
import styles from './styles.module.css';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AssetAndUserCard from 'components/asset&user-card';
import { useAppData } from 'providers/appdataprovider';

const CompanyList = () => {
  const { user } = useAuth();
  const { width } = useWindowDimensions();
  const { setBreadcrumbs } = useAppData();
  const { strings } = useStrings();
  const [query, setQuery] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState(user?.companies);

  useEffect(() => {
    if (query.length > 0) {
      setFilteredCompanies(
        user?.companies.filter((comp) =>
          JSON.stringify(comp).toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setFilteredCompanies(user?.companies);
    }
  }, [user, query]);

  useEffect(() => {
    setBreadcrumbs([{ title: 'All companies', route: '' }]);
  }, []);

  const renderFilterSection = () => {
    return (
      <div className={styles.filterWrapperStyle}>
        <Input
          placeholder={strings.searchCompanies}
          inputStyle={styles.filterInputStyle}
          leftIcon={searchIcon}
          leftIconStyle={styles.filterIconStyle}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {/* <Button
          variant="gray"
          title={strings.filter}
          leftIcon={filterIcon}
          btnStyle={styles.filterBtnStyle}
          leftIconStyle={styles.filterIconStyle}
        />
        <Button
          variant="gray"
          title={strings.select}
          leftIcon={multiSelectIcon}
          btnStyle={styles.filterBtnStyle}
          leftIconStyle={styles.filterIconStyle}
        /> */}
      </div>
    );
  };

  const renderListSection = () => {
    return (
      <table className={styles.tableWrapperStyle}>
        <thead>
          <tr className={styles.tableHeaderRowStyle}>
            <th>{strings.comapny}</th>
            <th>{strings.email}</th>
            <th>{strings.dateAndTime}</th>
          </tr>
        </thead>
        <tbody>
          {filteredCompanies.length > 0 ? (
            filteredCompanies.map((item, index) => (
              <tr key={index} className={styles.tableRowStyle}>
                <td>
                  <div className={styles.avatarWrapperStyle}>
                    <Avatar
                      src={item.image}
                      label={item.company_name}
                      containerStyle={styles.avatarStyle}
                    />
                    <p>{item.company_name}</p>
                  </div>
                </td>
                <td>
                  <span>{item.email}</span>
                </td>
                <td>
                  <div className={styles.customColStyle}>
                    <span>{moment(item.created_at).format('ll')}</span>
                    <span className={styles.tableSmallTextStyle}>
                      {moment(item.created_at).format('LT')}
                    </span>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr className={styles.tableRowStyle}>
              <td className={styles.emptyDataStyle}>{strings.noData}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderListMobileView = () => {
    return (
      <div className={styles.mobileViewStyle}>
        {filteredCompanies.length > 0 ? (
          filteredCompanies.map((item, index) => (
            <React.Fragment key={index}>
              <AssetAndUserCard
                type="user"
                title={item.company_name}
                description={item.email}
                image={item?.image}
                leftLable="Created on"
                leftTitle={moment(item.created_at).format('ll')}
                leftDescription={moment(item.created_at).format('LT')}
                containerStyle={styles.cardStyle}
                deleteImg
                bottomSectionStyle={styles.cardBottomSectionStyle}
              />
            </React.Fragment>
          ))
        ) : (
          <div className={styles.emptyViewStyle}>
            <p className={styles.emptyDataStyle}>No data available</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.listContainerStyle}>
      {renderFilterSection()}
      {width > 480 ? renderListSection() : renderListMobileView()}
    </div>
  );
};

export default CompanyList;
