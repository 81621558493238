import React, { useEffect, useState } from 'react';
import Image from 'components/common/image';
import PropTypes from 'prop-types';
import {
  checkCircleFillPurpleIcon,
  chevronDownFillGrayIcon,
  closeIcon,
  deleteIcon,
  infoCircleIcon,
  optionsVerticalGrayIcon,
  uncheckPurpleIcon,
} from 'resources/images';
import styles from './styles.module.css';
import Capsule from 'components/common/capsule';
import Reminders from '../reminders';
import moment from 'moment';
import { account_types } from 'resources/data';
import { useAuth } from 'providers/authprovider';
import classNames from 'classnames';
import Modal from 'components/common/modal';
import ReminderCard from 'components/reminder-card';
import Attachments from '../attachments';
import Button from 'components/common/button';
import ReminderDetails from 'components/reminder-details';

const AllRemainders = (props) => {
  const { type, onClose = () => {}, remainders } = props;
  const { userType } = useAuth();
  const [filteredRemainders, setFilteredRemainders] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('Upcoming');
  const [selectedReminder, setSelectedReminder] = useState(null);
  const tabs = ['Upcoming', 'Completed'];

  useEffect(() => {
    if (activeTab === 'Completed') {
      setFilteredRemainders(remainders?.filter((rem) => rem.status?.length > 0));
    } else {
      setFilteredRemainders(remainders?.filter((rem) => rem.status?.length === 0));
    }
  }, [remainders, activeTab]);

  const renderDate = (timeToken, date) => {
    const timestampDate = moment(timeToken).format('L');
    dates.add(date);
    return <p className={styles.dateTextStyle}>{renderDateFormate(timestampDate)}</p>;
  };

  const renderDateFormate = (timestampDate) => {
    var today = moment();
    var yesterday = moment().subtract(1, 'day');
    if (moment(timestampDate).isSame(today, 'd')) {
      return 'Today';
    } else if (moment(timestampDate).isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      return moment(timestampDate).format('Do MMMM YYYY');
    }
  };

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <p className={styles.headerTextStyle}>All reminders</p>
        <Image src={closeIcon} alt="X" onClick={onClose} containerStyle={styles.closeIconStyle} />
      </div>
    );
  };

  const renderRemindersSection = () => {
    return (
      <div className={styles.remindersWrapperStyle}>
        <div className={styles.tabsViewStyle}>
          {tabs.map((tab, index) => (
            <Capsule
              key={index}
              label={tab}
              onClick={() => setActiveTab(tab)}
              containerStyle={activeTab === tab ? styles.activeTabStyle : ''}
            />
          ))}
        </div>
        {activeTab === 'Upcoming' && (
          // <Reminders reminders={filteredRemainders} containerStyle={styles.customReminderStyle} />
          <div className={styles.upcomingRemaindersViewStyle}>
            {filteredRemainders.length > 0 ? (
              <React.Fragment>
                {filteredRemainders.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ReminderCard
                        data={item}
                        rightIcon={deleteIcon}
                        onClick={() => {
                          setIsShowModal(true);
                          setSelectedReminder(item);
                        }}
                        customRightIconStyle={
                          userType === account_types.employee ? styles.disableviewStyle : ''
                        }
                      />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ) : (
              renderEmptyRendersSection('upcoming')
            )}
          </div>
        )}
        {activeTab === 'Completed' && renderCompletedRemainders()}
      </div>
    );
  };

  const renderCompletedRemainders = () => {
    return (
      <React.Fragment>
        {filteredRemainders.length > 0
          ? renderRemainders()
          : renderEmptyRendersSection('completed')}
      </React.Fragment>
    );
  };

  const renderEmptyRendersSection = (data) => {
    return (
      <div className={styles.noRemaindersWrapperStyle}>
        <Image containerStyle={styles.iconContainerStyle} src={infoCircleIcon} alt="icon" />
        <p className={styles.noRemindersTextStyle}>
          You have no {data} reminders set for this {type}. Click on “Add reminder” to get started.
        </p>
      </div>
    );
  };

  const dates = new Set();
  const renderRemainders = () => {
    const displayReminders = [];
    filteredRemainders.map((item) => {
      const date = moment(item.start_date).format('L');
      if (!dates.has(date)) {
        displayReminders.push(
          filteredRemainders.filter((item) => moment(date).isSame(item.start_date, 'date'))
        );
        dates.add(date);
      }
    });
    return (
      <div className={styles.completedRemindersListStyle}>
        {displayReminders.map((items, itemsIndex) => {
          const date = moment(items[0].start_date).format('L');
          return (
            <div className={styles.completedReminderItemsDateWrapperStyle} key={itemsIndex}>
              <p className={styles.dateTextStyle}>{renderDateFormate(date)}</p>
              <div className={styles.completedReminderItemsWrapperStyle}>
                {items.map((item, index) => {
                  return (
                    <ReminderCard
                      data={item}
                      rightIcon={deleteIcon}
                      onClick={() => {
                        setIsShowModal(true);
                        setSelectedReminder(item);
                      }}
                      customRightIconStyle={
                        userType === account_types.employee ? styles.disableviewStyle : ''
                      }
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderRemainderOverViewModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowModal}
        onClose={() => setIsShowModal(false)}
        closeOnOutSideClick
        containerStyle={styles.remainderOverModalStyle}
        overlayStyle={styles.remainderOverModalOverlayStyle}
      >
        <ReminderDetails
          reminder={selectedReminder}
          onCloseAction={() => {
            setIsShowModal(false);
            setSelectedReminder({});
          }}
        />
        {/* <div className={styles.remainderOverViewStyle}>
          <div className={styles.overViewHeaderStyle}>
            <p className={styles.overViewHeaderTTextStyle}>Reminder</p>
          </div>
          <div className={styles.remainderContentViewStyle}>
            <ReminderCard
              data={selectedReminder}
              rightIcon={optionsVerticalGrayIcon}
              customContainerStyle={styles.customContainerStyle}
            />
            <div className={styles.reminderDetailsViewStyle}>
              <div className={styles.curvedViewStyle}></div>
              <div className={styles.reminderDetailsSubViewStyle}>
                <div className={styles.reminderDetailsHeaderStyle}>
                  <p className={styles.reminderDetailsHeaderTextStyle}>Notes</p>
                </div>
                <p className={styles.reminderDetailsDescTextStyle}>
                  {selectedReminder?.notes || '---'}
                </p>
              </div>
            </div>
            <div className={styles.reminderDetailsViewStyle}>
              <div className={styles.curvedViewStyle}></div>
              <div className={styles.reminderDetailsSubViewStyle}>
                <div className={styles.reminderDetailsHeaderStyle}>
                  <p className={styles.reminderDetailsHeaderTextStyle}>Frequency</p>
                </div>
                <p className={styles.reminderDetailsDescTextStyle}>
                  <p className={styles.remainderTimeTextStyle}>
                    {selectedReminder?.type === 'repeat'
                      ? `Repeats every ${selectedReminder?.repeat_interval?.value} ${
                          selectedReminder?.repeat_interval?.type
                        } till ${moment(selectedReminder?.end_date).format('DD/MM/YYYY')}`
                      : `Reminds on ${moment(selectedReminder?.start_date).format(
                          'DD/MM/YYYY'
                        )} at ${moment(selectedReminder?.start_date).format('LT')} `}
                  </p>
                </p>
              </div>
            </div>
            <div className={styles.attachmentsViewStyle}>
              <p className={styles.attachmentsTextStyle}>Attachments</p>
              <Attachments
                data={selectedReminder?.attachments || []}
                customContainerStyle={styles.customattachContainerStyle}
              />
            </div>
            <div className={styles.dividerStyle}></div>
            <Button
              title="Dismiss"
              variant="light"
              leftIcon={closeIcon}
              btnStyle={styles.dismissBtnStyle}
              size="md"
              onClick={() => {
                setIsShowModal(false);
                setSelectedReminder(null);
              }}
            />
          </div>
        </div> */}
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {renderHeaderSection()}
      {renderRemindersSection()}
      {renderRemainderOverViewModal()}
    </div>
  );
};

AllRemainders.propTypes = {
  onClose: PropTypes.func,
  remainders: PropTypes.array,
  type: PropTypes.oneOf(['asset', 'user']),
};

export default AllRemainders;
