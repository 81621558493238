import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { avatar2Icon, sendBlueFillIcon, sendLightGrayIcon } from 'resources/images';
import Input from 'components/common/input';
import Image from 'components/common/image';
import styles from './styles.module.css';

const ChatInput = (props) => {
  const {
    id,
    value,
    setValue,
    disabled,
    sendAction,
    containerViewStyle,
    onInputFocus = () => {},
  } = props;

  return (
    <div className={classNames(styles.containerViewStyle, containerViewStyle)} id={id}>
      <div className={styles.userIconViewStyle}>
        <Image src={avatar2Icon} alt="avatar" containerStyle={styles.imgStyle} />
      </div>
      <div className={styles.inputBoxViewStyle}>
        <Input
          type="textarea"
          placeholder="Type and start a conversation ..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
          numberOfRows={2}
          inputStyle={styles.inputViewStyle}
          disabled={disabled || false}
          onFocus={onInputFocus}
        />
        {/* <Image src={linkIcon} alt="link" containerStyle={styles.iconViewStyle} /> */}
        {/* <Image src={atSignIcon} alt="@" containerStyle={styles.iconViewStyle} /> */}
        <Image
          src={value.length > 0 ? sendBlueFillIcon : sendLightGrayIcon}
          alt="send"
          containerStyle={styles.iconViewStyle}
          onClick={!disabled ? sendAction : () => {}}
        />
      </div>
    </div>
  );
};

ChatInput.propTypes = {
  id: PropTypes.string,
  sendAction: PropTypes.func,
  containerViewStyle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onInputFocus: PropTypes.func,
};

export default ChatInput;
