import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Button from 'components/common/button';
import styles from './styles.module.css';

const RegisterSuccessCard = (props) => {
  const {
    onClickAddAnother = () => {},
    headerText,
    onClickOverview = () => {},
    data,
    type,
    containerStyle,
    insideContainerStyle,
    headerTextStyle,
    dataWrapperStyle,
    customBarStyle,
    customListWrapperStyle,
    customTextLabelStyle,
    customValueStyle,
    customBtnWrapperStyle,
    customLeftBtnStyle,
    customRightBtnStyle,
  } = props;
  return (
    <div className={classNames(styles.alertModalContainerStyle, containerStyle)}>
      <div className={classNames(styles.modalDataWrapperStyle, insideContainerStyle)}>
        <h4 className={classNames(styles.alertHeaderTextStyle, headerTextStyle)}>{headerText}</h4>
        <div className={classNames(styles.detailsWrapperStyle, dataWrapperStyle)}>
          <div className={classNames(styles.barStyle, customBarStyle)}></div>
          <div className={classNames(styles.listWrapperStyle, customListWrapperStyle)}>
            <p className={classNames(styles.textLabelStyle, customTextLabelStyle)}>
              {type === 'asset' ? 'Serial no:' : 'Employee name:'}&nbsp;
              <span className={classNames(styles.labelDetailsTextStyle, customValueStyle)}>
                {type === 'asset' ? data?.asset_uid : `${data.full_name}`}
              </span>
            </p>
            <p className={classNames(styles.textLabelStyle, customTextLabelStyle)}>
              {type === 'asset' ? 'Asset name:' : 'Employee unique ID:'}&nbsp;
              <span className={classNames(styles.labelDetailsTextStyle, customValueStyle)}>
                {type === 'asset' ? data?.asset_name : data?.employee_id}
              </span>
            </p>
            <p className={classNames(styles.textLabelStyle, customTextLabelStyle)}>
              {type === 'asset' ? 'Purchase date:' : 'Account type:'}&nbsp;
              <span className={classNames(styles.labelDetailsTextStyle, customValueStyle)}>
                {type === 'asset'
                  ? moment(data?.purchased_on).format('DD MMMM YYYY')
                  : data?.account_type}
              </span>
            </p>
            <p className={classNames(styles.textLabelStyle, customTextLabelStyle)}>
              {type === 'asset' ? 'Asset category:' : 'Phone number:'}&nbsp;
              <span className={classNames(styles.labelDetailsTextStyle, customValueStyle)}>
                {type === 'asset' ? data?.asset_category : data?.phone_number?.value}
              </span>
            </p>
            <p className={classNames(styles.textLabelStyle, customTextLabelStyle)}>
              {type === 'asset' ? 'Assigned to:' : 'Role:'}&nbsp;
              <span className={classNames(styles.labelDetailsTextStyle, customValueStyle)}>
                {type === 'asset' ? data?.assigned_to?.full_name : data?.employee_type}
              </span>
            </p>
            {/* {type === 'asset' && (
              <p className={classNames(styles.textLabelStyle, customTextLabelStyle)}>
                {type === 'asset' && 'Reminders:'}&nbsp;
                <span className={classNames(styles.labelDetailsTextStyle, customValueStyle)}>
                  {type === 'asset' && data?.reminde}
                </span>
              </p>
            )} */}
            {type === 'asset' && (
              <p className={classNames(styles.textLabelStyle, customTextLabelStyle)}>
                {type === 'asset' && 'Attachments:'}&nbsp;
                <span className={classNames(styles.labelDetailsTextStyle, customValueStyle)}>
                  {type === 'asset' && data?.attachments?.length}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={classNames(styles.alertBtnContainerStyle, customBtnWrapperStyle)}>
        <Button
          title="Add another"
          onClick={onClickAddAnother}
          btnStyle={classNames(customLeftBtnStyle)}
        />
        <Button
          title="Show full overview"
          variant="light"
          onClick={onClickOverview}
          btnStyle={classNames(customRightBtnStyle)}
        />
      </div>
    </div>
  );
};
RegisterSuccessCard.propTypes = {
  onClickAddAnother: PropTypes.func,
  headerText: PropTypes.string,
  onClickOverview: PropTypes.func,
  data: PropTypes.object,
  type: PropTypes.string,
};
export default RegisterSuccessCard;
