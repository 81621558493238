import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const employeeCreateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createEmployee, data));

export const employeeDetailsWithCompaniesApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getEmployeeDetailsWithCompanies.replace('{id}', id)));

export const employeeDetailsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getEmployeeDetails.replace('{id}', id)));

export const getAllEmployeesWithLastAssetApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAllEmployeesWithLastAsset + `?company=${id}`));

export const employeeUpdateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateEmployee, data));

export const employeeDeleteApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteEmployee.replace('{id}', id)));

export const getemployeeDetailsWithAssetsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getemployeeDetailsWithAssets.replace('{id}', id)));
export const uploadUserXlsFileApi = async (data) =>
  Promise.resolve(axios.post(endPoints.uploadUserXlsFile, data));

// get all admins and employees api
export const getAllAdminsAndEmployeesApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAdminsAndEmployees + `?company=${id}`));

export const getUserReminderListApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getUserReminderList.replace('{id}', id)));

export const handleUpdateUserReminderApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateUserReminder, data));

export const addUserReminderApi = async (data) =>
  Promise.resolve(axios.post(endPoints.addUserReminder, data));

export const deleteUserReminderApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteUserReminder.replace('{id}', id)));

export const getUserCompletedRemindersApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getUserCompletedReminders + `?company=${id}`));
