import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { closeCircleFillGrayIcon, xlsIcon } from 'resources/images';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Image from 'components/common/image';
import Button from 'components/common/button';
import { uploadAssetXlsFileApi } from 'networking/api/asset';
import { uploadUserXlsFileApi } from 'networking/api/employee';
import { downloadUsersXLSFile, downloadassetsXLSFile } from 'networking/endpoints';
import styles from './styles.module.css';

const UploadXLSFile = (props) => {
  const { onSuccess, type } = props;
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const { company } = useAuth();
  const { setAssets, setUsers } = useUserData();
  const fileInputRef = useRef();
  const [xlsFile, setXlsFile] = useState(null);
  const [xlsFileError, setXlsFileError] = useState(null);
  const [loader, setLoader] = useState(false);

  const getSampleFileLink = () => {
    let link = type === 'asset' ? downloadassetsXLSFile : downloadUsersXLSFile;
    return `${process.env.REACT_APP_SERVER_API_URL}${link}`;
  };

  const handleClearFile = () => {
    setXlsFile(null);
    setXlsFileError(null);
  };

  const handleSelectFile = () => {
    setXlsFileError('');
    fileInputRef.current.value = '';
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        setXlsFileError(null);
        const file = event.target.files[0];
        setXlsFile(file);
      }
    } catch (error) {
      setXlsFileError(error.message);
    }
  };

  const uploadXlsFileData = async () => {
    try {
      // api call if file exists
      if (xlsFile) {
        setLoader(true);
        let formData = new FormData();
        formData.append('file', xlsFile);
        formData.append('company', company._id);
        let response;
        if (type === 'asset') {
          response = await uploadAssetXlsFileApi(formData);
        } else {
          response = await uploadUserXlsFileApi(formData);
        }
        if (response && response.data.type === 'success') {
          onSuccess();
          if (type === 'asset' && response.data?.data) {
            setAssets((prev) => [...response.data.data, ...prev]);
          }
          if (type === 'user' && response.data?.data) {
            setUsers((prev) => [...response.data.data, ...prev]);
          }
          handleClearFile();
          setLoader(false);
        } else {
          setLoader(false);
          setXlsFileError(response.data.message);
        }
      } else {
        setXlsFileError('Please select file');
      }
    } catch (error) {
      setLoader(false);
      setXlsFileError(error.message);
    }
  };

  return (
    <div className={classNames(styles.containerStyle)}>
      <div className={styles.uploadWrapperStyle}>
        <Image src={xlsIcon} containerStyle={styles.fileImgTypeStyle} />
        <p className={styles.descStyle}>
          {strings.xlsFileDescp} {type === 'asset' ? 'assets' : 'employee list'}?
          <Link to={getSampleFileLink()} className={styles.linkStyle} target="_blank">
            {' ' + strings.downLoadXls}
          </Link>
        </p>
        <input
          type="file"
          accept=".xlsx,.xls"
          hidden
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {xlsFile ? (
          <div className={styles.selectedFileWrapperStyle}>
            <span className={styles.selectedFileTitleStyle}>{xlsFile.name}</span>
            <Image
              src={closeCircleFillGrayIcon}
              onClick={(event) => {
                event.stopPropagation();
                handleClearFile();
              }}
              containerStyle={styles.closeIconStyle}
            />
          </div>
        ) : (
          <Button
            onClick={handleSelectFile}
            title={strings.uploadXLsFile}
            btnStyle={styles.customBtnStyle}
            size={width > 480 ? 'lg' : 'md'}
          />
        )}
      </div>
      <div className={styles.bottomWrapperStyle}>
        <p className={styles.errorTextStyle}>{xlsFileError}</p>
        <div className={styles.btnWrapperSyle}>
          <Button
            title={strings.cancel}
            onClick={() => {
              setXlsFile(null);
              setXlsFileError('');
            }}
            size={width > 480 ? 'lg' : 'md'}
            variant="light"
          />
          <Button
            title={type === 'asset' ? 'Add asset(s)' : 'Add user(s)'}
            size={width > 480 ? 'lg' : 'md'}
            onClick={() => uploadXlsFileData()}
            isLoading={loader}
          />
        </div>
      </div>
    </div>
  );
};

UploadXLSFile.propTypes = {
  onSuccess: PropTypes.func,
  type: PropTypes.oneOf(['asset', 'user']),
};

export default UploadXLSFile;
