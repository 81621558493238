import SuggestionCard from 'components/suggestion-card';
import { useStrings } from 'providers/stringsprovider';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/common/input';
import { useAuth } from 'providers/authprovider';
import Button from 'components/common/button';
import { createCompanyApi } from 'networking/api/company';
import Modal from 'components/common/modal';
import SuccessCard from 'components/success-card';
import { useNavigate } from 'react-router';
import styles from './styles.module.css';
import routePaths from 'routes/paths';
import useWindowDimensions from 'hooks/useWindowDimensions';
import NumberInput from 'components/common/phone-number-input';
import { useAppData } from 'providers/appdataprovider';

const CompanyRegistration = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const { strings } = useStrings();
  const { setBreadcrumbs } = useAppData();
  const { width } = useWindowDimensions();
  const [schemaObj, setSchemaObj] = useState({
    company_name: Yup.string().required(strings.companyNameRequired),
    email: Yup.string()
      .email(strings.validEmail)
      .matches(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, strings.validEmail)
      .required(strings.emailRequired),
    phone_number: Yup.object().shape({
      country_code: Yup.string().notRequired(),
      value: Yup.string().required(strings.phoneNumRequired),
    }),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setBreadcrumbs([{ title: strings.addCompany, route: '' }]);
  }, []);

  const handleCompanyCreate = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await createCompanyApi(values);
      if (response.data.type === 'success') {
        setApiError('');
        resetForm();
        setIsModalOpen(true);
        setUser({
          ...user,
          companies: [response.data.data, ...user?.companies],
        });
        setIsLoading(false);
      } else {
        setApiError(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setApiError(error.message);
    }
  };

  const companySchema = Yup.object().shape(schemaObj);
  const formik = useFormik({
    initialValues: {
      company_name: '',
      email: '',
      phone_number: {
        country_code: '+46',
        value: '',
      },
      admin: user ? user._id : '',
    },
    validationSchema: companySchema,
    onSubmit: handleCompanyCreate,
  });

  const renderInputSecction = () => {
    return (
      <form onSubmit={formik.handleSubmit} className={styles.formWrapperStyle}>
        <div className={styles.inputWrapperStyle}>
          <Input
            name="company_name"
            labelText={strings.companyName}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={'Ex: Vilje Tech'}
            value={formik.values.company_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.company_name && formik.errors.company_name
                ? formik.errors.company_name
                : ''
            }
            containerStyle={styles.inputStyle}
          />
          <Input
            name="email"
            labelText={strings.emailLabelRequired}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.mailIdExample}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            containerStyle={styles.inputStyle}
          />
          <NumberInput
            name="phone_number.value"
            lableText={strings.phoneNumCompulsory}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.phoneNumHere}
            value={formik.values.phone_number?.value}
            // onChange={formik.handleChange}
            onChange={(e) => formik.setFieldValue('phone_number.value', e.target.value.toString())}
            countryCode={formik.values.phone_number?.country_code}
            onChangeCountryCode={(value) =>
              formik.setFieldValue('phone_number.country_code', value)
            }
            onBlur={formik.handleBlur}
            // onFocus={() => formik.setFieldError('phone_number.value', '')}
            hasError={
              formik.touched.phone_number?.value && formik.errors.phone_number?.value
                ? formik.errors.phone_number?.value
                : ''
            }
          />
        </div>
        {renderBtns()}
      </form>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnContainerStyle}>
        <p className={styles.errorTextStyle}>{apiError}</p>
        <div className={styles.btnsViewStyle}>
          <Button
            onClick={() => formik.resetForm()}
            title={strings.cancel}
            variant="light"
            btnStyle={styles.customBtnStyle}
            size={width > 480 ? 'lg' : 'md'}
          />
          <Button
            title={strings.addCompanyBtn}
            type={'submit'}
            btnStyle={styles.customBtnStyle}
            size={width > 480 ? 'lg' : 'md'}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal showOverlay={true} show={isModalOpen} containerStyle={styles.modalViewStyle}>
        <SuccessCard
          title={strings.createCompanyMsgTitle}
          description={strings.suggestionBoardDescForAddCompany}
          leftBtnTitle={strings.backToDashBoard}
          leftAction={() => {
            setIsModalOpen(false);
            navigate(routePaths.dashboard);
          }}
          rightBtnTitle={strings.showSummary}
          rightAction={() => {
            setIsModalOpen(false);
            navigate(routePaths.companyList);
          }}
          cardBtnStyle={styles.btnGroupStyle}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      <SuggestionCard
        title={strings.suggestionBoardTitleForAddCompany}
        description={strings.suggestionBoardDescForAddCompany}
      />
      {renderInputSecction()}
      {renderModal()}
    </div>
  );
};

export default CompanyRegistration;
