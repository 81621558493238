import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStrings } from 'providers/stringsprovider';
import Button from 'components/common/button';
import AvatarInfoCard from 'components/common/avatar-info-card';
import PopOver from 'components/common/pop-over';
import Input from 'components/common/input';
import Avatar from 'components/common/avatar';
import { useUserData } from 'providers/userdataprovider';
import { useAuth } from 'providers/authprovider';
import { avatar1Icon, closeCircleFillGrayIcon } from 'resources/images';
import Image from 'components/common/image';
import { useNavigate } from 'react-router';
import Loader from 'components/common/loader';
import styles from './styles.module.css';

const AssignAssetCard = (props) => {
  const { asset, onClick = () => {}, onCancel = () => {}, setUser, isLoader } = props;
  const navigate = useNavigate();
  const { strings } = useStrings();
  const { users } = useUserData();
  const { user } = useAuth();
  const [userListRef, setUserListRef] = useState();
  const [usersList, setUsersList] = useState(users);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDropDownList, setShowDropDownList] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (users) {
      setUsersList(users.filter((item) => item._id !== user._id));
    }
  }, [users]);

  useEffect(() => {
    if (query.length > 0) {
      setUsersList(
        users.filter((user) => JSON.stringify(user).toLowerCase().includes(query.toLowerCase()))
      );
    } else {
      setUsersList(users);
    }
  }, [users, query]);

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser._id);
    }
  }, [selectedUser]);

  const renderSelectUserSection = () => {
    return (
      <div className={styles.assignToViewStyles}>
        <p className={styles.assignToLabelTextStyles}>
          {asset?.assigned_to ? strings.assignToDiffUser : strings.assignToRequired}
        </p>
        <div className={styles.inviteUserWrapperStyle}>
          {selectedUser ? (
            <div className={styles.selectedUserWrapperStyle}>
              <Image
                src={closeCircleFillGrayIcon}
                containerStyle={styles.assignedUserRemoveIconStyle}
                onClick={() => setSelectedUser(null)}
              />
              <div className={styles.selectedProfileWrapperStyle}>
                <Avatar src={selectedUser.image || avatar1Icon} />
                <div className={styles.profileTextWrapperStyle}>
                  <p className={styles.profileTitleStyle}>{selectedUser.full_name}</p>
                  <p className={styles.profileDescStyle}>{selectedUser.email}</p>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div ref={setUserListRef} className={styles.assignUserInputWrapperStyle}>
                <Input
                  placeholder={strings.typeEmailOrEmployeeName}
                  inputStyle={styles.assignUserInputStyle}
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    setShowDropDownList(true);
                  }}
                />
              </div>
              <PopOver
                reference={userListRef}
                show={showDropDownList}
                onClose={() => setShowDropDownList(false)}
                containerStyle={styles.dropDownWrapperStyle}
              >
                {usersList.length > 0 ? (
                  usersList
                    .filter((item) => item._id !== asset?.assigned_to?._id)
                    .map((user, index) => (
                      <div
                        className={styles.profileWrapperStyle}
                        key={'user' + index}
                        onClick={() => {
                          setSelectedUser(user);
                        }}
                      >
                        <Avatar src={avatar1Icon} />
                        <div className={styles.profileTextWrapperStyle}>
                          <p className={styles.profileTitleStyle}>{user.full_name}</p>
                          <p className={styles.profileDescStyle}>{user.email}</p>
                        </div>
                      </div>
                    ))
                ) : (
                  <p className={styles.noDataStyle}>No data available</p>
                )}
              </PopOver>
              {/* <Button
                title={strings.addNewuser}
                leftIcon={addUserIcon}
                leftIconStyle={styles.assetTypeIconStyle}
                btnStyle={styles.addUserBtnStyle}
                variant="gray"
                onClick={() => {
                  navigate(routePaths.createEmployee);
                }}
              /> */}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnsWrapperStyle}>
        <Button
          title="Cancel"
          variant="light"
          size="md"
          onClick={onCancel}
          btnStyle={styles.btnStyle}
        />
        <Button
          title="Confirm"
          size="md"
          onClick={onClick}
          btnStyle={styles.btnStyle}
          disabled={selectedUser === null ? true : false}
        />
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {isLoader && <Loader />}
      <div className={styles.assetStyles}>
        <p className={styles.assetTextStyles}>
          {asset.asset_uid}: {asset.asset_name}
        </p>
      </div>
      <div className={styles.subContainerStyle}>
        <div className={styles.titleViewStyle}>
          <p className={styles.titleTextStyles}>
            {asset?.assigned_to ? strings.assignAssetDifferntUser : strings.assignAsset}
          </p>
          <p className={styles.descpTextStyles}>
            {asset?.assigned_to ? strings.note : strings.selectRegisteruser}
          </p>
        </div>
        {/* // currently assigned view */}
        {asset?.assigned_to && (
          <div className={styles.currentlyAssignedViewStyles}>
            <p className={styles.currentlyAssignedlabelStyles}>{strings.currentlyAssignLabel}</p>
            <div className={styles.userDetailsStyles}>
              <AvatarInfoCard
                icon={asset.assigned_to?.image}
                title={asset?.assigned_to.full_name}
                description={asset?.assigned_to.email}
                containerStyle={styles.avatarInfoCardStyle}
                accountType={asset.assigned_to.account_type}
              />
            </div>
          </div>
        )}
        {/* // select user */}
        {renderSelectUserSection()}
        {renderBtns()}
      </div>
    </div>
  );
};

AssignAssetCard.propTypes = {
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  asset: PropTypes.object,
  isLoader: PropTypes.bool,
};

export default AssignAssetCard;
