import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  assetAssignApi,
  assetAssignToOthersApi,
  assetDetailsApi,
  assetUnAssignApi,
  getAssetReminderListApi,
  markAsLostApi,
  assetUpdateApi,
  assetDeleteApi,
} from 'networking/api/asset';
import Loader from 'components/common/loader';
import { useAppData } from 'providers/appdataprovider';
import Image from 'components/common/image';
import Avatar from 'components/common/avatar';
import moment from 'moment';
import { useStrings } from 'providers/stringsprovider';
import Button from 'components/common/button';
import {
  arrowLeftIcon,
  assetIcon,
  assignToBlackIcon,
  assignedGrayIcon,
  attachmentIcon,
  chevronDownFillBlackIcon,
  chevronDownFillBlueIcon,
  chevronRightPurpleIcon,
  closeIcon,
  deleteIcon,
  historyGaryIcon,
  historyIcon,
  infoCircleFillRedIcon,
  infoCircleIcon,
  infoCircleUpFillGrayIcon,
  multiSelectIcon,
  multiselectPurpleIcon,
  optionsGrayIcon,
  optionsIcon,
  pencilEditBlackIcon,
  ticketsGrayIcon,
  ticketsIcon,
} from 'resources/images';
import Modal from 'components/common/modal';
import SuccessCard from 'components/success-card';
import PopOver from 'components/common/pop-over';
import AssignAssetCard from 'components/assign-asset-card';
import {
  updateAssetFunc,
  updateOverAllAssetFunc,
  updateOverAllAssetWhenAssetUpdatedFunc,
  updateOverAllUsersWhenAssetUpdatedFunc,
} from 'functions';
import { useUserData } from 'providers/userdataprovider';
import { socket } from 'providers/socketprovider';
import classNames from 'classnames';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AssetAndUserCard from 'components/asset&user-card';
import Capsule from 'components/common/capsule';
import StatisticsCard from 'components/statistics-card';
import InfoCard from 'components/info-card';
import Reminders from 'sections/assets/reminders';
import Attachments from 'sections/assets/attachments';
import UserTickets from 'sections/employee/user-tickets';
import UserClusters from 'sections/employee/user-clusters';
import EditAsset from 'sections/assets/edit-asset';
import MultiFileUpload from 'components/multi-file-upload';
import AllRemainders from 'sections/assets/all-reminders';
import AddEditReminder from 'sections/assets/add-edit-reminder';
import { useAuth } from 'providers/authprovider';
import { account_types, reminderActionTypes } from 'resources/data';
import routePaths from 'routes/paths';
import { isEqual } from 'lodash';
import { useToast } from 'providers/toastprovider';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.css';

const AssetDetails = () => {
  const { id } = useParams();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const toast = useToast();
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const { assets, setAssets, users, setUsers, tickets, clusters } = useUserData();
  const { strings } = useStrings();
  const { userType } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [assetDetails, setAssetDetails] = useState(null);
  const [reminders, setReminders] = useState([]);
  const [remindersLoading, setRemindersLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickerRef, setPickerRef] = useState();
  const [assetRef, setAssetRef] = useState();
  const [isShowActionOptions, setIsShowActionOptions] = useState(false);
  const [isShowAssignAssetModal, setIsShowAssignAssetModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionLoader, setActionLoader] = useState(false);
  const [showCurrentlyAssign, setShowCurrentlyAssign] = useState(true);
  const [showPreviouslyAssign, setShowPreviouslyAssign] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Overview');
  const [ongoingTickets, setOngoingTickets] = useState([]);
  const [assignedTickets, setAssignedTickets] = useState([]);
  const [assignedClusters, setAssignedClusters] = useState([]);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isShowAttachmentsModal, setisShowAttachmentsModal] = useState(false);
  const [isShowAllRemainders, setIsShowAllRemainders] = useState(false);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteAssetErr, setDeleteAssetErr] = useState('');
  const [attachDoneDisabled, setAttachDoneDisabled] = useState(false);
  const [isAttachValuesChanged, setIsAttachValuesChanged] = useState(false);
  const [assetDeletedModal, setAssetDeletedModal] = useState(false);

  const clusterTabs = [
    {
      title: 'Overview',
      icon: selectedTab === 'Overview' ? optionsIcon : optionsGrayIcon,
    },
    {
      title: 'Assigned',
      icon: selectedTab === 'Assigned' ? assignToBlackIcon : assignedGrayIcon,
    },
    {
      title: 'Tickets',
      icon: selectedTab === 'Tickets' ? ticketsIcon : ticketsGrayIcon,
    },
    {
      title: 'Maintenance',
      icon: selectedTab === 'Maintenance' ? historyIcon : historyGaryIcon,
    },
  ];

  const actionOptions = [strings.unAssign, strings.assignToOther];
  const assetOptions = [strings.markAsLost, strings.deleteAsset];
  const attachmentsData = [
    { title: 'Image', type: 'Doc', size: '2.3MB' },
    { title: 'Image', type: 'Doc', size: '2.3MB' },
    { title: 'Image', type: 'Doc', size: '2.3MB' },
  ];
  useEffect(() => {
    if (width <= 480) {
      setShowHeader(false);
    }
  }, [width]);

  useEffect(() => {
    setSelectedTab(searchParams.get('selectedTab') || 'Overview');
  }, [searchParams.get('selectedTab')]);

  useEffect(() => {
    getAssetDetails();
    getAssetReminderList();
    if (userType !== account_types.employee) {
      setUserCanEdit(true);
    }
  }, [id]);

  useEffect(() => {
    setIsAttachValuesChanged(isEqual(assetDetails?.attachments, attachments));
    setBreadcrumbs([
      { title: strings.allAssets, route: routePaths.assetList },
      { title: assetDetails ? `#${assetDetails?.asset_uid}` : 'Loading...', route: '' },
    ]);
  }, [assetDetails, attachments, setIsAttachValuesChanged]);

  useEffect(() => {
    socketListner();
  }, []);

  useEffect(() => {
    if (isShowAssignAssetModal) {
      document.body.style.overflow = 'hidden';
    }
  }, [isShowAssignAssetModal]);

  useEffect(() => {
    // * ongoing tickets
    let ongoingTicData = [];
    tickets.filter((ticket) => {
      if (ticket.asset._id === assetDetails?._id) {
        if (ticket.ticket_status !== 'Closed') {
          ongoingTicData.push(ticket);
        }
      }
    });
    setOngoingTickets(ongoingTicData);
    // * assigned tickets
    let ticData = [];
    tickets.filter((ticket) => {
      if (ticket.asset._id === assetDetails?._id) {
        ticData.push(ticket);
      }
    });
    setAssignedTickets(ticData);
    // * ongoing clusters
    let clusData = [];
    clusters.filter((cluster) => {
      if (cluster.last_report) {
        cluster.last_report.cluster_assets.filter((ass) => {
          if (ass.asset === assetDetails?._id) {
            clusData.push(cluster);
          }
        });
      }
    });
    setAssignedClusters(clusData);
  }, [assetDetails, tickets]);

  const socketListner = () => {
    socket.on('asset-assign', (data) => {
      setAssetDetails((prev) => {
        let previous_data = { ...prev };
        return updateAssetFunc(previous_data, data);
      });
    });
    socket.on('asset-assign-other', (data) => {
      setAssetDetails((prev) => {
        let previous_data = { ...prev };
        return updateAssetFunc(previous_data, data);
      });
    });
    socket.on('asset-unassign', (data) => {
      setAssetDetails((prev) => {
        let previous_data = { ...prev };
        return updateAssetFunc(previous_data, data);
      });
    });
    socket.on('asset-update', (data) => {
      setAssetDetails((prev) => {
        let previous_data = { ...prev };
        if (previous_data && previous_data._id === data._id) {
          return data;
        }
      });
    });
    socket.on('asset-delete', (data) => {
      setAssetDetails((prev) => {
        let previous_data = { ...prev };
        if (previous_data._id === data._id) {
          setAssetDeletedModal(true);
        }
        return previous_data;
      });
    });
    // remainders
    socket.on('asset-reminder-create', (data) => {
      setAssetDetails((prev) => {
        setReminders((rem) => {
          let previous_data = [...rem];
          if (prev?._id === data.asset) {
            let findIndex = previous_data.findIndex((item) => item._id === data._id);
            if (findIndex === -1) {
              previous_data = [{ ...data, status: data?.status || [] }, ...previous_data];
            }
          }
          return previous_data;
        });
        return prev;
      });
    });
    socket.on('asset-reminder-update', (data) => {
      setAssetDetails((prev) => {
        setReminders((rem) => {
          let previous_data = [...rem];
          if (prev?._id === data.asset) {
            let findIndex = previous_data.findIndex((item) => item._id === data._id);
            if (findIndex !== -1) {
              previous_data[findIndex] = data;
            }
          }
          return previous_data;
        });
        return prev;
      });
    });
    socket.on('asset-reminder-delete', (data) => {
      setAssetDetails((prev) => {
        setReminders((rem) => {
          let previous_data = [...rem];
          if (prev?._id === data.asset) {
            previous_data = previous_data.filter((item) => item._id !== data._id);
          }
          return previous_data;
        });
        return prev;
      });
    });
    socket.on('asset-reminder-trigger', (data) => {
      setReminders((prev) => {
        let previous_data = [...prev];
        let index = previous_data.findIndex((item) => item._id === data._id);
        if (index !== -1) {
          previous_data[index].status = [...previous_data[index].status, data?.status];
        }
        return previous_data;
      });
    });
  };

  const getAssetDetails = async () => {
    try {
      setLoader(true);
      let response = await assetDetailsApi(id);
      if (response.data.type === 'success') {
        setAssetDetails(response.data.data);
        setAttachments(response.data.data.attachments);
        setLoader(false);
      } else {
        console.log('get Asset Data api failed', response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log('get Asset Data api catch', error);
      setLoader(false);
    }
  };

  const getAssetReminderList = async () => {
    try {
      setRemindersLoading(true);
      let response = await getAssetReminderListApi(id);
      if (response.data.type === 'success') {
        setReminders(response.data.data);
        setRemindersLoading(false);
      } else {
        console.log('get Asset Reminders List api failed', response.data.message);
        setRemindersLoading(false);
      }
    } catch (error) {
      console.log('get Asset Reminders List api catch', error.message);
      setRemindersLoading(false);
    }
  };

  const tapOnActionOption = (option) => {
    setSelectedOption(option);
    if (option === strings.unAssign) {
      setIsModalOpen(true);
    }
    if (option === strings.assignToOther) {
      setIsShowAssignAssetModal(true);
    }
  };

  const assignAssetToOthers = async () => {
    if (selectedUser) {
      setActionLoader(true);
      try {
        let apiData = {
          asset_id: assetDetails._id,
          employee_id: selectedUser,
        };
        let response = await assetAssignToOthersApi(apiData);
        if (response.data.type === 'success') {
          setAssetDetails(response.data.data);
          setIsShowAssignAssetModal(false);
          let updatedData = updateOverAllAssetFunc(assets, response.data.data);
          setAssets(updatedData);
          setActionLoader(false);
          toast.success(response.data.message);
        } else {
          setActionLoader(false);
          toast.error(response.data.message);
          console.log('asset Assign To Others Api failed', response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
        setActionLoader(false);
        console.log('asset Assign To Others Api catch', error.response.message);
      }
    }
  };

  const assignAsset = async () => {
    if (selectedUser) {
      setActionLoader(true);
      try {
        let apiData = {
          asset_id: assetDetails?._id,
          employee_id: selectedUser,
        };
        let response = await assetAssignApi(apiData);
        if (response.data.type === 'success') {
          setAssetDetails(response.data.data);
          let updatedData = updateOverAllAssetFunc(assets, response.data.data);
          setAssets(updatedData);
          setActionLoader(false);
          setIsShowAssignAssetModal(false);
          toast.success(response.data.message);
        } else {
          setActionLoader(false);
          toast.error(response.data.message);
          console.log('asset Assign Api failed', response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
        setActionLoader(false);
        console.log('asset Assign Api catch', error.response.message);
      }
    }
  };

  const unAssignAsset = async () => {
    try {
      setActionLoader(true);
      let response = await assetUnAssignApi(assetDetails?._id);
      if (response.data.type === 'success') {
        setAssetDetails(response.data.data);
        let updatedData = updateOverAllAssetFunc(assets, response.data.data);
        setAssets(updatedData);
        setIsModalOpen(false);
        setActionLoader(false);
        toast.success(response.data.message);
      } else {
        setActionLoader(false);
        toast.error(response.data.message);
        console.log('Asset UnAssign api failed', response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      setActionLoader(false);
      console.log('Asset UnAssign api catch', error.response.message);
    }
  };

  // const tapOnAssetOption = (option) => {
  //   setSelectedOption(option);
  //   if (option === strings.markAsLost) {
  //     setIsModalOpen(true);
  //   }
  // };

  const handleUpdateAttachments = async () => {
    try {
      setActionLoader(true);
      let response = await assetUpdateApi({
        asset_id: assetDetails._id,
        ...assetDetails,
        assigned_to: assetDetails?.assigned_to?._id,
        attachments: attachments.map((item) => item._id),
      });
      if (response.data.type === 'success') {
        setAssetDetails(response.data.data);
        toast.success('Attachments updated');
      } else {
        toast.error(response.data.message);
        console.log('Update attachments api failed', response.data.message);
      }
      setisShowAttachmentsModal(false);
      setActionLoader(false);
    } catch (error) {
      setActionLoader(false);
      toast.error(error.message);
      console.log('Update attachments api catch', error.message);
    }
  };

  const markAsLost = async () => {
    setActionLoader(true);
    try {
      let response = await markAsLostApi(assetDetails?._id);
      if (response.data.type === 'success') {
        setAssetDetails({
          ...assetDetails,
          asset_status: response.data.data.asset_status,
        });
        let updatedAssetsData = updateOverAllAssetWhenAssetUpdatedFunc(assets, response.data.data);
        setAssets(updatedAssetsData);
        let updatedUsersData = updateOverAllUsersWhenAssetUpdatedFunc(users, response.data.data);
        setUsers(updatedUsersData);
        setIsModalOpen(false);
        setActionLoader(false);
        toast.success(response.data.message);
      } else {
        setActionLoader(false);
        toast.error(response.data.message);
        console.log('response api failed', response.data.message);
      }
    } catch (error) {
      setActionLoader(false);
      toast.error(error.message);
      console.log('markAslostResponse api catch', error);
    }
  };

  const handleDeleteAsset = async () => {
    try {
      setDeleteLoader(true);
      const response = await assetDeleteApi(assetDetails?._id);
      if (response.data.type === 'success') {
        setDeleteLoader(false);
        let filteredAssets = assets.filter((item) => item._id !== assetDetails._id);
        setAssets(filteredAssets);
        setDeleteModalOpen(false);
        toast.success(response.data.message);
        navigate(routePaths.assetList);
      } else {
        console.log('Delete Asset api failed', response.data.message);
        setDeleteAssetErr(response.data.message);
        setDeleteLoader(false);
      }
    } catch (error) {
      console.log('get Asset Delete api catch', error);
      setDeleteAssetErr(error);
      setDeleteLoader(false);
    }
  };

  const toggleTab = (tab) => {
    setSelectedTab(tab);
    setSearchParams({ selectedTab: tab });
  };
  // Ui part start //

  const renderBackOptionSection = () => {
    return (
      <div className={styles.backOptionViewStyle}>
        <div className={styles.backOptionSubViewStyle}>
          <Image
            onClick={() => {
              navigate(-1);
            }}
            src={arrowLeftIcon}
            alt="<"
            containerStyle={styles.backArrowStyle}
          />
          <p className={styles.backTextStyle}>Back</p>
        </div>
        {!assetDetails?.assigned_to &&
          assetDetails?.asset_status !== 'Marked lost' &&
          selectedTab !== 'Assigned' &&
          userType !== account_types.employee && (
            <Button
              title={strings.assignNow}
              onClick={() => setIsShowAssignAssetModal(true)}
              size="md"
              disabled={assetDetails?.asset_status === 'Marked lost' ? true : false}
            />
          )}
      </div>
    );
  };

  const renderAssetInfo = () => {
    return (
      <div className={styles.assetInfoSectionStyle}>
        <div className={styles.assetInfoViewStyle}>
          <div className={styles.assetWrapperStyle}>
            <Image
              src={assetDetails?.image || assetIcon}
              alt="asseet"
              containerStyle={styles.avatarViewStyle}
            />
            <div className={styles.assetDetailsViewStyle}>
              <p className={styles.titleTextStyles}>
                #{assetDetails?.asset_uid} : {assetDetails?.asset_name}
              </p>
              <p className={styles.descTextStyle}>
                {strings.assetRegdOn} {moment(assetDetails?.created_at).format('ll')}
              </p>
            </div>
          </div>
          {width > 480 && userCanEdit && (
            <div className={styles.btnStyle} ref={setAssetRef}>
              <Button
                variant="light"
                // title={strings.options}
                leftIcon={deleteIcon}
                btnStyle={styles.optionsBtnStyle}
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
              />
            </div>
          )}
        </div>
        {assetDetails?.asset_status !== 'Marked lost' ? (
          assetDetails?.assigned_to === null && (
            <div className={styles.assetStatusViewStyle}>
              <div className={styles.noteViewStyle}>
                <Image src={infoCircleIcon} alt="info" containerStyle={styles.infoIconStyle} />
                <p className={styles.noteTextStyle}>{strings.assetIsUnassigned}</p>
              </div>
              {width > 480 && userCanEdit && userType !== account_types.employee && (
                <Button title={strings.assignNow} onClick={() => setIsShowAssignAssetModal(true)} />
              )}
            </div>
          )
        ) : (
          <div className={styles.assetStatusViewStyle}>
            <div className={styles.noteWhenLostAssetStyle}>
              <Image src={infoCircleFillRedIcon} alt="info" containerStyle={styles.infoIconStyle} />
              <p className={styles.noteTextStyle}> {strings.assetMarkAsLost}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTabsSection = () => {
    return (
      <div className={styles.tabsWrapperStyle}>
        {clusterTabs.map((tab, index) => (
          <Capsule
            key={index}
            leftIcon={tab.icon}
            label={tab.title}
            containerStyle={
              selectedTab === tab.title ? styles.selectedCapsuleStyle : styles.capsuleViewStyle
            }
            labelStyle={
              selectedTab === tab.title ? styles.selectedLableStyle : styles.capsuleLableStyle
            }
            onClick={() => toggleTab(tab.title)}
          />
        ))}
      </div>
    );
  };

  const renderCurrentAndPreviousAssetsInfo = () => {
    return (
      <div className={styles.assetAssignUnAssignWrapperStyle}>
        {renderTabsSection()}
        <div className={styles.tabContentViewStyle}>
          {selectedTab === 'Overview' && renderOverViewSection()}
          {selectedTab === 'Assigned' && renderAssignedSection()}
          {selectedTab === 'Tickets' && <UserTickets tickets={assignedTickets} />}
          {selectedTab === 'Maintenance' && <UserClusters clusters={assignedClusters} />}
        </div>
      </div>
    );
  };

  const renderAddReminderModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={reminderModal}
        containerStyle={styles.reminderModalViewStyle}
        handleClickOutSide={() => setReminderModal(false)}
      >
        <AddEditReminder
          type={reminderActionTypes.add}
          handleWithApi
          onclose={() => setReminderModal(false)}
          reminder={{ asset: assetDetails?._id }}
          setReminder={(data) =>
            setReminders((prev) => {
              let preData = [...prev];
              let index = preData.findIndex((item) => item._id === data._id);
              if (index === -1) {
                return [data, ...preData];
              } else {
                return preData;
              }
            })
          }
        />
      </Modal>
    );
  };

  const renderOverViewSection = () => {
    return (
      <div className={styles.overViewStyle}>
        <div className={styles.userInfoSectionStyle}>
          <div className={styles.userInfoWrapperStyle}>
            <div className={styles.userInfoSubWrapperStyle}>
              <p className={styles.lableTextStyle}>Asset info : </p>
              {userType !== account_types.employee && (
                <React.Fragment>
                  <Button
                    leftIcon={pencilEditBlackIcon}
                    onClick={() => {
                      userCanEdit && setIsShowEditModal(true);
                    }}
                    title="Edit info"
                    variant="light"
                    size="md"
                    btnStyle={classNames(
                      styles.editInfoBtnStyle,
                      !userCanEdit && styles.disableViewStyle
                    )}
                    leftIconStyle={styles.btnLeftIconStyle}
                  />
                  <p
                    className={classNames(
                      styles.editInfoTextStyle,
                      !userCanEdit && styles.disableViewStyle
                    )}
                    onClick={() => {
                      userCanEdit && setIsShowEditModal(true);
                    }}
                  >
                    Edit info{' '}
                  </p>
                </React.Fragment>
              )}
            </div>
            <InfoCard data={assetDetails} type="asset" />
          </div>
        </div>
        <div className={styles.userInfoSectionStyle}>
          <div className={styles.userInfoWrapperStyle}>
            <div className={styles.labelWrapperStyle}>
              <p className={styles.lableTextStyle}>Reminders: </p>
              <div className={styles.viewAllAndBtnWrapperStyle}>
                {userType !== account_types.employee && (
                  <Button
                    leftIcon={multiSelectIcon}
                    title="Add reminder"
                    variant="light"
                    size="md"
                    onClick={() => {
                      userCanEdit && setReminderModal(true);
                    }}
                    btnStyle={classNames(
                      styles.reminderBtnStyle,
                      !userCanEdit && styles.disableViewStyle
                    )}
                    leftIconStyle={styles.btnLeftIconStyle}
                  />
                )}

                {reminders && reminders.length > 0 ? (
                  <div
                    className={styles.viewAllWrapperStyle}
                    onClick={() => {
                      setIsShowAllRemainders(true);
                    }}
                  >
                    <p className={styles.viewAllTextStyle}>View all</p>
                    <Image
                      containerStyle={styles.viewAllIconStyle}
                      src={chevronRightPurpleIcon}
                      alt="icon"
                    />
                  </div>
                ) : (
                  userType !== account_types.employee && (
                    <p
                      className={classNames(
                        styles.addNewTextStyle,
                        !userCanEdit && styles.disableViewStyle
                      )}
                      onClick={() => {
                        userCanEdit && setReminderModal(true);
                      }}
                    >
                      Add new{' '}
                    </p>
                  )
                )}
              </div>
            </div>
            <div className={styles.remaindersViewStyle}>
              {remindersLoading ? (
                <Loader />
              ) : (
                <Reminders type="asset" reminders={reminders} setReminders={setReminders} />
              )}
              {reminders && reminders.length > 0 && userType !== account_types.employee && (
                <div
                  className={classNames(
                    styles.addNewBtnViewStyle,
                    !userCanEdit && styles.disableViewStyle
                  )}
                  onClick={() => {
                    userCanEdit && setReminderModal(true);
                  }}
                >
                  <Image
                    containerStyle={styles.viewAllIconStyle}
                    src={multiselectPurpleIcon}
                    alt="icon"
                  />
                  <p className={styles.viewAllTextStyle}>Add new reminder</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.userInfoSectionStyle}>
          <div className={styles.userInfoWrapperStyle}>
            <div className={styles.userInfoSubWrapperStyle}>
              <p className={styles.lableTextStyle}>Attachments: </p>
              {userType !== account_types.employee && (
                <React.Fragment>
                  <Button
                    leftIcon={attachmentIcon}
                    onClick={() => {
                      userCanEdit && setisShowAttachmentsModal(true);
                    }}
                    title={
                      assetDetails?.attachments.length > 0 ? 'Edit attachments' : 'Add attachments'
                    }
                    variant="light"
                    size="md"
                    btnStyle={classNames(
                      styles.reminderBtnStyle,
                      !userCanEdit && styles.disableViewStyle
                    )}
                    leftIconStyle={styles.btnLeftIconStyle}
                  />
                  <p
                    className={classNames(
                      styles.editInfoTextStyle,
                      !userCanEdit && styles.disableViewStyle
                    )}
                    onClick={() => {
                      userCanEdit && setisShowAttachmentsModal(true);
                    }}
                  >
                    {assetDetails?.attachments.length > 0 ? 'Edit' : 'Add new'}
                  </p>
                </React.Fragment>
              )}
            </div>
            <Attachments type="asset" data={assetDetails?.attachments || []} />
          </div>
        </div>
        <div className={styles.userInfoWrapperStyle}>
          <p className={styles.lableTextStyle}>Key statistics: </p>
          <div className={styles.statisticsCardWrapperStyle}>
            <StatisticsCard
              onClick={() => toggleTab('Tickets')}
              count={ongoingTickets?.length}
              title={'Ongoing tickets:'}
              containerStyle={styles.statisticsCardContainerStyle}
              countStyle={styles.countStatisticsStyle}
            />
            <StatisticsCard
              onClick={() => toggleTab('Maintenance')}
              count={assignedClusters?.length}
              title={'Ongoing Maintenance:'}
              containerStyle={styles.statisticsCardContainerStyle}
              countStyle={styles.countStatisticsStyle}
            />
          </div>
        </div>
        {renderAddReminderModal()}
      </div>
    );
  };

  const renderAssignedSection = () => {
    return (
      <React.Fragment>
        {assetDetails?.assigned_to || assetDetails?.assigned_data?.length > 0 ? (
          <div className={styles.assetAssignWrapperStyle}>
            {assetDetails?.assigned_to && currentlyAssignedSection()}
            {assetDetails?.assigned_data?.length > 0 && previouslyAssignedSection()}
          </div>
        ) : (
          renderEmptyDataSection()
        )}
      </React.Fragment>
    );
  };

  const renderEmptyDataSection = () => {
    return (
      <div className={styles.emptyDataViewStyle}>
        <div className={styles.emptyDataSubViewStyle}>
          <Image src={infoCircleIcon} />
          <p className={styles.emptyDataTextStyle}>
            This asset has never been assigned to anyone yet.
          </p>
        </div>
        {assetDetails?.asset_status !== 'Marked lost' && userCanEdit && (
          <div className={styles.emptyDataSubViewStyle}>
            <Button title="Assign now" onClick={() => setIsShowAssignAssetModal(true)} size="md" />
            <Button
              title="Delete asset"
              variant="light"
              size="md"
              onClick={() => setDeleteModalOpen(true)}
            />
          </div>
        )}
      </div>
    );
  };

  const currentlyAssignedSection = () => {
    return (
      <React.Fragment>
        {width <= 480 ? (
          currentlyAssignedMobileSection()
        ) : (
          <div className={styles.tableSectionStyle}>
            <table className={styles.tableWrapperStyle}>
              <thead>
                <tr className={styles.tableHeaderRowStyle}>
                  <th>{strings.dateAndTime}</th>
                  <th>{strings.currentlyAssign} to</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.tableRowStyle}>
                  <td>
                    <div className={styles.customColStyle}>
                      <span>{moment(assetDetails?.assigned_at).format('ll')}</span>
                      <span className={styles.tableSmallTextStyle}>
                        {moment(assetDetails?.assigned_at).format('LT')}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => {
                        if (userType !== account_types.employee) {
                          navigate(routePaths.employeeDetails(assetDetails?.assigned_to?._id));
                        }
                      }}
                      className={styles.avatarWrapperStyle}
                    >
                      <Avatar
                        src={assetDetails?.assigned_to?.image}
                        label={assetDetails?.assigned_to?.full_name}
                        containerStyle={styles.avatarStyle}
                      />
                      <div className={styles.customColStyle}>
                        <span>{assetDetails?.assigned_to?.full_name}</span>
                        <span className={styles.tableSmallTextStyle}>
                          {assetDetails?.assigned_to?.email}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.actionBtnViewStyle}>
                      <div className={styles.btnStyle} ref={setPickerRef}>
                        <Button
                          title={strings.actions}
                          rightIcon={chevronDownFillBlueIcon}
                          onClick={(e) => {
                            setIsShowActionOptions(true);
                          }}
                          btnStyle={classNames(
                            styles.actionBtnStyle,
                            !userCanEdit && styles.disableViewStyle
                          )}
                          size="md"
                          disabled={!userCanEdit}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  };

  const currentlyAssignedMobileSection = () => {
    return (
      <div className={styles.currentlyAssignedMobileSectionStyle}>
        <div className={styles.lableViewStyle}>
          <p className={styles.lableTextStyle}>{strings.currentlyAssignLabel}</p>
          <Image
            onClick={() => setShowCurrentlyAssign(!showCurrentlyAssign)}
            src={chevronDownFillBlackIcon}
            containerStyle={
              showCurrentlyAssign ? styles.arrowRotateViewStyle : styles.dropDownIconStyle
            }
          />
        </div>
        {showCurrentlyAssign && (
          <AssetAndUserCard
            type="asset"
            title={assetDetails?.assigned_to?.full_name}
            description={assetDetails?.assigned_to?.email}
            image={assetDetails?.assigned_to?.image}
            arrowRight={false}
            leftLable="Date & Time"
            leftTitle={
              assetDetails?.assigned_at ? moment(assetDetails?.assigned_at).format('ll') : '- - -'
            }
            leftDescription={
              assetDetails?.assigned_at ? moment(assetDetails?.assigned_at).format('LT') : ''
            }
            actionBtn={true}
            bottomSectionStyle={styles.cardBottomSectionStyle}
            tapOnAction={(e) => {
              setIsShowActionOptions(true);
              setPickerRef(e.target);
            }}
          />
        )}
      </div>
    );
  };

  const previouslyAssignedSection = () => {
    return (
      <React.Fragment>
        {width <= 480 ? (
          previouslyAssignedMobileSection()
        ) : (
          <div className={styles.tableSectionStyle}>
            <table className={styles.tableWrapperStyle}>
              <thead>
                <tr className={styles.tableHeaderRowStyle}>
                  <th>{strings.dateAndTime}</th>
                  <th>{strings.previouslyAssignedTo}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {assetDetails?.assigned_data.map((item, index) => (
                  <tr key={index} className={styles.tableRowStyle}>
                    <td>
                      <div className={styles.customColStyle}>
                        <span>{moment(item.assigned_at).format('ll')}</span>
                        <span className={styles.tableSmallTextStyle}>
                          {moment(item.assigned_at).format('LT')}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={() => {
                          if (userType !== account_types.employee) {
                            navigate(routePaths.employeeDetails(item?.assigned_to?._id));
                          }
                        }}
                        className={styles.avatarWrapperStyle}
                      >
                        <Avatar
                          src={item.assigned_to.image}
                          label={item.assigned_to.full_name}
                          containerStyle={styles.avatarStyle}
                        />
                        <div className={styles.customColStyle}>
                          <span>{item.assigned_to.full_name}</span>
                          <span className={styles.tableSmallTextStyle}>
                            {item.assigned_to.email}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  };

  const previouslyAssignedMobileSection = () => {
    return (
      <div className={styles.currentlyAssignedMobileSectionStyle}>
        <div className={styles.lableViewStyle}>
          <p className={styles.lableTextStyle}>{strings.previouslyAssignedTo}</p>
          <Image
            onClick={() =>
              assetDetails?.assigned_data.length > 0 &&
              setShowPreviouslyAssign(!showPreviouslyAssign)
            }
            src={chevronDownFillBlackIcon}
            containerStyle={
              showPreviouslyAssign ? styles.arrowRotateViewStyle : styles.dropDownIconStyle
            }
          />
        </div>
        {assetDetails?.assigned_data.length > 0 &&
          showPreviouslyAssign &&
          assetDetails?.assigned_data.map((item, index) => (
            <React.Fragment key={index}>
              <AssetAndUserCard
                type="user"
                title={item.assigned_to.full_name}
                description={item.assigned_to.email}
                image={item.assigned_to.image}
                arrowRight={false}
                leftLable="Date & Time"
                leftTitle={item.assigned_at ? moment(item.assigned_at).format('ll') : '- - -'}
                leftDescription={item.assigned_at ? moment(item.assigned_at).format('LT') : ''}
                deleteImg={true}
                bottomSectionStyle={styles.cardBottomSectionStyle2}
                deleteAction={() => {}}
              />
            </React.Fragment>
          ))}
      </div>
    );
  };

  const renderActionOptions = () => {
    return (
      <PopOver
        show={isShowActionOptions}
        reference={pickerRef}
        onClose={() => setIsShowActionOptions(false)}
        containerStyle={styles.actionPopUpViewStyle}
      >
        <div className={styles.actionOptionsViewStyle}>
          {actionOptions.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  tapOnActionOption(item);
                  setIsShowActionOptions(false);
                }}
                className={styles.optionsViewStyles}
              >
                {item}
              </p>
            );
          })}
        </div>
      </PopOver>
    );
  };

  const renderAlertModal = () => {
    return (
      <Modal showOverlay={true} show={isModalOpen} containerStyle={styles.alertModalViewStyle}>
        <SuccessCard
          title={
            selectedOption === strings.unAssign
              ? `${strings.areUnAssignAsset} #${assetDetails?.asset_uid}?`
              : selectedOption === strings.markAsLost
              ? strings.deleteAssetAlertTitle
              : ''
          }
          description={
            selectedOption === strings.unAssign
              ? `${strings.theAssetIsCurrentlyUnAssign} @${assetDetails?.assigned_to?.full_name}. ${strings.confirmUnAssignAsset}`
              : selectedOption === strings.markAsLost
              ? strings.deleteAssetAlertDesc
              : ''
          }
          leftBtnTitle={
            selectedOption === strings.markAsLost ? strings.markAsLost : strings.confirm
          }
          leftAction={() => {
            selectedOption === strings.unAssign
              ? unAssignAsset()
              : selectedOption === strings.markAsLost
              ? markAsLost()
              : console.log('');
          }}
          rightBtnTitle={strings.cancel}
          rightAction={() => setIsModalOpen(false)}
          isLoader={actionLoader}
        />
      </Modal>
    );
  };

  const renderAssignAssetModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAssignAssetModal}
        containerStyle={styles.assetModalViewStyle}
      >
        <AssignAssetCard
          asset={assetDetails}
          setUser={setSelectedUser}
          onCancel={() => {
            setIsShowAssignAssetModal(false);
            setSelectedUser(null);
          }}
          isLoader={actionLoader}
          onClick={() => (assetDetails?.assigned_to ? assignAssetToOthers() : assignAsset())}
        />
      </Modal>
    );
  };

  const renderEditInfoModal = () => {
    return (
      <Modal showOverlay={true} show={isShowEditModal} containerStyle={styles.modalViewStyle}>
        <EditAsset
          asset={assetDetails}
          onCancel={() => setIsShowEditModal(false)}
          onSuccess={(data) => {
            setAssetDetails(data);
            setIsShowEditModal(false);
          }}
        />
      </Modal>
    );
  };

  const renderAllRemaindersModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAllRemainders}
        onClose={() => setIsShowAllRemainders(false)}
        closeOnOutSideClick
        containerStyle={styles.allRemaindersModalViewStyle}
      >
        <AllRemainders
          type="asset"
          remainders={reminders}
          onClose={() => setIsShowAllRemainders(false)}
        />
      </Modal>
    );
  };

  const renderAttachmentsModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAttachmentsModal}
        containerStyle={styles.editAttachmentsModalViewStyle}
        handleClickOutSide={() => {
          setisShowAttachmentsModal(false);
          setAttachments(assetDetails?.attachments);
        }}
      >
        <div className={styles.editAttachmentsViewStyle}>
          <div className={styles.editAttachmentsHeaderStyle}>
            <p className={styles.attachmentsHeaderTextStyle}>
              {assetDetails?.attachments.length > 0 ? 'Edit attachments' : 'Add attachments'}
            </p>
            <Image
              src={closeIcon}
              onClick={() => {
                setisShowAttachmentsModal(false);
                setAttachments(assetDetails?.attachments);
              }}
            />
          </div>
          <div className={styles.atttachmentsContentStyle}>
            <div className={styles.attachmentsLableStyle}>
              <p className={styles.labelTextStyle}>Attachments</p>
              <Image
                containerStyle={styles.infoIconStyle}
                src={infoCircleUpFillGrayIcon}
                alt="icon"
              />
            </div>
            <MultiFileUpload
              files={attachments || []}
              setFiles={(data) => setAttachments(data)}
              customListItemStyle={styles.customListItemStyle}
              setFilesUploading={(val) => setAttachDoneDisabled(val)}
            />
            <div className={styles.attachmentsBtnStyle}>
              <Button
                title="Done"
                onClick={handleUpdateAttachments}
                size="md"
                isLoading={actionLoader}
                disabled={actionLoader || attachDoneDisabled || isAttachValuesChanged}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isDeleteModalOpen}
        containerStyle={styles.alertDeleteModalViewStyle}
      >
        <div className={styles.deleteModalContainerStyle}>
          <div className={styles.deleteModalTextWrapperStyle}>
            <p className={styles.deleteTextStyle}>
              Are you sure you want to delete the asset #{assetDetails?.asset_uid}:
              {assetDetails?.asset_name}?
            </p>
            <p className={styles.deleteModalDescStyle}>
              By proceeding, the asset will be deleted from the trakr database and you will not be
              able to tag it in any future tickets or maintenance, and much more.
              <span>Learn more</span>
            </p>
          </div>
          {deleteAssetErr && <p className={styles.errorTextStyle}>{deleteAssetErr}</p>}
          <div className={styles.btnContainerStyle}>
            <Button
              title="Yes, proceed"
              variant="primary"
              size="md"
              disabled={deleteLoader}
              onClick={handleDeleteAsset}
              isLoading={deleteLoader}
            />
            <Button
              title="Cancel"
              variant="light"
              size="md"
              disabled={deleteLoader}
              onClick={() => {
                setDeleteModalOpen(false);
                setDeleteAssetErr('');
              }}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderDeletedAssetAlertModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={assetDeletedModal}
        containerStyle={styles.alertDeleteModalViewStyle}
      >
        <SuccessCard
          title={'Deleted!'}
          description={`This asset "#${assetDetails?.asset_uid}" deleted, please click on Continue to proceed`}
          titleStyle={styles.assetDeletedAlertTitleStyle}
          leftBtnTitle={strings.continue}
          leftAction={() => {
            setAssetDeletedModal(true);
            navigate(-1);
          }}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.detailsContainerStyle}>
      {loader ? (
        <div className={styles.loaderViewStyle}>
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          <React.Fragment>
            {assetDetails ? (
              <React.Fragment>
                {renderBackOptionSection()}
                {renderAssetInfo()}
                {renderCurrentAndPreviousAssetsInfo()}
              </React.Fragment>
            ) : (
              <div className={styles.emptyassetViewStyle}>
                <p className={styles.descTextStyle}>No asset data found with this id</p>
              </div>
            )}
          </React.Fragment>
          {renderAlertModal()}
          {renderAssignAssetModal()}
          {/* {renderAssetOptions()} */}
          {renderActionOptions()}
          {renderEditInfoModal()}
          {renderAttachmentsModal()}
          {renderAllRemaindersModal()}
          {renderDeleteModal()}
          {renderDeletedAssetAlertModal()}
        </React.Fragment>
      )}
    </div>
  );
};

export default AssetDetails;
