import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { closeIcon } from 'resources/images';
import { useUserData } from 'providers/userdataprovider';
import Image from 'components/common/image';
import Avatar from 'components/common/avatar';
import Modal from 'components/common/modal';
import ReminderDetails from 'components/reminder-details';
import styles from './styles.module.css';

const Notifications = (props) => {
  const { onClose = () => {} } = props;
  const { notifications } = useUserData();
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);

  useEffect(() => {
    let data = [];
    if (notifications.length > 0) {
      notifications.map((notify) => {
        if (notify?.status.length > 0) {
          notify.status.map((stat) => {
            data.push({ ...notify, status: [stat] });
          });
        }
      });
    }
    let ddd = data.sort(
      (a, b) => new Date(b.status[0].updated_at) - new Date(a.status[0].updated_at)
    );
    setNotificationsData(ddd);
  }, [notifications]);

  const renderNotifications = () => {
    return (
      <div className={styles.notificationsViewStyle}>
        {notificationsData.map((item, index) => (
          <div
            onClick={() => {
              setSelectedReminder(item);
              setIsShowModal(true);
            }}
            key={index}
            className={classNames(
              styles.notificationsSubViewStyle,
              selectedReminder &&
                selectedReminder.status[0]._id === item.status[0]._id &&
                styles.activeViewStyle,
              item.status[0].is_viewed && styles.readedMsgViewStyle
            )}
          >
            <div className={styles.notificationStyle}>
              <Avatar
                src={item?.image}
                label={item.title}
                containerStyle={styles.commentAvatarStyle}
              />
              <div className={styles.notificationTitleAndDesc}>
                <p className={styles.notificationTitleStyle}>{item.title}</p>
                <p className={styles.notificationDescStyle}>
                  type : {item.notificationType} •{' '}
                  {moment(item.status[0].updated_at).format('DD/MM/YYYY')} •{' '}
                  {moment(item.status[0].updated_at).format('LT')}
                </p>
              </div>
            </div>
            {item.status && item.status[0].is_viewed === false && (
              <div className={styles.markViewStyle}></div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderNotificationOverViewModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowModal}
        onClose={() => setIsShowModal(false)}
        closeOnOutSideClick
        containerStyle={styles.remainderOverModalStyle}
        overlayStyle={styles.remainderOverModalOverlayStyle}
      >
        <ReminderDetails
          reminder={selectedReminder}
          onCloseAction={() => {
            setIsShowModal(false);
            setSelectedReminder(null);
          }}
        />
      </Modal>
    );
  };

  const renderEmptyDataSection = () => {
    return (
      <div className={styles.emptyDataViewStyle}>
        <p className={styles.emptyDataTextStyle}>No data avaialble</p>
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      <div className={styles.headerViewStyle}>
        <p className={styles.headerTextStyle}>Notifications</p>
        <Image src={closeIcon} onClick={onClose} containerStyle={styles.colseIconStyle} />
      </div>
      {notificationsData.length > 0 ? renderNotifications() : renderEmptyDataSection()}
      {renderNotificationOverViewModal()}
    </div>
  );
};

Notifications.propTypes = {
  onClose: PropTypes.func,
};

export default Notifications;
