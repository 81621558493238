import React, { useEffect, useState } from 'react';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import { chevronDownGrayIcon, closeIcon, copyIcon } from 'resources/images';
import { useUserData } from 'providers/userdataprovider';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { account_types } from 'resources/data';
import Image from 'components/common/image';
import MultiSelectInput from 'components/common/multi-seleect-input';
import Button from 'components/common/button';
import PopOver from 'components/common/pop-over';
import Avatar from 'components/common/avatar';
import Loader from 'components/common/loader';
import styles from './styles.module.css';

const Members = (props) => {
  const {
    type,
    members,
    selectedMembers,
    setMembers,
    permission,
    setPermission,
    actionStatus = true,
    inviteAction = () => {},
    loader,
    closeAction = () => {},
    updatePermissionAction = () => {},
  } = props;
  const { users } = useUserData();
  const { user } = useAuth();
  const { width } = useWindowDimensions();
  const { strings } = useStrings();
  //   const [invitePermission, setInvitePermission] = useState('Can view');
  const [linkPermission, setLinkPermission] = useState(string.canView);
  const [availableMembers, setAvailableMembers] = useState([]);
  const [hasAccess, setHssAccess] = useState(false);

  //   useEffect(() => {
  //     setPermission(invitePermission);
  //   }, [invitePermission]);

  useEffect(() => {
    if (members) {
      const filteredArr = [...users].filter((item) =>
        members?.every((filterItem) => filterItem.user._id !== item._id)
      );
      setAvailableMembers(filteredArr);
    }
    let mem = members.find((mem) => mem.user._id === user._id);
    if (mem) {
      if (mem.user.account_type === account_types.super_admin) {
        if (type === 'ticket' && !actionStatus) {
          setHssAccess(false);
        } else {
          setHssAccess(true);
        }
      } else {
        if (type === 'ticket') {
          if (mem.user.account_type === account_types.admin && mem.can_edit === true) {
            setHssAccess(true);
          }
        } else {
          if (mem.user.account_type === account_types.admin && mem.permissions.includes('Edit')) {
            setHssAccess(true);
          }
        }
      }
    }
  }, [members]);

  const handleAccessChange = (permissionType, user) => {
    let data = { permition: permissionType, userId: user._id };
    updatePermissionAction(data);
  };

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerSectionStyle}>
        <p className={styles.headerTextStyle}>{strings.members}</p>
        <Image onClick={closeAction} src={closeIcon} alt="X" containerStyle={styles.iconStyle} />
      </div>
    );
  };

  const renderInputSection = () => {
    return (
      <div className={styles.inputWrapperStyle}>
        <div className={styles.inputSubWrapperStyle}>
          <MultiSelectInput
            placeholder={strings.searchUserLable}
            options={availableMembers.map((user) => {
              return {
                label: user.full_name,
                value: user._id,
                desc: user.employee_type,
              };
            })}
            multi={true}
            selectedData={selectedMembers}
            setSelectedData={(data) => {
              setMembers(data);
            }}
            customMultiSelectInputStyle={styles.inputSectionStyle}
            multiInputStyle={styles.inputStyle}
            eachOptionViewStyle={styles.membersOptionsViewStyle}
            optionsModalViewStyle={styles.optionsModalViewStyle}
            disabledInput={!hasAccess}
          />
          <Permissions
            permission={permission}
            setPermissionType={setPermission}
            access={hasAccess && actionStatus}
          />
        </div>
        {selectedMembers.length > 0 && <Button title={strings.invite} onClick={inviteAction} />}
      </div>
    );
  };

  const renderMembersSection = () => {
    return (
      <div className={styles.membersSectionStyle}>
        <p className={styles.membersLabelStyle}>{strings.addedMembers}</p>
        <div className={styles.membersViewStyle}>
          {members &&
            members.length > 0 &&
            members.map((item, index) => (
              <div key={index} className={styles.eachMemberWrapperStyle}>
                <div className={styles.membersDetailsStyle}>
                  <Avatar src={item.user.image} label={item.user.full_name} />
                  <div className={styles.membersDetailsSubViewStyle}>
                    <div className={styles.membersDetailsStyle}>
                      <p className={styles.memberTitleTextStyle}>{item.user.full_name}</p>
                      <p
                        className={classNames(
                          styles.accountTypeStyle,
                          item.user.account_type === account_types.admin && styles.adminTypeStyle,
                          item.user.account_type === account_types.super_admin &&
                            styles.superAdminTypeStyle
                        )}
                      >
                        {item.user.account_type}
                      </p>
                    </div>
                    <p className={styles.memberDescTextStyle}>{item.user.email}</p>
                  </div>
                </div>
                <Permissions
                  permission={
                    type === 'cluster'
                      ? item.permissions.includes('Edit')
                        ? strings.canEdit
                        : strings.canView
                      : item.can_edit === true
                      ? strings.canEdit
                      : strings.canView
                  }
                  setPermissionType={(e) => handleAccessChange(e, item.user)}
                  activeOptiion={
                    type === 'cluster'
                      ? item.permissions.includes('Edit')
                        ? { can_edit: true, can_view: true }
                        : { can_edit: false, can_view: true }
                      : item
                  }
                  access={
                    hasAccess &&
                    item.user.account_type !== account_types.super_admin &&
                    item.user._id !== user._id &&
                    actionStatus
                  }
                />
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div className={styles.bottomSectionStyle}>
        <div className={styles.bottomLetfSectionStyle}>
          <p className={styles.bottomleftTextStyle}>{strings.linkAnyone}</p>
          <Permissions
            permission={linkPermission}
            placementType={width <= 480 && 'top-start'}
            setPermissionType={setLinkPermission}
            access={hasAccess && actionStatus}
          />
        </div>
        <div className={styles.bottomRightSectionStyle}>
          <Image src={copyIcon} alt={strings.copy} />
          <p className={classNames(styles.bottomRightTextStyle)}>{strings.copy}</p>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      {loader && <Loader />}
      {renderHeaderSection()}
      {renderInputSection()}
      {renderMembersSection()}
      {renderBottomSection()}
    </div>
  );
};

Members.propTypes = {
  members: PropTypes.array,
  selectedMembers: PropTypes.array,
  setMembers: PropTypes.func,
  permission: PropTypes.string,
  setPermission: PropTypes.func,
  inviteAction: PropTypes.func,
  closeAction: PropTypes.func,
  updatePermissionAction: PropTypes.func,
  loader: PropTypes.bool,
  actionStatus: PropTypes.bool,
  type: PropTypes.oneOfType(['ticket', 'cluster']),
};

export default Members;

export const Permissions = (props) => {
  const { access, permission, setPermissionType, activeOptiion, placementType } = props;
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const [accessRef, setAccessRef] = useState(null);
  const [accessModal, setAccessModal] = useState(false);
  let accessOptions = [strings.canView, strings.canEdit];

  return (
    <div
      onClick={() => {
        access && setAccessModal(!accessModal);
      }}
      className={styles.accessSectionStyle}
      ref={setAccessRef}
    >
      <p className={classNames(styles.accessLabelTextStyle, !access && styles.disableStyle)}>
        {permission}
      </p>
      <Image src={chevronDownGrayIcon} alt="^" containerStyle={classNames(styles.downIconStyle)} />
      <PopOver
        show={accessModal}
        reference={accessRef}
        placement={placementType || 'bottom-end'}
        onClose={() => {
          setAccessModal(false);
        }}
        containerStyle={classNames(
          styles.accessModalStyle,
          width <= 480 && placementType && styles.customAccessModalStyle
        )}
      >
        <div className={styles.accessOptionsWrapperStyle}>
          {accessOptions.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setPermissionType(item);
                setAccessModal(false);
              }}
              className={classNames(
                styles.accessOptionsViewStyle,
                activeOptiion?.can_edit && item === strings.canEdit && styles.activeOptionViewStyle,
                activeOptiion?.can_view && item === strings.canView && styles.activeOptionViewStyle
              )}
            >
              <p className={styles.accessOptionTextStyle}>{item}</p>
            </div>
          ))}
        </div>
      </PopOver>
    </div>
  );
};
