import React, { useState } from 'react';
import Input from 'components/common/input';
import { chevronDownFillBlackIcon, scannerWhiteIcon, searchIcon } from 'resources/images';
import { useStrings } from 'providers/stringsprovider';
import Button from 'components/common/button';
import { useUserData } from 'providers/userdataprovider';
import ListItemCard from 'components/list-item-card';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.css';
import ChatDetails from 'sections/lost-and-found/chat-details';
import useWindowDimensions from 'hooks/useWindowDimensions';

const LostAndFound = () => {
  const { chatList } = useUserData();
  const { width } = useWindowDimensions();
  const { strings } = useStrings();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedChatId = searchParams.get('chatId');
  const assetId = searchParams.get('asset');
  const [query, setQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(strings.all);

  const renderListSection = () => {
    return (
      <div className={styles.listSectionWrapperStyle}>
        <div className={styles.headerSectionStyle}>
          {renderSearchSection()}
          {renderFilterSecctiion()}
        </div>
        {renderList()}
      </div>
    );
  };

  const renderSearchSection = () => {
    return (
      <div className={styles.searchWrapperStyle}>
        <Input
          placeholder={strings.searchAssets}
          inputStyle={styles.searchInputStyle}
          leftIcon={searchIcon}
          leftIconStyle={styles.searchIconStyle}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {/* <Button
          variant="gray"
          leftIcon={filterIcon}
          btnStyle={styles.searchBtnStyle}
          leftIconStyle={styles.searchIconStyle}
        /> */}
      </div>
    );
  };

  const renderFilterSecctiion = () => {
    return (
      <div className={styles.filterSectionWrapperStyle}>
        <p className={styles.headerTextStyle}>{strings.tickets}</p>
        <Button
          title={selectedFilter}
          rightIcon={chevronDownFillBlackIcon}
          btnStyle={styles.selectBtnStyle}
          variant="light"
        />
      </div>
    );
  };

  const renderList = () => {
    return (
      <div className={styles.listWrapperStyle}>
        {chatList &&
          chatList?.length > 0 &&
          chatList.map((chat, index) => (
            <React.Fragment key={'chat' + index}>
              <ListItemCard
                type="lost&found"
                icon={scannerWhiteIcon}
                title={chat?.asset_name}
                status={chat?.asset_status}
                description={chat.last_activity}
                subTitle={`#${chat?.asset_uid}`}
                onClick={() => {
                  setSearchParams({ chatId: chat?.lf_asset?._id, asset: chat._id });
                }}
                selected={assetId === chat._id}
              />
            </React.Fragment>
          ))}
      </div>
    );
  };

  const renderChatOverViewSection = () => {
    return (
      <div className={styles.chatOverViewSectionStyle}>
        <ChatDetails chat_id={selectedChatId} asset_id={assetId} />
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {(!assetId || width > 480) && renderListSection()}
      {assetId && renderChatOverViewSection()}
    </div>
  );
};

export default LostAndFound;
