import React from 'react';
import PropTypes from 'prop-types';
import { infoCircleIcon } from 'resources/images';
import Image from 'components/common/image';
import styles from './styles.module.css';
import classNames from 'classnames';

const Attachments = (props) => {
  const { type, data, customContainerStyle } = props;

  const renderList = () => {
    return (
      <React.Fragment>
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className={styles.documentItemWrapperStyle}
              onClick={() => window.open(item.file_url, '__blank')}
            >
              <p className={styles.documentTitleTextStyle}>{item.file_name}</p>
              <p className={styles.documentSizeTextStyle}>
                {item.file_ext} • {`${(item.file_size / 1024 / 1024).toFixed(2)} MB`}
              </p>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  const renderEmptyView = () => {
    return (
      <div className={styles.emptyViewStyle}>
        <Image containerStyle={styles.iconContainerStyle} src={infoCircleIcon} alt="icon" />
        <p className={styles.emptyTextStyle}>
          You have no attachments added for this {type}. Click on “Add attachments” to get started.
          <span className={styles.learnMoreTextStyle}> Learn more</span> about attachments.
        </p>
      </div>
    );
  };

  return (
    <div className={classNames(styles.attachmentsContainerStyle, customContainerStyle)}>
      {data.length > 0 ? renderList() : renderEmptyView()}
    </div>
  );
};

Attachments.propTypes = {
  data: PropTypes.array,
  type: PropTypes.oneOf(['asset', 'user']),
  customContainerStyle: PropTypes.string,
};

export default Attachments;
