import React, { createContext, useContext } from 'react';
import { Toaster, toast } from 'sonner';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const showToast = {
    success: (message, options = {}) => {
      const id = toast.success(message, {
        ...options,
        action: {
          label: 'Close',
          onClick: () => toast.dismiss(id),
        },
      });
    },
    error: (message, options = {}) => {
      const id = toast.error(message, {
        ...options,
        action: {
          label: 'Close',
          onClick: () => toast.dismiss(id),
        },
      });
    },
    info: (message, options = {}) => {
      const id = toast(message, {
        ...options,
        action: {
          label: 'Close',
          onClick: () => toast.dismiss(id),
        },
      });
    },
    warning: (message, options = {}) => toast(message, { ...options, icon: '⚠️' }),
    loading: (message, options = {}) => toast.loading(message, options),
    promise: (promise, messages, options = {}) => toast.promise(promise, messages, options),
    custom: (content, options = {}) => toast.custom(content, options),
    dismiss: (id) => toast.dismiss(id),
    clear: () => toast.dismiss(),
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <Toaster duration={3000} position="bottom-center" visibleToasts={3} />
    </ToastContext.Provider>
  );
};

export default ToastProvider;

export const useToast = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToast must be used within ToastProvider');
  }
  return context;
};

// import React, { createContext, useContext } from 'react';
// import { Slide, ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const ToastContext = createContext();

// const ToastProvider = ({ children }) => {
//   const toastOptions = {
//     autoClose: 3000,
//     hideProgressBar: true,
//     closeOnClick: true,
//     closeButton: false,
//     pauseOnHover: true,
//     dragggable: true,
//     progress: undefined,
//     icon: false,
//   };

//   const showToast = {
//     success: (message, options = {}) =>
//       toast.success(<CustomToast message={message} />, { ...toastOptions, ...options }),
//     error: (message, options = {}) =>
//       toast.error(<CustomToast message={message} />, { ...toastOptions, ...options }),
//     info: (message, options = {}) =>
//       toast.info(<CustomToast message={message} />, { ...toastOptions, ...options }),
//     warning: (message, options = {}) =>
//       toast.warn(<CustomToast message={message} />, { ...toastOptions, ...options }),
//     default: (message, options = {}) =>
//       toast(<CustomToast message={message} />, { ...toastOptions, ...options }),
//   };

//   return (
//     <ToastContext.Provider value={showToast}>
//       {children}
//       <ToastContainer stacked position="bottom-center" limit={5} transition={Slide} />
//     </ToastContext.Provider>
//   );
// };

// export default ToastProvider;

// const CustomToast = ({ message }) => (
//   <div
//     style={{
//       padding: '8px 16px',
//       borderRadius: '8px',
//       color: '#fff',
//       display: 'flex',
//       alignItems: 'center',
//       width: 'fit-content',
//     }}
//   >
//     <span
//       style={{
//         fontFamily: 'InterTight, sans-serif',
//         fontWeight: 600,
//         fontSize: '16px',
//         lineHeight: '24px',
//         width: 'auto',
//       }}
//     >
//       {message}
//     </span>
//   </div>
// );
