import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PopOver from 'components/common/pop-over';
import { useAuth } from 'providers/authprovider';
import {
  account_types,
  ticketAdminOptions,
  ticketCommonOptions,
  ticketMenuOptions,
} from 'resources/data';
import Button from 'components/common/button';
import AvatarGroup from 'components/common/avatar-group';
import Image from 'components/common/image';
import {
  chevronDownFillBlackIcon,
  chevronLeftBlackIcon,
  closeIcon,
  optionsVerticalBlackIcon,
  priorityIcon,
} from 'resources/images';
import classNames from 'classnames';
import { useStrings } from 'providers/stringsprovider';
import Modal from 'components/common/modal';
import SuccessCard from 'components/success-card';
import {
  inviteTicketMembersApi,
  markAsCannotSolveApi,
  markAsCompletedApi,
  markAsResolvedApi,
  markAsRevertApi,
  markAsWorkingApi,
  updateUserTicketAccessApi,
} from 'networking/api/tickets';
import { useUserData } from 'providers/userdataprovider';
import Loader from 'components/common/loader';
import ImageUpload from 'components/image-upload';
import Input from 'components/common/input';
import Members from 'components/members';
import Avatar from 'components/common/avatar';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useAppData } from 'providers/appdataprovider';
import routePaths from 'routes/paths';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const TicketHeader = (props) => {
  const { ticket, setTicket, forwardAction = () => {}, revertTicketAction = () => {} } = props;
  const toast = useToast();
  const { user, userType } = useAuth();
  const { setShowHeader } = useAppData();
  const { tickets, setTickets } = useUserData();
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ticketDetails, setTicketDetails] = useState(null);
  const [ticketStatusRef, setTicketStatusRef] = useState(null);
  const [membersRef, setMembersRef] = useState(null);
  const [menuRef, setMenuRef] = useState(null);
  const [isShownTicketStatusModal, setIsShownTicketStatusModal] = useState(false);
  const [activeOptions, setActiveOptions] = useState([]);
  const [previousStatusOfTicket, setPreviousStatusOfTicket] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [alertModal, setAlertModal] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [taskModal, setTaskModdal] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  const [comment, setComment] = useState('');
  const [membersModal, setMembersModal] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [membersLoader, setMembersLoader] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(strings.canView);

  useEffect(() => {
    if (ticket) {
      setTicketDetails(ticket);
    }
  }, [ticket]);

  useEffect(() => {
    if (
      ticket?.ticket_status === 'In review*' ||
      ticket?.ticket_status === 'In review' ||
      ticket?.ticket_status === 'Closed'
    ) {
      setOptions(ticketAdminOptions);
    } else {
      setOptions(ticketCommonOptions);
    }
    handleAlreadyUpddatedStatus();
  }, [ticket]);

  useEffect(() => {
    handleOptions();
  }, [previousStatusOfTicket]);

  const handleTicketAccess = () => {
    let findUser = ticketDetails?.ticket_users.find((item) => item.user._id === user._id);
    if (findUser && findUser.can_edit) {
      return true;
    } else {
      return false;
    }
  };

  const handleTicketStatusModal = () => {
    if (userType === account_types.super_admin && handleTicketAccess()) {
      setIsShownTicketStatusModal(true);
    } else if (userType === account_types.admin && handleTicketAccess()) {
      setIsShownTicketStatusModal(true);
    } else if (userType === account_types.employee && handleTicketAccess()) {
      if (
        ticketDetails?.ticket_status === 'Assigned' ||
        ticketDetails?.ticket_status === 'In progress'
      ) {
        setIsShownTicketStatusModal(true);
      }
    }
  };

  const handleOptions = () => {
    if (previousStatusOfTicket.length > 0) {
      previousStatusOfTicket.map((item) => {
        if (item === 'Assign') {
          setActiveOptions(['Working']);
        } else if (item === 'Working') {
          setActiveOptions(['Completed', "Can't solve"]);
        } else if (item === 'Completed' || item === "Can't solve") {
          setActiveOptions(['Resolved', 'Revert']);
        } else if (item === 'Resolved') {
          setActiveOptions([]);
        }
      });
    }
  };

  const handleAlreadyUpddatedStatus = async () => {
    if (ticket?.ticket_status === 'Assigned') {
      setPreviousStatusOfTicket(['Assign']);
    } else if (ticket?.ticket_status === 'In progress') {
      setPreviousStatusOfTicket(['Assign', 'Working']);
    } else if (ticket?.ticket_status === 'In review') {
      setPreviousStatusOfTicket(['Assign', 'Working', 'Completed']);
    } else if (ticket?.ticket_status === 'In review*') {
      setPreviousStatusOfTicket(['Assign', 'Working', "Can't solve"]);
    } else if (ticket?.ticket_status === 'Closed') {
      setPreviousStatusOfTicket(['Resolved']);
    }
  };

  const getBgClolor = (type) => {
    if (type === 'Raise') {
      return styles.pinkBgStyle;
    } else if (type === 'Forward') {
      return styles.grayBgStyle;
    } else if (type === 'Assign') {
      return styles.primaryBgStyle;
    } else if (type === 'Working' || type === 'Revert') {
      return styles.greenBgStyle;
    } else if (type === 'Completed') {
      return styles.orangeBgStyle;
    } else if (type === "Can't solve") {
      return styles.redBgStyle;
    } else if (type === 'Resolved') {
      return styles.blackBgStyle;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const alertMsgHandleer = () => {
    let message = '';
    if (selectedStatus === 'Completed') {
      message = strings.ticketCompletedAlertMsg.replace('{status}', selectedStatus);
    } else if (selectedStatus === "Can't solve") {
      message = strings.ticketCannotSolveAlertMsg;
    } else if (selectedStatus === 'Resolved') {
      message = strings.ticketResolveAlertMsg;
    } else if (selectedStatus === 'Revert') {
      message = strings.ticketRevertAlertMsg;
    }
    return message;
  };

  const handleChecked = (value) => {
    if (value === 'Working') {
      markAsWorking();
    } else {
      setAlertModal(true);
    }
  };

  const handleAlertModalActions = () => {
    if (selectedStatus === 'Completed') {
      setAlertModal(false);
      setTaskModdal(true);
    } else if (selectedStatus === "Can't solve") {
      setAlertModal(false);
      setTaskModdal(true);
    } else if (selectedStatus === 'Resolved') {
      markAsResolved();
    } else if (selectedStatus === 'Revert') {
      setAlertModal(false);
      setSelectedStatus('');
      revertTicketAction();
    }
  };

  const handleTaskAction = () => {
    if (selectedStatus === 'Completed') {
      markAsCompleted();
    } else if (selectedStatus === "Can't solve") {
      markAsCannotSolve();
    }
  };

  // update user permission
  const updateUserPermission = async (data) => {
    let permissionData = {};
    if (data.permition === 'Can edit') {
      permissionData = {
        user: data.userId,
        ticket: ticketDetails?._id,
        can_edit: true,
        can_view: false,
      };
    } else {
      permissionData = {
        user: data.userId,
        ticket: ticketDetails?._id,
        can_edit: false,
        can_view: true,
      };
    }
    setMembersLoader(true);
    try {
      let response = await updateUserTicketAccessApi(permissionData);
      if (response.data.type === 'success') {
        setTicket((prev) => {
          return { ...prev, ...response.data.data };
        });
        setMembersLoader(false);
        toast.success(response.data.message);
      } else {
        setMembersLoader(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setMembersLoader(false);
      toast.error(error.message);
    }
  };

  // invite member
  const inviteMembers = async () => {
    setMembersLoader(true);
    let data = { ticket: ticketDetails?._id, users: [] };
    selectedMembers.map((item) => {
      let data1 = {
        user: item,
        can_view: true,
        can_edit: selectedPermission === 'Can edit' ? true : false,
      };
      data.users.push(data1);
    });
    try {
      let response = await inviteTicketMembersApi(data);
      if (response.data.type === 'success') {
        setTicket({ ...ticketDetails, ...response.data.data });
        setTickets((prev) => {
          let previous_data = [...prev];
          let findIndex = previous_data.findIndex((item) => item._id === response.data.data._id);
          if (findIndex !== -1) {
            previous_data[findIndex].ticket_users = response.data.data.ticket_users;
          }
          return previous_data;
        });
        setSelectedMembers([]);
        setSelectedPermission('Can view');
        setMembersLoader(false);
        toast.success(response.data.message);
      } else {
        setMembersLoader(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setMembersLoader(false);
      toast.error(error.message);
    }
  };

  // mark as working api
  const markAsWorking = async () => {
    setLoader(true);
    try {
      let data = {
        ticket: ticketDetails._id,
      };
      let response = await markAsWorkingApi(data);
      if (response.data.type === 'success') {
        setTicket({
          ...ticketDetails,
          activity: response.data.data.activity,
          ticket_status: 'In progress',
        });
        let previous_data = [...tickets];
        let ticketIndex = previous_data.findIndex((data) => data._id === ticketDetails._id);
        previous_data[ticketIndex].ticket_status = 'In progress';
        previous_data[ticketIndex].last_activity = response.data.data.activity[0];
        setTickets(previous_data);
        setLoader(false);
        setSelectedStatus('');
        setIsShownTicketStatusModal(false);
        toast.success(response.data.message);
      } else {
        setLoader(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  };

  // mark as completed api
  const markAsCompleted = async () => {
    setTaskLoader(true);
    try {
      let data = {
        ticket: ticketDetails._id,
        image: uploadedImg,
        note: comment,
      };
      let response = await markAsCompletedApi(data);
      if (response.data.type === 'success') {
        setTicket({
          ...ticketDetails,
          activity: response.data.data.activity,
          ticket_status: 'In review',
        });
        let previous_data = [...tickets];
        let ticketIndex = previous_data.findIndex((data) => data._id === ticketDetails._id);
        previous_data[ticketIndex].ticket_status = 'In review';
        previous_data[ticketIndex].last_activity = response.data.data.activity[0];
        setTickets(previous_data);
        setTaskModdal(false);
        setTaskLoader(false);
        setComment('');
        setUploadedImg('');
        setIsShownTicketStatusModal(false);
        toast.success(response.data.message);
      } else {
        setTaskModdal(false);
        setTaskLoader(false);
        toast.errorr(response.data.message);
      }
    } catch (error) {
      setTaskModdal(false);
      setTaskLoader(false);
      toast.error(error.message);
    }
  };

  // mark as Can not Solve  api
  const markAsCannotSolve = async () => {
    setTaskLoader(true);
    try {
      let data = {
        ticket: ticketDetails._id,
        image: uploadedImg,
        note: comment,
      };
      let response = await markAsCannotSolveApi(data);
      if (response.data.type === 'success') {
        setTicket({
          ...ticketDetails,
          activity: response.data.data.activity,
          ticket_status: 'In review*',
        });
        let prev_data = [...tickets];
        let ticketIndex = prev_data.findIndex((data) => data._id === ticketDetails._id);
        prev_data[ticketIndex].ticket_status = 'In review*';
        prev_data[ticketIndex].last_activity = response.data.data.activity[0];
        setTickets(prev_data);
        setTaskModdal(false);
        setTaskLoader(false);
        setComment('');
        setUploadedImg('');
        setIsShownTicketStatusModal(false);
        toast.success(response.data.message);
      } else {
        setTaskModdal(false);
        setTaskLoader(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setTaskModdal(false);
      setTaskLoader(false);
      toast.error(error.message);
    }
  };

  // mark as Resolved api
  const markAsResolved = async () => {
    try {
      let data = {
        ticket: ticketDetails._id,
      };
      let response = await markAsResolvedApi(data);
      if (response.data.type === 'success') {
        setTicket({
          ...ticketDetails,
          activity: response.data.data.activity,
          ticket_status: 'Closed',
        });
        let prev_data = [...tickets];
        let ticketIndex = prev_data.findIndex((data) => data._id === ticketDetails._id);
        prev_data[ticketIndex].ticket_status = 'Closed';
        prev_data[ticketIndex].last_activity = response.data.data.activity[0];
        prev_data[ticketIndex].updated_at = new Date();
        setTickets(prev_data);
        setAlertModal(false);
        setIsShownTicketStatusModal(false);
        toast.success(response.data.message);
      } else {
        setAlertModal(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      setAlertModal(false);
    }
  };

  //----ui section -----

  const renderHeaderLeftSection = () => {
    return (
      <div className={styles.headerSubWrapperStyle}>
        <Image
          onClick={() => {
            navigate(routePaths.ticketList);
            setShowHeader(true);
          }}
          src={chevronLeftBlackIcon}
          containerStyle={styles.backIconStyle}
        />
        <div className={styles.headerLeftWrapperStyle}>
          <p className={styles.headerTextStyle}>#{ticketDetails?.ticket_uid}</p>
          {ticketDetails?.is_high_priority && (
            <Image
              src={priorityIcon}
              // containerStyle={styles.backIconStyle}
            />
          )}
          <div className={styles.statusViewStyle} ref={setTicketStatusRef}>
            <Button
              title={ticketDetails?.ticket_status}
              rightIcon={
                ticketDetails?.ticket_status !== 'Complaint raised'
                  ? chevronDownFillBlackIcon
                  : null
              }
              rightIconStyle={styles.dropDownIconStyle}
              variant="light"
              btnStyle={classNames(
                styles.headerBtnStyle,
                ticketDetails?.ticket_status === 'Complaint raised' && styles.noActionVtnStyle
              )}
              onClick={() => {
                ticketDetails?.ticket_status !== 'Complaint raised' && handleTicketStatusModal();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderHeaderRightSection = () => {
    return (
      <div className={styles.headerRightWrapperStyle}>
        <AvatarGroup
          data={ticketDetails?.ticket_users?.map((u) => ({
            label: u.user.full_name,
            src: u.user.image,
          }))}
          onClick={() => setMembersModal(true)}
          reference={setMembersRef}
        />
        {userType !== account_types.employee &&
          ticketDetails?.ticket_status === 'Complaint raised' &&
          handleTicketAccess() && (
            <Image
              src={optionsVerticalBlackIcon}
              alt="I"
              onClick={() => setMenuModal(true)}
              containerStyle={styles.menuIconStyle}
              reference={setMenuRef}
            />
          )}
      </div>
    );
  };

  const renderMembersModal = () => {
    return (
      <PopOver
        showOverlay={true}
        show={membersModal}
        reference={membersRef}
        // onClose={() => setMembersModal(false)}
        containerStyle={styles.membersModalViewStyle}
      >
        <Members
          type="ticket"
          members={ticketDetails?.ticket_users}
          selectedMembers={selectedMembers}
          setMembers={setSelectedMembers}
          permission={selectedPermission}
          setPermission={setSelectedPermission}
          closeAction={() => setMembersModal(false)}
          loader={membersLoader}
          inviteAction={() => inviteMembers()}
          updatePermissionAction={(data) => updateUserPermission(data)}
          actionStatus={ticketDetails?.ticket_status === 'Closed' ? false : true}
        />
      </PopOver>
    );
  };

  const renderTicketStatusModal = () => {
    return (
      <PopOver
        showOverlay={true}
        show={isShownTicketStatusModal}
        reference={ticketStatusRef}
        onClose={() => !alertModal && !taskModal && setIsShownTicketStatusModal(false)}
        containerStyle={styles.ticketStatusOptionsViewStyle}
      >
        <div className={styles.ticketStatusContainerStyle}>
          {loader && <Loader />}
          <div className={styles.deviderStyle} />
          {options.map((option, index) => (
            <React.Fragment key={index}>
              {option.userTypes.includes(user.account_type) && (
                <div
                  className={[
                    styles.optionViewStyle,
                    (option.status === 'Completed' ||
                      option.status === "Can't solve" ||
                      option.status === 'Resolved' ||
                      option.status === 'Revert') &&
                      styles.optioViewStyle2,
                    selectedStatus === option.status && styles.selectedOptionViewStyle,
                    !activeOptions.includes(option.status) && styles.disabledViewStyle,
                  ].join(' ')}
                >
                  <div className={styles.detailsViewStyle}>
                    <p className={styles.titleTextStyle}>{option.title}</p>
                    <p className={styles.descTextStyle}>{option.desc}</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className={[
                        styles.customCheckBoxStyle,
                        (selectedStatus === option.status ||
                          previousStatusOfTicket.includes(option.status)) &&
                          getBgClolor(option.status),
                      ].join(' ')}
                      checked={
                        selectedStatus === option.status ||
                        previousStatusOfTicket.includes(option.status)
                      }
                      disabled={!activeOptions.includes(option.status)}
                      onChange={(e) => {
                        selectedStatus === option.status
                          ? setSelectedStatus('')
                          : setSelectedStatus(option.status);
                        handleChecked(option.status);
                      }}
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
          {renderAlertModal()}
        </div>
      </PopOver>
    );
  };

  const renderMenuOptionsModal = () => {
    return (
      <PopOver
        showOverlay={true}
        show={menuModal}
        reference={menuRef}
        onClose={() => setMenuModal(false)}
        containerStyle={styles.menuOptionsModalViewStyle}
      >
        <div className={styles.menuWrapperStyle}>
          <div className={styles.deviderStyle} />
          {ticketMenuOptions.map((item, index) => (
            <div onClick={forwardAction} key={index} className={styles.menuOptionViewStyle}>
              <div className={styles.menuOptionSubViewStyle}>
                <Avatar src={item.icon} alt="Icon" containerStyle={styles.menuOptionIconStyle} />
                <p className={styles.menuOptionTextStyle}>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </PopOver>
    );
  };

  const renderAlertModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={alertModal}
        overlayStyle={styles.overlayViewStyle}
        containerStyle={styles.alertModalViewStyle}
      >
        <SuccessCard
          title={
            selectedStatus === 'Resolved' || selectedStatus === 'Revert'
              ? strings.ticketAlertMsg
                  .replace('{ticket_uid}', ticketDetails?.ticket_uid)
                  .replace('{status}', selectedStatus)
              : strings.warning
          }
          description={alertMsgHandleer()}
          leftBtnTitle={strings.cancel}
          cardStyle={styles.alertBoxStyle}
          cardBtnStyle={styles.alertBoxBtnsStyle}
          leftBtnStyle={styles.alertBoxLeftBtnStyle}
          leftAction={() => {
            setAlertModal(false);
            setSelectedStatus('');
            // navigate(routePaths.dashboard);
          }}
          rightBtnTitle={
            selectedStatus === 'Resolved' || selectedStatus === 'Revert' ? 'Yes, proceed' : 'Yes'
          }
          rightAction={() => handleAlertModalActions(false)}
          rightBtnStyle={classNames(styles.alertBoxRightBtnStyle, getBgClolor(selectedStatus))}
        />
      </Modal>
    );
  };

  const renderTaskModal = () => {
    return (
      <Modal show={taskModal} showOverlay={true} containerStyle={styles.taskModalViewStyle}>
        {taskLoader && <Loader />}
        <div className={styles.taskModalStyle}>
          <div className={styles.taskModalTopSectionStyle}>
            <div className={styles.taskModalHeaderStyle}>
              <p className={styles.alertModalTitleTextStyle}>
                {selectedStatus === 'Completed'
                  ? 'Task completed'
                  : selectedStatus === "Can't solve"
                  ? 'Cannot solve'
                  : 'Revert to working'}
              </p>
              <div
                onClick={() => {
                  setTaskModdal(false);
                  setSelectedStatus('');
                  setUploadedImg('');
                }}
                className={styles.closeIconViewStyle}
              >
                <img src={closeIcon} alt="close" className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.taskMoadlDetailsViewStyle}>
              <div className={styles.taskMsgViewStyle}>
                <p className={styles.msgTextStyle}>
                  {selectedStatus === 'Completed'
                    ? strings.ticketCompletedNote
                    : selectedStatus === "Can't solve"
                    ? strings.ticketCannotSolveNote
                    : strings.ticketOtherNote}
                </p>
              </div>
              {(selectedStatus === 'Completed' || selectedStatus === "Can't solve") && (
                <ImageUpload
                  label={strings.uploadImgRequireLable}
                  placeholder={strings.uploadImgPlaceholder}
                  image={uploadedImg}
                  onSuccess={(img) => setUploadedImg(img)}
                />
              )}
              <Input
                type="textarea"
                labelText={strings.addComment}
                placeholder={strings.addCommentPlaceholder}
                value={comment}
                inputLabelStyle={styles.addCommentLabelStyle}
                inputStyle={styles.textAreaViewStyle}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.taskModalBtnViewStyle}>
            <Button
              title={strings.cancel}
              variant="light"
              onClick={() => {
                setTaskModdal(false);
                setSelectedStatus('');
                setUploadedImg('');
                setComment('');
              }}
              btnStyle={styles.taskBtnViewStyle}
            />
            <Button
              title={strings.proceed}
              onClick={() => handleTaskAction()}
              disabled={selectedStatus === 'Revert' ? false : uploadedImg ? false : true}
              btnStyle={styles.taskBtnViewStyle}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.headerWrapperStyle}>
      {renderHeaderLeftSection()}
      {renderHeaderRightSection()}
      {renderTicketStatusModal()}
      {renderTaskModal()}
      {renderMembersModal()}
      {renderMenuOptionsModal()}
    </div>
  );
};

TicketHeader.propTypes = {
  ticket: PropTypes.object,
  setTicket: PropTypes.func,
  forwardAction: PropTypes.func,
  revertTicketAction: PropTypes.func,
};

export default TicketHeader;
