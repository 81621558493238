import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { closeLightGrayIcon, uploadImgIcon } from 'resources/images';
import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';
import Button from 'components/common/button';
import Image from 'components/common/image';
import CircularProgressBar from 'components/common/circular-progress-bar';
import styles from './styles.module.css';

const MultiFileUpload = (props) => {
  const { files = [], setFiles, setFilesUploading = () => {}, customListItemStyle } = props;

  const [selectedFiles, setSelectedFiles] = useState(files);
  const [actionLoading, setActionLoading] = useState(false);
  const [uploadPercentages, setUploadPercentages] = useState(files.map((item) => 100));
  const fileInputRef = useRef();

  useEffect(() => {
    setSelectedFiles(files);
  }, [files, setFiles]);
  useEffect(() => {
    setUploadPercentages(files.map((item) => 100));
  }, [files]);
  useEffect(() => {
    setFilesUploading(actionLoading);
  }, [actionLoading]);

  const handleUploadFiles = async (data) => {
    setActionLoading(true);
    try {
      const promises = Array.from(data).map((file) => {
        const formData = new FormData();
        [file].forEach((file) => formData.append('files', file));
        return new Promise((resolve, reject) => {
          const config = {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (uploadPercentages) {
                setUploadPercentages((prevProgress) => {
                  const newProgress = [...prevProgress];
                  newProgress[files.length + data.indexOf(file)] = progress;
                  return newProgress;
                });
              } else {
                setUploadPercentages((prevProgress) => {
                  const newProgress = [...prevProgress];
                  newProgress[data.indexOf(file)] = progress;
                  return newProgress;
                });
              }
            },
          };
          axios
            .post(endPoints.uploadFiles, formData, config)
            .then((response) => {
              resolve(response.data.data[0]);
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        });
      });
      Promise.all(promises)
        .then((results) => {
          if (files) {
            setFiles(files.concat(results));
            setActionLoading(false);
          } else {
            setFiles(results);
            setActionLoading(false);
          }
          fileInputRef.current.value = '';
        })
        .catch((error) => {
          console.error('Error uploading files:', error);
          setActionLoading(false);
        });
    } catch (error) {
      console.log('error', error);
      setActionLoading(false);
    }
  };

  const handleSelectFiles = (e) => {
    let filesUploaded;
    if (selectedFiles) {
      const data = e.target.files;
      let fileNames = [];
      // Iterate through the selected files and store their names in the array
      for (let i = 0; i < data.length; i++) {
        fileNames.push(data[i]);
      }
      filesUploaded = selectedFiles.concat(fileNames);
      setFiles(files.concat(fileNames));
      handleUploadFiles(fileNames);
    } else {
      const data = e.target.files;
      let fileNames = [];
      // Iterate through the selected files and store their names in the array
      for (let i = 0; i < data.length; i++) {
        fileNames.push(data[i]);
      }
      filesUploaded = fileNames;
      setFiles(fileNames);
      handleUploadFiles(filesUploaded);
    }
  };

  const handleRemoveFile = (indexNo) => {
    let filteredFiles = selectedFiles.filter((file, index) => index !== indexNo);
    let filteredProgresses = uploadPercentages.filter((file, index) => index !== indexNo);
    setUploadPercentages(filteredProgresses);
    setFiles(filteredFiles);
    setSelectedFiles(filteredFiles);
  };

  return (
    <div className={styles.containerStyle}>
      <div className={styles.uploadBlockStyle}>
        <Button
          title={'Upload files'}
          leftIcon={uploadImgIcon}
          variant="light"
          size="md"
          onClick={() => fileInputRef.current?.click()}
          disabled={actionLoading}
        />
        <input
          type="file"
          name="files[]"
          multiple
          accept="image/jpeg, image/jpg, image/png,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
            text/plain, application/pdf,application/zip"
          placeholder="Upload files"
          ref={fileInputRef}
          onChange={handleSelectFiles}
          hidden
        />
        <div className={styles.uploadBlockTextViewStyle}>
          <p className={styles.titleStyle}>
            <span className={styles.descStyle}>Files supported:&nbsp;</span>
            JPEG, PNG, DOCX, XLS, PDF
          </p>
          <p className={styles.titleStyle}>
            <span className={styles.descStyle}>Max file size:&nbsp;</span>
            50MB
          </p>
        </div>
      </div>
      <div className={styles.fileListStyle}>
        {selectedFiles.map((item, index) => {
          return (
            <div
              key={'file' + index}
              className={classNames(styles.listItemStyle, customListItemStyle)}
            >
              <div className={styles.listItemViewStyle}>
                <p className={styles.listItemTitleStyle}>{item?.file_name || item?.name}</p>
                <p className={styles.listItemDescStyle}>
                  {item?.file_ext || item?.type} •{' '}
                  {`${((item.file_size || item.size) / 1024 / 1024).toFixed(2)} MB`}
                </p>
              </div>
              {uploadPercentages[index] === 100 ? (
                <Image
                  src={closeLightGrayIcon}
                  containerStyle={styles.closeIconViewStyle}
                  onClick={() => handleRemoveFile(index)}
                />
              ) : (
                <CircularProgressBar
                  size={20}
                  progress={uploadPercentages[index] || 0}
                  trackColor="#fff"
                  indicatorColor={'#236bfe'}
                  trackWidth={3}
                  indicatorWidth={3}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

MultiFileUpload.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  setFilesUploading: PropTypes.func,
  customListItemStyle: PropTypes.string,
};

export default MultiFileUpload;
