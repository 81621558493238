import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import routePaths from 'routes/paths';
import { assetIcon, chevronDownFillBlackIcon } from 'resources/images';
import { account_types } from 'resources/data';
import AvatarInfoCard from 'components/common/avatar-info-card';
import Button from 'components/common/button';
import PopOver from 'components/common/pop-over';
import styles from './styles.module.css';

const RightSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userType } = useAuth();
  const { users, tickets, assets } = useUserData();
  const { width } = useWindowDimensions();
  const options = { assets: 'Assets', users: 'Users' };
  const [selectedOption, setSelectedOption] = useState(options.assets);
  const [optionPopUp, setOptionPopUp] = useState(false);
  const [optionRef, setOptionRef] = useState(null);

  const handleTicketSubject = (data) => {
    let msg = '';
    if (data.ticket_status === 'Complaint raised') {
      msg = `${
        data?.created_by
          ? data.created_by.full_name + (data.created_by._id === user?._id ? ' (You)' : '')
          : 'Guest user'
        // : data.created_by_guest.full_name
      } has raised a complaint with subject “${data.subject}”`;
    } else if (data.ticket_status === 'Assigned') {
      msg = `${
        data.last_activity.action_by.full_name +
        (data.last_activity.action_by._id === user?._id ? ' (You)' : '')
      } assigned the ticket to ${
        data.last_activity.users.length > 0 && data.last_activity.users[0].full_name
      }`;
    } else {
      msg = `${
        data.last_activity.action_by.full_name +
        (data.last_activity.action_by._id === user?._id ? ' (You)' : '')
      } changed the task status to "${data.last_activity.type}"`;
    }
    return msg;
  };

  const renderTicketBlock = () => {
    return (
      <div className={styles.blockStyle}>
        <div className={styles.blockHeaderStyle}>
          <p className={styles.blockHeaderTitleStyle}>Tickets</p>
        </div>
        {tickets.length > 0 ? (
          <React.Fragment>
            {' '}
            <div className={styles.blockListStyle}>
              {tickets
                .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                .slice(0, 4)
                .map((ticket, index) => {
                  let title = handleTicketSubject(ticket);
                  return (
                    <AvatarInfoCard
                      key={'ticket' + index}
                      icon={ticket?.image || ticket?.asset?.image}
                      title={title}
                      description={moment(ticket.updated_at).fromNow()}
                      containerStyle={styles.blockListItemStyle}
                      avatarStyle={styles.blockListItemIconStyle}
                      titleStyle={styles.blockListItemTitleStyle}
                      onClick={() => {
                        navigate(routePaths.ticketList);
                      }}
                    />
                  );
                })}
            </div>
            <NavLink to={routePaths.ticketList} className={styles.blockLinkStyle}>
              View all tickets
            </NavLink>
          </React.Fragment>
        ) : (
          <p className={styles.noDataTextStyle}>No data found</p>
        )}
      </div>
    );
  };

  const renderRecentItemsBlock = () => {
    return (
      <div className={styles.blockStyle}>
        <div className={styles.blockHeaderStyle}>
          <p className={styles.blockHeaderTitleStyle}>Recently added</p>
          <Button
            title={selectedOption}
            rightIcon={chevronDownFillBlackIcon}
            btnStyle={styles.selectBtnStyle}
            variant="light"
            onClick={() => userType !== account_types.employee && setOptionPopUp(true)}
            reference={setOptionRef}
          />
          <PopOver
            reference={optionRef}
            show={optionPopUp}
            containerStyle={styles.selectPopUpStyle}
            onClose={() => setOptionPopUp(false)}
            placement="bottom-end"
          >
            {Object.values(options).map((opt, index) => {
              return (
                <div
                  key={'option' + index}
                  className={classNames(
                    styles.optionStyle,
                    selectedOption === opt ? styles.selectedOptionStyle : null
                  )}
                  onClick={() => {
                    setSelectedOption(opt);
                    setOptionPopUp(false);
                  }}
                >
                  <p className={styles.optionTextStyle}>{opt}</p>
                </div>
              );
            })}
          </PopOver>
        </div>
        {users.length > 0 || assets.length > 0 ? (
          <React.Fragment>
            <div className={styles.blockListStyle}>
              {selectedOption === options.assets &&
                assets
                  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                  .slice(0, 4)
                  .map((asset, index) => {
                    return (
                      <AvatarInfoCard
                        key={'asset' + index}
                        icon={asset.image || assetIcon}
                        title={asset.asset_name}
                        description={moment(asset.created_at).fromNow()}
                        containerStyle={styles.blockListItemStyle}
                        avatarStyle={styles.blockListItemAssetIconStyle}
                        titleStyle={styles.blockListItemTitleStyle}
                        onClick={() => navigate(routePaths.assetDetails(asset._id))}
                      />
                    );
                  })}
              {selectedOption === options.users &&
                users
                  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                  .slice(0, 4)
                  .map((usr, index) => {
                    return (
                      <AvatarInfoCard
                        key={'usr' + index}
                        icon={usr.image}
                        title={usr.full_name}
                        description={moment(usr.created_at).fromNow()}
                        containerStyle={styles.blockListItemStyle}
                        avatarStyle={styles.blockListItemUserIconStyle}
                        titleStyle={styles.blockListItemTitleStyle}
                        onClick={() => navigate(routePaths.employeeDetails(usr._id))}
                      />
                    );
                  })}
            </div>
            <NavLink
              to={
                selectedOption === options.assets ? routePaths.assetList : routePaths.employeeList
              }
              className={styles.blockLinkStyle}
            >
              View all{' '}
              {selectedOption === options.users
                ? options.users.toLowerCase()
                : options.assets.toLowerCase()}
            </NavLink>
          </React.Fragment>
        ) : (
          <p className={styles.noDataTextStyle}>No data found</p>
        )}
      </div>
    );
  };

  if (![routePaths.dashboard].includes(location.pathname) || width <= 991) {
    return null;
  }

  return (
    <div className={styles.rightSideBarWrapperStyle}>
      {renderTicketBlock()}
      {renderRecentItemsBlock()}
    </div>
  );
};

export default RightSideBar;
