import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const CircularProgressBar = (props) => {
  let {
    size = 24,
    progress = 0,
    trackWidth = 4,
    trackColor = `#e8e8e8`,
    indicatorWidth = 4,
    indicatorColor = `#236bfe`,
    indicatorCap = `round`,
    label = null,
    labelColor = `#1e1e20`,
    spinnerMode = false,
    spinnerSpeed = 1,
  } = props;

  const center = size / 2,
    radius = (size - indicatorWidth) / 2,
    viewBox = `0 0 ${size} ${size}`,
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  //   const center = size / 2,
  //     radius = (size - indicatorWidth) / 2,
  //     viewBox = `0 0 ${size} ${size}`,
  //     dashArray = 2 * Math.PI * radius,
  //     dashOffset = dashArray - (dashArray * progress) / 100;

  let hideLabel = size < 100 || !label.length || spinnerMode ? true : false;

  return (
    <div className={styles.containerStyle} style={{ width: size, height: size }}>
      <svg className={styles.svgStyle} style={{ width: size, height: size }} viewBox={viewBox}>
        <circle
          className={styles.svgTrackStyle}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={trackColor}
          strokeWidth={trackWidth}
        />
        <circle
          className={classNames(
            styles.svgIndicatorStyle,
            spinnerMode && styles.svgIndicatorSpinnerStyle
          )}
          style={{ animationDuration: spinnerSpeed * 1000 }}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={indicatorColor}
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={indicatorCap}
        />
      </svg>

      {/* {label && (
        <div className={styles.labelStyle} style={{ color: labelColor }}>
          <span className={styles.labelLoadingStyle}>{label}</span>
          {!spinnerMode && (
            <span className={styles.labelProgressStyle}>
              {`${progress > 100 ? 100 : progress}%`}
            </span>
          )}
        </div>
      )} */}
      {props.children}
    </div>
  );
};

CircularProgressBar.propTypes = {
  size: PropTypes.number,
  progress: PropTypes.number,
  trackWidth: PropTypes.number,
  trackColor: PropTypes.string,
  indicatorWidth: PropTypes.number,
  indicatorColor: PropTypes.string,
  indicatorCap: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  spinnerMode: PropTypes.bool,
  spinnerSpeed: PropTypes.number,
};

export default CircularProgressBar;
