import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppData } from 'providers/appdataprovider';
import { useStrings } from 'providers/stringsprovider';
import {
  employeeDeleteApi,
  employeeUpdateApi,
  getemployeeDetailsWithAssetsApi,
  getUserReminderListApi,
} from 'networking/api/employee';
import Loader from 'components/common/loader';
import Button from 'components/common/button';
import Avatar from 'components/common/avatar';
import {
  arrowLeftIcon,
  assetGrayIcon,
  assetIcon,
  attachmentIcon,
  chevronDownFillBlackIcon,
  chevronDownFillBlueIcon,
  chevronRightPurpleIcon,
  closeIcon,
  deleteBlackIcon,
  historyGaryIcon,
  historyIcon,
  infoCircleIcon,
  infoCircleUpFillGrayIcon,
  multiSelectIcon,
  multiselectPurpleIcon,
  optionsGrayIcon,
  optionsIcon,
  pencilEditBlackIcon,
  ticketsGrayIcon,
  ticketsIcon,
} from 'resources/images';
import moment from 'moment';
import PopOver from 'components/common/pop-over';
import Modal from 'components/common/modal';
import AssignAssetCard from 'components/assign-asset-card';
import { socket } from 'providers/socketprovider';
import {
  updateUserDataWhenAssetAssignFunc,
  updateUserDataWhenAssetToAnotherFunc,
  updateUserDataWhenAssetUnAssignFunc,
} from 'functions';
import { assetAssignToOthersApi, assetUnAssignApi } from 'networking/api/asset';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Image from 'components/common/image';
import AssetAndUserCard from 'components/asset&user-card';
import Capsule from 'components/common/capsule';
import InfoCard from 'components/info-card';
import StatisticsCard from 'components/statistics-card';
import { useUserData } from 'providers/userdataprovider';
import UserTickets from 'sections/employee/user-tickets';
import UserClusters from 'sections/employee/user-clusters';
import EditEmployee from 'sections/employee/edit-employee';
import routePaths from 'routes/paths';
import AddEditReminder from 'sections/assets/add-edit-reminder';
import Reminders from 'sections/assets/reminders';
import classNames from 'classnames';
import { useAuth } from 'providers/authprovider';
import { account_types, reminderActionTypes } from 'resources/data';
import Attachments from 'sections/assets/attachments';
import MultiFileUpload from 'components/multi-file-upload';
import AllRemainders from 'sections/assets/all-reminders';
import { useSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const EmployeeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { width } = useWindowDimensions();
  const { userType, user } = useAuth();
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const { strings } = useStrings();
  const { assets, tickets, clusters, users, setUsers } = useUserData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isShowActionOptions, setIsShowActionOptions] = useState(false);
  const [pickerRef, setPickerRef] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [isShowAssignAssetModal, setIsShowAssignAssetModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showCurrentlyAssign, setShowCurrentlyAssign] = useState(true);
  const [showPreviouslyAssign, setShowPreviouslyAssign] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Overview');
  const [assignedAssets, setAssignedAssets] = useState([]);
  const [assignedTickets, setAssignedTickets] = useState([]);
  const [assignedClusters, setAssignedClusters] = useState([]);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteEmpErr, setDeleteEmpErr] = useState('');
  const [reminders, setReminders] = useState([]);
  const [remindersLoading, setRemindersLoading] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [isShowAllRemainders, setIsShowAllRemainders] = useState(false);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [isShowAttachmentsModal, setisShowAttachmentsModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [actionLoader, setActionLoader] = useState(false);
  const [attachDoneDisabled, setAttachDoneDisabled] = useState(false);
  const [assignAssetLoader, setAssignAssetLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const [unAssignLoading, setUnAssignLoading] = useState(false);
  const [unAssignError, setUnAssignError] = useState('');
  const [isAttachValuesChanged, setIsAttachValuesChanged] = useState(false);
  const actionOptions = [strings.unAssign, strings.assignToOther];
  const clusterTabs = [
    {
      title: 'Overview',
      icon: selectedTab === 'Overview' ? optionsIcon : optionsGrayIcon,
    },
    {
      title: 'Asset history',
      icon: selectedTab === 'Asset history' ? assetIcon : assetGrayIcon,
    },
    {
      title: 'Tickets',
      icon: selectedTab === 'Tickets' ? ticketsIcon : ticketsGrayIcon,
    },
    {
      title: 'Maintenance',
      icon: selectedTab === 'Maintenance' ? historyIcon : historyGaryIcon,
    },
  ];

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(false);
    }
  }, [width]);

  useEffect(() => {
    setSelectedTab(searchParams.get('selectedTab') || 'Overview');
  }, [searchParams.get('selectedTab')]);

  useEffect(() => {
    getUserDetails();
    getUserReminderList();
    if (userType !== account_types.employee) {
      setUserCanEdit(true);
    }
  }, [id]);

  useEffect(() => {
    setIsAttachValuesChanged(isEqual(userDetails?.attachments, attachments));
    setBreadcrumbs([
      { title: strings.allEmployees, route: routePaths.employeeList },
      { title: userDetails ? `@${userDetails?.full_name}` : 'Loading...', route: '' },
    ]);
  }, [userDetails, attachments, setIsAttachValuesChanged]);

  useEffect(() => {
    // * set assigned assets
    if (assets.length > 0) {
      setAssignedAssets(
        assets?.filter((ass) => {
          if (ass?.assigned_to) {
            return ass.assigned_to._id === userDetails?._id;
          }
        })
      );
    }
    // * set assigned tickets
    let ticData = [];
    tickets.filter((ticket) => {
      if (ticket.ticket_users) {
        ticket.ticket_users.filter((tic) => {
          if (tic.user === userDetails?._id) {
            ticData.push(ticket);
          }
        });
      }
    });
    setAssignedTickets(ticData);
    // * set assigned clusters
    let clusData = [];
    clusters.filter((cluster) => {
      if (cluster.users) {
        cluster.users.filter((clus) => {
          if (clus.user === userDetails?._id) {
            clusData.push(cluster);
          }
        });
      }
    });
    setAssignedClusters(clusData);
  }, [assets, userDetails, tickets]);

  useEffect(() => {
    socketListner();
  }, []);

  useEffect(() => {
    if (isShowAssignAssetModal) {
      document.body.style.overflow = 'hidden';
    }
  }, [isShowAssignAssetModal]);

  const socketListner = () => {
    // asset
    socket.on('asset-assign', (data) => {
      setUserDetails((prev) => {
        let previous_data = { ...prev };
        return updateUserDataWhenAssetAssignFunc(previous_data, data);
      });
    });
    socket.on('asset-assign-other', (data) => {
      setUserDetails((prev) => {
        let previous_data = { ...prev };
        return updateUserDataWhenAssetToAnotherFunc(previous_data, data);
      });
    });
    socket.on('asset-unassign', (data) => {
      setUserDetails((prev) => {
        let previous_data = { ...prev };
        return updateUserDataWhenAssetUnAssignFunc(previous_data, data);
      });
    });
    // employee
    socket.on('user-update', (data) => {
      setUserDetails((prev) => {
        let previous_data = { ...prev };
        if (previous_data._id === data._id) {
          return data;
        } else {
          return previous_data;
        }
      });
    });
    // remainders
    socket.on('user-reminder-create', (data) => {
      setUserDetails((prev) => {
        setReminders((rem) => {
          let previous_data = [...rem];
          if (prev?._id === data.user) {
            let findIndex = previous_data.findIndex((item) => item._id === data._id);
            if (findIndex === -1) {
              previous_data = [{ ...data, status: data?.status || [] }, ...previous_data];
            }
          }
          return previous_data;
        });
        return prev;
      });
    });
    socket.on('user-reminder-update', (data) => {
      setUserDetails((prev) => {
        setReminders((rem) => {
          let previous_data = [...rem];
          if (prev?._id === data.user) {
            let findIndex = previous_data.findIndex((item) => item._id === data._id);
            if (findIndex !== -1) {
              previous_data[findIndex] = data;
            }
          }
          return previous_data;
        });
        return prev;
      });
    });
    socket.on('user-reminder-delete', (data) => {
      setUserDetails((prev) => {
        setReminders((rem) => {
          let previous_data = [...rem];
          if (prev?._id === data.user) {
            previous_data = previous_data.filter((item) => item._id !== data._id);
          }
          return previous_data;
        });
        return prev;
      });
    });
    socket.on('user-reminder-trigger', (data) => {
      setReminders((prev) => {
        let previous_data = [...prev];
        let index = previous_data.findIndex((item) => item._id === data._id);
        if (index !== -1) {
          previous_data[index].status = [...previous_data[index].status, data?.status];
        }
        return previous_data;
      });
    });
  };

  const getUserDetails = async () => {
    setLoader(true);
    try {
      let response = await getemployeeDetailsWithAssetsApi(id);
      if (response.data.type === 'success') {
        setUserDetails(response.data.data);
        setAttachments(response.data.data.attachments);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getUserReminderList = async () => {
    try {
      setRemindersLoading(true);
      let response = await getUserReminderListApi(id);
      if (response.data.type === 'success') {
        setReminders(response.data.data);
        setRemindersLoading(false);
      } else {
        setRemindersLoading(false);
      }
    } catch (error) {
      setRemindersLoading(false);
    }
  };

  const toggleTab = (tab) => {
    setSelectedTab(tab);
    setSearchParams({ selectedTab: tab });
  };

  const tapOnActionOption = (option) => {
    setSelectedOption(option);
    if (option === strings.unAssign) {
      setIsModalOpen(true);
    }
    if (option === strings.assignToOther) {
      setIsShowAssignAssetModal(true);
    }
  };

  const tapOnUnAssign = async () => {
    try {
      setUnAssignLoading(true);
      setUnAssignError('');
      let response = await assetUnAssignApi(selectedAsset?._id);
      if (response.data.type === 'success') {
        let updatedData = updateUserDataWhenAssetUnAssignFunc(userDetails, response.data.data);
        setIsModalOpen(false);
        setUserDetails(updatedData);
        toast.success(response.data.message);
      } else {
        setUnAssignError(response.data.message);
      }
      setUnAssignLoading(false);
    } catch (error) {
      setUnAssignLoading(false);
      setUnAssignError(error.message);
    }
  };

  const assetAssignToOthers = async () => {
    if (selectedUser) {
      try {
        setAssignAssetLoader(true);
        let apiData = {
          asset_id: selectedAsset?._id,
          employee_id: selectedUser,
        };
        let response = await assetAssignToOthersApi(apiData);
        if (response.data.type === 'success') {
          let updatedData = updateUserDataWhenAssetToAnotherFunc(userDetails, response.data.data);
          setUserDetails(updatedData);
          setIsShowAssignAssetModal(false);
          setSelectedUser(null);
          setAssignAssetLoader(false);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
          console.log('asset Assign To Others Api failed', response.data.message);
          setAssignAssetLoader(false);
        }
      } catch (error) {
        toast.error(error.message);
        console.log('asset Assign To Others Api catch', error.response.message);
        setAssignAssetLoader(false);
      }
    }
  };

  const handleDeleteEmployee = async () => {
    try {
      setDeleteLoader(true);
      const response = await employeeDeleteApi(userDetails?._id);
      if (response.data.type === 'success') {
        setDeleteLoader(false);
        let filteredEmployees = users.filter((item) => item._id !== userDetails._id);
        setUsers(filteredEmployees);
        setDeleteModalOpen(false);
        toast.success(response.data.message);
        navigate(routePaths.employeeList);
      } else {
        console.log('Delete Employee api failed', response.data.message);
        setDeleteEmpErr(response.data.message);
        setDeleteLoader(false);
      }
    } catch (error) {
      console.log('get Employe Delete api catch', error);
      setDeleteEmpErr(error);
      setDeleteLoader(false);
    }
  };

  const handleUpdateAttachments = async () => {
    try {
      setActionLoader(true);
      let response = await employeeUpdateApi({
        _id: userDetails?._id,
        ...userDetails,
        assigned_to: userDetails?.assigned_to?._id,
        attachments: attachments.map((item) => item._id),
      });
      if (response.data.type === 'success') {
        setUserDetails(response.data.data);
        setisShowAttachmentsModal(false);
        toast.success('Attachments updated');
      } else {
        setApiError(response.data.message);
      }
      setActionLoader(false);
    } catch (error) {
      setApiError(error.message);
      setActionLoader(false);
    }
  };

  // render methods start -------

  const renderBackOptionSection = () => {
    return (
      <div className={styles.backOptionViewStyle}>
        <Image
          onClick={() => navigate(-1)}
          src={arrowLeftIcon}
          alt="<"
          containerStyle={styles.backArrowStyle}
        />
        <p className={styles.backTextStyle}>Back</p>
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <div className={styles.userInfoSectionStyle}>
        <div className={styles.userInfoViewStyle}>
          <div className={styles.userWrapperStyle}>
            <Avatar
              src={userDetails?.image}
              alt={userDetails?.full_name}
              label={userDetails?.full_name}
              containerStyle={styles.avatarViewStyle}
            />
            <div className={styles.userDetailsViewStyle}>
              <p className={styles.titleTextStyles}>{userDetails?.full_name}</p>
              <p className={styles.descTextStyle}> {userDetails?.email}</p>
            </div>
          </div>
          <Button
            variant="light"
            leftIcon={deleteBlackIcon}
            leftIconStyle={styles.iconStyle}
            btnStyle={styles.optionsBtnStyle}
            onClick={() => setDeleteModalOpen(true)}
          />
        </div>
      </div>
    );
  };

  const renderTabsSection = () => {
    return (
      <div className={styles.tabsWrapperStyle}>
        {clusterTabs.map((tab, index) => (
          <Capsule
            key={index}
            leftIcon={tab.icon}
            label={tab.title}
            containerStyle={
              selectedTab === tab.title ? styles.selectedCapsuleStyle : styles.capsuleViewStyle
            }
            labelStyle={
              selectedTab === tab.title ? styles.selectedLableStyle : styles.capsuleLableStyle
            }
            onClick={() => toggleTab(tab.title)}
          />
        ))}
      </div>
    );
  };

  const renderCurrentAndPreviousAssetsInfo = () => {
    return (
      <div className={styles.userAssetsWrapperStyle}>
        {renderTabsSection()}
        <div className={styles.tabContentViewStyle}>
          {selectedTab === 'Overview' && renderOverViewSection()}
          {selectedTab === 'Asset history' && renderAssetHistory()}
          {selectedTab === 'Tickets' && <UserTickets tickets={assignedTickets} />}
          {selectedTab === 'Maintenance' && <UserClusters clusters={assignedClusters} />}
        </div>
      </div>
    );
  };

  const renderOverViewSection = () => {
    return (
      <div className={styles.overViewContentSectionStyle}>
        {/* Employee Info */}
        <div className={styles.overViewSectionStyle}>
          <div className={styles.overViewTitleWrapperStyle}>
            <p className={styles.overViewTitleTextStyle}>Employee info:</p>
            {(userType === account_types.super_admin ||
              user?._id === userDetails?._id ||
              userDetails?.account_type === account_types.employee) && (
              <div className={styles.overViewActionElementsWrapperStyle}>
                {width > 480 ? (
                  <Button
                    leftIcon={pencilEditBlackIcon}
                    onClick={() => setIsShowEditModal(true)}
                    title="Edit info"
                    variant="light"
                    size="md"
                    btnStyle={styles.overViewBtnStyle}
                    leftIconStyle={styles.overViewBtnLeftIconStyle}
                  />
                ) : (
                  <span
                    className={styles.overViewLinkTextStyle}
                    onClick={() => setIsShowEditModal(true)}
                  >
                    Edit info
                  </span>
                )}
              </div>
            )}
          </div>
          <div className={styles.overViewBodyWrapperStyle}>
            <InfoCard data={userDetails} />
          </div>
        </div>
        {/* Reminders */}
        <div className={styles.overViewSectionStyle}>
          <div className={styles.overViewTitleWrapperStyle}>
            <p className={styles.overViewTitleTextStyle}>Reminders:</p>
            {(userType === account_types.super_admin ||
              user?._id === userDetails?._id ||
              userDetails?.account_type === account_types.employee) && (
              <div className={styles.overViewActionElementsWrapperStyle}>
                {width > 480 && (
                  <Button
                    leftIcon={multiSelectIcon}
                    title="Add reminder"
                    variant="light"
                    size="md"
                    onClick={() => {
                      userCanEdit && setReminderModal(true);
                    }}
                    leftIconStyle={styles.overViewBtnLeftIconStyle}
                    btnStyle={classNames(
                      styles.overViewBtnStyle,
                      !userCanEdit && styles.overViewDisabledBtnStyle
                    )}
                  />
                )}
                {reminders && reminders.length > 0 && (
                  <div className={styles.overViewLinkWrapperStyle}>
                    <span
                      className={classNames(
                        styles.overViewLinkTextStyle,
                        styles.reminderCustomLinkTextStyle
                      )}
                      onClick={() => setIsShowAllRemainders(true)}
                    >
                      View all
                    </span>
                    <Image
                      containerStyle={styles.overViewLinkIconStyle}
                      src={chevronRightPurpleIcon}
                      alt="icon"
                    />
                  </div>
                )}
                {width <= 480 && reminders && reminders.length === 0 && (
                  <span
                    className={classNames(
                      classNames(styles.overViewLinkTextStyle, styles.reminderCustomLinkTextStyle)
                    )}
                    onClick={() => setReminderModal(true)}
                  >
                    Add new
                  </span>
                )}
              </div>
            )}
          </div>
          <div className={styles.overViewBodyWrapperStyle}>
            {remindersLoading ? (
              <Loader />
            ) : (
              <Reminders type="user" reminders={reminders} setReminders={setReminders} />
            )}
          </div>
          {width <= 480 && reminders && reminders.length > 0 && (
            <Button
              title="Add new reminder"
              size="md"
              variant="light"
              leftIcon={multiselectPurpleIcon}
              onClick={() => {
                userCanEdit && setReminderModal(true);
              }}
              btnStyle={styles.reminderCustomBtnStyle}
            />
          )}
        </div>
        {/* Attachments */}
        <div className={styles.overViewSectionStyle}>
          <div className={styles.overViewTitleWrapperStyle}>
            <p className={styles.overViewTitleTextStyle}>Attachments:</p>
            {(userType === account_types.super_admin ||
              user?._id === userDetails?._id ||
              userDetails?.account_type === account_types.employee) && (
              <div className={styles.overViewActionElementsWrapperStyle}>
                {width > 480 && (
                  <Button
                    leftIcon={attachmentIcon}
                    title={
                      userDetails?.attachments.length > 0 ? 'Edit attachments' : 'Add attachments'
                    }
                    variant="light"
                    size="md"
                    leftIconStyle={styles.overViewBtnLeftIconStyle}
                    onClick={() => {
                      userCanEdit && setisShowAttachmentsModal(true);
                    }}
                    btnStyle={classNames(
                      styles.overViewBtnStyle,
                      !userCanEdit && styles.overViewDisabledBtnStyle
                    )}
                  />
                )}
                {width <= 480 && (
                  <span
                    className={classNames(styles.overViewLinkTextStyle)}
                    onClick={() => setisShowAttachmentsModal(true)}
                  >
                    {userDetails?.attachments?.length > 0 ? 'Edit' : 'Add new'}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className={styles.overViewBodyWrapperStyle}>
            <Attachments type="user" data={userDetails?.attachments || []} />
          </div>
        </div>
        {/* Statistics */}
        <div className={styles.overViewSectionStyle}>
          <div className={styles.overViewTitleWrapperStyle}>
            <p className={styles.overViewTitleTextStyle}>Key statistics:</p>
          </div>
          <div className={styles.overViewBodyWrapperStyle}>
            <div className={styles.overViewBodyWrapperContentStyle}>
              <StatisticsCard
                count={assignedAssets.length}
                title={'Assigned assets:'}
                containerStyle={styles.statisticsCardContainerStyle}
                countStyle={styles.countStatisticsStyle}
                onClick={() => toggleTab('Asset history')}
              />
              <StatisticsCard
                count={assignedTickets.length}
                title={'Assigned tickets:'}
                containerStyle={styles.statisticsCardContainerStyle}
                countStyle={styles.countStatisticsStyle}
                onClick={() => toggleTab('Tickets')}
              />
              <StatisticsCard
                count={assignedClusters.length}
                title={'Assigned clusters::'}
                containerStyle={styles.statisticsCardContainerStyle}
                countStyle={styles.countStatisticsStyle}
                onClick={() => toggleTab('Maintenance')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssetHistory = () => {
    return (
      <React.Fragment>
        {assignedAssets.length > 0 ? (
          <div className={styles.userAssetsListWrapperStyle}>
            {userDetails?.currently_assigned.length > 0 && currentlyAssignedSection()}
            {userDetails?.previously_assigned?.length > 0 && previouslyAssignedSection()}
          </div>
        ) : (
          renderEmptyDataSection()
        )}
      </React.Fragment>
    );
  };

  const currentlyAssignedSection = () => {
    return (
      <React.Fragment>
        {width <= 480 ? (
          currentlyAssignedMobileSection()
        ) : (
          <div className={styles.tableSectionStyle}>
            <table className={styles.tableWrapperStyle}>
              <thead>
                <tr className={styles.tableHeaderRowStyle}>
                  <th>{strings.currentlyAssignLabel}</th>
                  <th>{strings.dateAndTime}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userDetails?.currently_assigned.map((item, index) => (
                  <tr key={index} className={styles.tableRowStyle}>
                    <td>
                      <div
                        className={styles.assetIconWrapperStyle}
                        onClick={() => {
                          navigate(routePaths.assetDetails(item._id));
                        }}
                      >
                        <Image
                          src={item.image || assetIcon}
                          containerStyle={styles.assetIconStyle}
                          alt={item.asset_name}
                        />
                        <div className={styles.customColStyle}>
                          <span className={styles.tableTitleTextStyle}>{item.asset_name}</span>
                          <span className={styles.tableSmallTextStyle}>#{item.asset_uid}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={styles.customColStyle}>
                        <span className={styles.tableTitleTextStyle}>
                          {moment(item.assigned_at).format('ll')}
                        </span>
                        <span className={styles.tableSmallTextStyle}>
                          {moment(item.assigned_at).format('LT')}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.actionBtnViewStyle}>
                        <div className={styles.btnStyle}>
                          <Button
                            title={strings.actions}
                            onClick={(e) => {
                              setIsShowActionOptions(true);
                              setSelectedAsset(item);
                              setPickerRef(e.target);
                              e.stopPropagation();
                            }}
                            size="md"
                            btnStyle={styles.actionBtnStyle}
                            rightIcon={chevronDownFillBlueIcon}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  };

  const currentlyAssignedMobileSection = () => {
    return (
      <div className={styles.currentlyAssignedMobileSectionStyle}>
        <div className={styles.lableViewStyle}>
          <p className={styles.lableTextStyle}>{strings.currentlyAssign}</p>
          <Image
            onClick={() => setShowCurrentlyAssign(!showCurrentlyAssign)}
            src={chevronDownFillBlackIcon}
            containerStyle={
              showCurrentlyAssign ? styles.arrowRotateViewStyle : styles.dropDownIconStyle
            }
          />
        </div>
        {userDetails?.currently_assigned.length > 0 &&
          showCurrentlyAssign &&
          userDetails?.currently_assigned.map((item, index) => (
            <React.Fragment key={index}>
              <AssetAndUserCard
                type="user"
                title={item.asset_name}
                description={`#${item.asset_uid}`}
                image={item?.image}
                arrowRight={false}
                leftLable="Assigned on"
                leftTitle={item.assigned_at ? moment(item.assigned_at).format('ll') : '- - -'}
                leftDescription={item.assigned_at ? moment(item.assigned_at).format('LT') : ''}
                actionBtn={true}
                bottomSectionStyle={styles.cardBottomSectionStyle}
                tapOnAction={(e) => {
                  setIsShowActionOptions(true);
                  setSelectedAsset(item);
                  setPickerRef(e.target);
                }}
              />
            </React.Fragment>
          ))}
      </div>
    );
  };

  const previouslyAssignedSection = () => {
    return (
      <React.Fragment>
        {width <= 480 ? (
          previouslyAssignedMobileSection()
        ) : (
          <div className={styles.tableSectionStyle}>
            <table className={styles.tableWrapperStyle}>
              <thead>
                <tr className={styles.tableHeaderRowStyle}>
                  <th>{strings.previouslyAssign}</th>
                  <th>{strings.fromDateAndTime}</th>
                  <th className={styles.lastElementViewStyle}>{strings.toDateAndTime}</th>
                </tr>
              </thead>
              <tbody>
                {userDetails?.previously_assigned.map((item, index) => (
                  <tr key={index} className={styles.tableRowStyle}>
                    <td>
                      <div
                        className={styles.assetIconWrapperStyle}
                        onClick={() => navigate(routePaths.assetDetails(item.asset._id))}
                      >
                        <Image
                          src={item.asset.image || assetIcon}
                          containerStyle={styles.assetIconStyle}
                          alt={item.asset.asset_name}
                        />
                        <div className={styles.customColStyle}>
                          <span className={styles.tableTitleTextStyle}>
                            {item.asset.asset_name}
                          </span>
                          <span className={styles.tableSmallTextStyle}>
                            #{item.asset.asset_uid}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={styles.customColStyle}>
                        <span className={styles.tableTitleTextStyle}>
                          {moment(item.assigned_at).format('ll')}
                        </span>
                        <span className={styles.tableSmallTextStyle}>
                          {moment(item.assigned_at).format('LT')}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.customDateColStyle}>
                        <div className={styles.dateColStyle}>
                          <span className={styles.tableTitleTextStyle}>
                            {moment(item.un_assigned_at).format('ll')}
                          </span>
                          <span className={styles.tableSmallTextStyle}>
                            {moment(item.un_assigned_at).format('LT')}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  };

  const previouslyAssignedMobileSection = () => {
    return (
      <div className={styles.currentlyAssignedMobileSectionStyle}>
        <div className={styles.lableViewStyle}>
          <p className={styles.lableTextStyle}>{strings.previouslyAssign}</p>
          <Image
            onClick={() => setShowPreviouslyAssign(!showPreviouslyAssign)}
            src={chevronDownFillBlackIcon}
            containerStyle={
              showPreviouslyAssign ? styles.arrowRotateViewStyle : styles.dropDownIconStyle
            }
          />
        </div>
        {userDetails?.previously_assigned.length > 0 &&
          showPreviouslyAssign &&
          userDetails?.previously_assigned.map((item, index) => (
            <React.Fragment key={index}>
              <AssetAndUserCard
                type="user"
                title={item.asset.asset_name}
                description={`#${item.asset.asset_uid}`}
                image={item?.asset.image}
                arrowRight={false}
                leftLable="From Date"
                leftTitle={item.assigned_at ? moment(item.assigned_at).format('ll') : '- - -'}
                leftDescription={item.assigned_at ? moment(item.assigned_at).format('LT') : ''}
                rightLable="To Date :"
                rightTitle={
                  item.un_assigned_at ? moment(item.un_assigned_at).format('ll') : '- - -'
                }
                rightDescription={
                  item.un_assigned_at ? moment(item.un_assigned_at).format('LT') : ''
                }
              />
            </React.Fragment>
          ))}
      </div>
    );
  };

  const renderActionOptions = () => {
    return (
      <PopOver
        show={isShowActionOptions}
        reference={pickerRef}
        onClose={() => setIsShowActionOptions(false)}
        containerStyle={styles.actionPopUpViewStyle}
      >
        <div className={styles.actionOptionsViewStyle}>
          {actionOptions.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  tapOnActionOption(item);
                  setIsShowActionOptions(false);
                }}
                className={styles.optionsViewStyles}
              >
                {item}
              </p>
            );
          })}
        </div>
      </PopOver>
    );
  };

  const renderAlertModal = () => {
    return (
      <Modal showOverlay={true} show={isModalOpen} containerStyle={styles.alertModalViewStyle}>
        <div className={styles.modalContentWrapperStyle}>
          <p className={styles.modalTitleStyle}>
            {strings.areUnAssignAsset + ' #' + selectedAsset?.asset_uid}?
          </p>
          <p className={styles.modalDescriptionStyle}>
            {strings.theAssetIsCurrentlyUnAssign} <span>@{userDetails?.full_name}</span>.{' '}
            {strings.confirmUnAssignAsset}
          </p>
          {unAssignError && <p className={styles.errorTextStyle}>{unAssignError}</p>}
          <div className={styles.modalActionBtnWrapperStyle}>
            <Button
              title={strings.confirm}
              variant="primary"
              size="md"
              onClick={tapOnUnAssign}
              disabled={unAssignLoading}
              isLoading={unAssignLoading}
            />
            <Button
              title="Cancel"
              variant="light"
              size="md"
              disabled={unAssignLoading}
              onClick={() => {
                setIsModalOpen(false);
                setUnAssignError('');
              }}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderAssignAssetModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAssignAssetModal}
        containerStyle={styles.assetModalViewStyle}
      >
        <AssignAssetCard
          asset={selectedAsset}
          setUser={setSelectedUser}
          onCancel={() => {
            setIsShowAssignAssetModal(false);
            setSelectedUser(null);
          }}
          onClick={() => {
            assetAssignToOthers();
          }}
          isLoader={assignAssetLoader}
        />
      </Modal>
    );
  };

  const renderEditInfoModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowEditModal}
        containerStyle={styles.editModalViewStyle}
        // handleClickOutSide={() => setIsShowEditModal(false)}
      >
        <EditEmployee
          employee={userDetails}
          onCancel={() => setIsShowEditModal(false)}
          onSuccess={(data) => {
            setUserDetails({ ...userDetails, ...data });
            setIsShowEditModal(false);
          }}
        />
      </Modal>
    );
  };

  const renderEmptyDataSection = () => {
    return (
      <div className={styles.emptyDataViewStyle}>
        <div className={styles.emptyDataSubViewStyle}>
          <Image src={infoCircleIcon} />
          <p className={styles.emptyDataTextStyle}>No asset is assigned to this user yet</p>
        </div>
      </div>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isDeleteModalOpen}
        containerStyle={styles.alertDeleteModalViewStyle}
      >
        <div className={styles.deleteModalContainerStyle}>
          <div className={styles.deleteModalTextWrapperStyle}>
            <p className={styles.deleteTextStyle}>
              Are you sure you want to delete the user @{userDetails?.full_name}?
            </p>
            <p className={styles.deleteModalDescStyle}>
              By proceeding, the user will be deleted from the trakr database and you will not be
              able to tag the user in any future tickets or maintenance, and much more.{' '}
              <span>Learn more</span>
            </p>
          </div>
          {deleteEmpErr && <p className={styles.errorTextStyle}>{deleteEmpErr}</p>}
          <div className={styles.btnContainerStyle}>
            <Button
              title="Yes, proceed"
              variant="primary"
              size="md"
              disabled={deleteLoader}
              onClick={handleDeleteEmployee}
              isLoading={deleteLoader}
            />
            <Button
              title="Cancel"
              variant="light"
              size="md"
              disabled={deleteLoader}
              onClick={() => {
                setDeleteModalOpen(false);
                setDeleteEmpErr('');
              }}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderAddReminderModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={reminderModal}
        containerStyle={styles.reminderModalViewStyle}
        handleClickOutSide={() => setReminderModal(false)}
      >
        <AddEditReminder
          type={reminderActionTypes.add}
          handleWithApi
          onclose={() => setReminderModal(false)}
          reminder={{ user: userDetails?._id }}
          setReminder={(data) =>
            setReminders((prev) => {
              let preData = [...prev];
              let index = preData.findIndex((item) => item._id === data._id);
              if (index === -1) {
                return [data, ...preData];
              } else {
                return preData;
              }
            })
          }
        />
      </Modal>
    );
  };

  const renderAttachmentsModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAttachmentsModal}
        containerStyle={styles.editAttachmentsModalViewStyle}
        handleClickOutSide={() => {
          setisShowAttachmentsModal(false);
          setApiError('');
          setAttachments(userDetails?.attachments);
        }}
      >
        <div className={styles.editAttachmentsViewStyle}>
          <div className={styles.editAttachmentsHeaderStyle}>
            <p className={styles.attachmentsHeaderTextStyle}>
              {userDetails?.attachments.length > 0 ? 'Edit attachments' : 'Add attachments'}
            </p>
            <Image
              src={closeIcon}
              onClick={() => {
                setisShowAttachmentsModal(false);
                setAttachments(userDetails?.attachments);
                setApiError('');
              }}
            />
          </div>
          <div className={styles.atttachmentsContentStyle}>
            <div className={styles.attachmentsLableStyle}>
              <p className={styles.labelTextStyle}>Attachments</p>
              <Image
                containerStyle={styles.infoIconStyle}
                src={infoCircleUpFillGrayIcon}
                alt="icon"
              />
            </div>
            <MultiFileUpload
              files={attachments || []}
              setFiles={(data) => setAttachments(data)}
              customListItemStyle={styles.customListItemStyle}
              setFilesUploading={(val) => setAttachDoneDisabled(val)}
            />
            <div className={styles.attachmentsBtnStyle}>
              <p className={styles.errorTextStyle}>{apiError}</p>
              <Button
                title="Done"
                onClick={handleUpdateAttachments}
                size="md"
                isLoading={actionLoader}
                disabled={actionLoader || attachDoneDisabled || isAttachValuesChanged}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderAllRemaindersModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowAllRemainders}
        onClose={() => setIsShowAllRemainders(false)}
        closeOnOutSideClick
        containerStyle={styles.allRemaindersModalViewStyle}
      >
        <AllRemainders
          type="user"
          remainders={reminders}
          onClose={() => setIsShowAllRemainders(false)}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.userDetailsContainerStyle}>
      {loader ? (
        <div className={styles.loaderViewStyle}>
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          {renderBackOptionSection()}
          {renderUserInfo()}
          {renderCurrentAndPreviousAssetsInfo()}
          {renderActionOptions()}
          {renderAlertModal()}
          {renderAssignAssetModal()}
          {renderEditInfoModal()}
          {renderDeleteModal()}
          {renderAddReminderModal()}
          {renderAttachmentsModal()}
          {renderAllRemaindersModal()}
        </React.Fragment>
      )}
    </div>
  );
};

export default EmployeeDetails;
